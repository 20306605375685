import { UploadController } from './upload.controller';
import { UploadDropzone } from './views/files.upload.dropzone';
import { FilesListView } from './views/files.list.view';
import { FileSelectModal } from './views/files.select.modal';
import { FilesUploadModal } from './views/files.upload.modal';
import { FileDetailsModal } from './views/files.details.modal';
import { FileDeleteModal } from './views/files.delete.modal';


export function FilesController() {

    const filesDataset = andy.backend.services.files.dataset;

    const filesContainer = andy.mainContainer.newView({ code: 'files-container', route: '/content' });
    const uploader = new UploadController();
    const dropzone = new UploadDropzone();
    const filesListView = new FilesListView();
    const filesUploadModal = new FilesUploadModal();
    const fileDetailsModal = new FileDetailsModal();
    const fileDeleteModal = new FileDeleteModal();
    const fileSelectModal = new FileSelectModal();

    let fileSelectModalCallback = null;

    function constructor() {
        addToMainMenu();

        filesListView.filesGridList.bind(filesDataset);
        filesListView.on('upload', filesUploadModal.show);


        //File delete
        filesListView.on('file.delete', fileDeleteModal.show);

        fileDeleteModal.on('yes', deleteFile);
        fileDeleteModal.on('cancel', fileDeleteModal.hide);

        //File details
        filesListView.on('file.details', fileDetailsModal.show);
        fileDetailsModal.on('save', saveFileDetails);
        fileDetailsModal.on('cancel', fileDetailsModal.hide);
        fileDetailsModal.on('file.copy.id', copyIdToClipboard);

        filesListView.on('file.download', downloadFile);
        filesListView.on('file.copy.id', copyIdToClipboard);

        filesContainer.append(filesListView.element);

        dropzone.on('drop', filesUploadModal.show);
        uploader.listenOnDrop(dropzone.element);

        filesUploadModal.on('choose.file', uploader.triggerUploader);

        uploader.on('file.start', (file) => filesUploadModal.uploadsList.addFile(file.meta._id, file));
        uploader.on('file.progress', ({ file, bytesLoaded }) => filesUploadModal.uploadsList.updateFile(file.meta._id, bytesLoaded));
        uploader.on('file.complete', (file) => filesUploadModal.uploadsList.completeFile(file.meta._id));

        // File Select Modal

        fileSelectModal.filesGridList.bind(filesDataset);

        fileSelectModal.on('close', () => {
            fileSelectModal.hide();
            if (fileSelectModalCallback) {
                fileSelectModalCallback(null);
                fileSelectModalCallback = null;
            }
        });

        fileSelectModal.on('select', (data) => {
            fileSelectModal.hide();
            if (fileSelectModalCallback) {
                fileSelectModalCallback(data.values);
                fileSelectModalCallback = null;
            }
        });

    }

    function addToMainMenu() {
        const filesMenuItem = new andy.UI.MainMenuItem({ code: 'content.files', label: 'files', icon: 'mdi-folder-outline', order: 1 });
        andy.mainMenu.props.children['root.content.section'].append(filesMenuItem);

        filesMenuItem.connect(filesContainer, 'show', 'select');
    }


    function saveFileDetails({ file, values }) {
        file.name = values.name;
        file.tags = values.tags;
        filesDataset.save(file, (res) => {
            fileDetailsModal.hide(); //TODO - handle error
        });
    }

    function deleteFile(file) {
        filesDataset.remove(file, (res) => {
            fileDeleteModal.hide(); //TODO - handle error
        });
    }

    function copyIdToClipboard(file) {
        navigator.permissions.query({ name: "clipboard-write" }).then(result => {
            if (result.state == "granted" || result.state == "prompt") {
                navigator.clipboard.writeText(file._id);
            }
        });
    }

    function downloadFile(file) {
        console.log(file);
        let link = document.createElement("a");
        link.target = '_blank';
        link.download = file.originalName;
        link.href = `${andy.backend.location}/data/${file.filename}`;
        link.click();
    }

    function selectFileModal(options, cb) {
        fileSelectModalCallback = cb;
        fileSelectModal.setOptions(options);
        fileSelectModal.show();
    }

    constructor();

    return {
        selectFileModal
    }
}