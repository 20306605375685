export function ModulesService({ socket }) {

    let events = new andy.EventManager();

    function constructor() {

        socket.on('modules', (data) => {
            switch (data.action) {
                case 'loaded':
                    events.dispatchEvent('modules.metadata', data.value);
                    break;
            }
        });

    }

    function getModules(){
        socket.emit('modules', {
            action: 'get',
            value: '',
            token: andy.Auth.token
        });
    }

    constructor();

    return {
        getModules,
        on: events.addEventListener
    }
}