export function EditorToolbars() {

    const events = new andy.EventManager();

    let rightGroup, leftGroup , zoomInput;

    function constructor() {

        zoomInput = new andy.UI.ZoomInput(); 

        zoomInput.on('change', () => events.dispatchEvent('editor.zoomchange', zoomInput.value))

        rightGroup = new andy.UI.Group({
            grid: new andy.UI.Button({ icon: 'mdi-grid' }, () => events.dispatchEvent('editor.guidelines')),
            rulers: new andy.UI.Button({ icon: 'mdi-ruler-square' }, () => events.dispatchEvent('editor.rulers')),
            zoomFit: new andy.UI.Button({ icon: 'mdi-magnify-scan' }, () => events.dispatchEvent('editor.zoomfit')),
            zoomInput,
            animations: new andy.UI.Button({ icon: 'mdi-animation' }, () => events.dispatchEvent('editor.animations')),
            settings: new andy.UI.Button({ icon: 'mdi-wrench' }, () => events.dispatchEvent('page.settings'))
        }, { style: { justifyContent: 'flex-end' } });

        leftGroup = new andy.UI.Group({
            undo: new andy.UI.Button({ icon: 'mdi-undo' }, () => events.dispatchEvent('editor.undo')),
            redo: new andy.UI.Button({ icon: 'mdi-redo' }, () => events.dispatchEvent('editor.redo')),
            selectAll: new andy.UI.Button({ icon: 'mdi-select-all' }, () => events.dispatchEvent('editor.selectall')),
            group: new andy.UI.Button({ icon: 'mdi-group' }, () => events.dispatchEvent('editor.group'))
        }, { style: { justifyContent: 'flex-start' } });

    }

    function setZoomValue(val){
        zoomInput.value = parseInt(val * 100); 
    }

    constructor();

    return {
        get elements() {
            return [leftGroup.getElement(), rightGroup.getElement()]
        },
        setZoomValue,
        on: events.addEventListener
    }

}