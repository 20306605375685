export function RecoverForm () {

    let events = andy.EventManager();
    let form;
   
    function constructor() {

        form = new andy.UI.Form('recover_password', {
            email: {
                label: 'email',
                item: new andy.UI.Input({validators: [andy.Validators.notEmpty, andy.Validators.email]})
            }
        }, {
                cancel: new andy.UI.Button({
                    icon: 'cancel',
                    label: 'cancel'
                }, ()=>{
                    events.dispatchEvent('cancel'); 
                }),
                recover: new andy.UI.Button({
                    icon: 'autorenew',
                    label: 'recover_password'
                }, () => {
                    events.dispatchEvent('submit', form.getValue());
                })
            });

        form.getElement().addEventListener('keypress', (e) => {
            if (e.which == 13) {
                events.dispatchEvent('submit', form.getValue());
            }
        });
    }

    constructor();

    return {
        get element() {
            return form.getElement();
        },
        on: events.addEventListener
    }
}