export function DeleteModal() {

    const events = new andy.EventManager();

    let confirmDeleteForm, deleteModal;
    let itemToDelete = null;

    function constructor() {
        confirmDeleteForm = new andy.UI.Form('confirm_delete', {
            msg: { item: new andy.UI.Label('confirm_delete_message') },
            description: { item: new andy.UI.Label('desc') }
        },
            {
                yes: new andy.UI.Button({ label: 'yes', icon: 'delete' }, yesHandler),
                no: new andy.UI.Button({ label: 'no', icon: 'cancel' }, cancelHandler),
            });

        deleteModal = new andy.UI.Modal(confirmDeleteForm);
        deleteModal.on('dismiss', cancelHandler);
    }

    function yesHandler() {
        events.dispatchEvent('yes', itemToDelete);
        itemToDelete = null;
    };

    function cancelHandler() {
        itemToDelete = null;
        events.dispatchEvent('cancel');
    };

    function show(description, item) {
        if (item) {
            itemToDelete = item;
        }

        confirmDeleteForm.setValue({
            description
        })

        deleteModal.show();
    }

    function hide() {
        deleteModal.hide();
    }

    constructor();

    return {
        show,
        hide,
        on: events.addEventListener
    }
}