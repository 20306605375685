import { TriggerModal } from './triggerModal';

const triggerModal = new TriggerModal();

export function GFMSelect(p) {

    const props = {
        ...{
            extensions: [],
            extension: null,
            triggers: false
        },
        ...p
    }

    const audio = document.createElement('audio');
    const path = andy.backend ? andy.backend.location : window.location;
    let value = null;
    let el, label, messageCode, moreDropdown;
    let filesDataset = andy.backend.services.files.dataset;

    function constructor() {

        messageCode = 'click_to_select';
        el = new DIV('andy-ui-gfmselect');
        label = new andy.UI.Label(messageCode);

        const moreDropdownItems = {
            remove: new andy.UI.Button({ label: 'remove', icon: 'mdi-close' }, reset),
            ...(props.triggers) ? { triggers: new andy.UI.Button({ label: 'triggers', icon: 'mdi-chart-timeline' }, openTriggersHandler) } : {}
        };

        moreDropdown = new andy.UI.DropDown({ icon: 'mdi-dots-vertical', items: moreDropdownItems, customClass: 'device-item-more' });

        el.append(label.getElement());
        el.addEventListener('click', selectFileHandler);
    }

    function selectFileHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        window.mediaManager.selectFileModal({ mode: 'select', type: '*', extensions: (props.extensions.length) ? props.extensions : props.extension }, (data) => {
            if (data) {
                set(data);
                el.append(moreDropdown.getElement());
            }
        });
    }

    function openTriggersHandler() {
        triggerModal.show(null, (timestamps) => {
            console.log(timestamps);
            setTimestamps(timestamps);
            triggerModal.hide();
        });
    }

    function reset(resetValue = true) {
        if (resetValue) {
            value = null;
        }

        audio.remove();

        label.setValue(messageCode);
        el.style.backgroundImage = 'none';
        el.classList.remove('withfile');
        el.classList.remove('videofile');
        moreDropdown.getElement().remove();
    }

    function set(f) {
        if (f.length == 1 || !Array.isArray(f)) {
            let af = Array.isArray(f) ? f[0] : f;
            value = af._id;
            switch (af.type) {
                case 'image':
                    reset(false);
                    el.style.backgroundImage = "url('" + path + "/thumbnails/" + af.thumbnail + "')";
                    label.setValue(af.name);
                    el.classList.add('withfile');
                    break;

                case 'video':
                    reset(false);
                    el.style.backgroundImage = "url('" + path + "/thumbnails/" + af.thumbnail + "')";
                    label.setValue(af.name);
                    el.classList.add('withfile');
                    el.classList.add('videofile');
                    console.log('here', f);
                    if (f.timestamps) {
                        setTimestamps(f.timestamps);
                        triggerModal.form.setValue({ video: f, timestampsList: f.timestamps });
                    }
                    else {
                        triggerModal.form.reset();
                        triggerModal.form.setValue({ video: f });
                    }
                    break;

                case 'audio':
                    let audioIcon = new andy.UI.Icon('audiotrack');
                    reset(false);
                    audioIcon.getElement().style.cssText = "font-size: 90px, opacity: 0.1; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)";
                    audio.controls = true;
                    audio.src = andy.backend.location + "/data/" + af.filename;
                    el.append(audioIcon.getElement(), audio);
                    el.style.backgroundImage = '';
                    label.setValue(af.name);
                    el.classList.add('withfile');
                    break;

                default:
                    reset(false);
                    label.setValue(af.name);
                    el.classList.add('withfile');
                    el.style.backgroundImage = '';
                    break;
            }
            el.append(moreDropdown.getElement());
        }
    }

    function setTimestamps(kfs) {
        if (!value) {
            return;
        }

        let _id = (value && value._id) ? value._id : value;

        // console.log('Set timestamps: ', kfs)

        value = Object.assign({ _id }, {
            timestamps: kfs
        });
    }

    let _public = {
        clear: reset,
        reset,
        getElement: function () {
            return el;
        },
        getValue: function () {
            return value;
        },
        setValue: function (v) {
            reset();
            if ((v && !Array.isArray(v)) || (v && Array.isArray(v) && v.length == 1)) {
                if (filesDataset) {
                    if (filesDataset.data[v]) {
                        let f = filesDataset.data[v];
                        triggerModal.form.reset();
                        set(f);
                    }
                    else if (filesDataset.data[v._id]) {
                        let f = filesDataset.data[v._id];
                        f.timestamps = v.timestamps ? v.timestamps : [];
                        triggerModal.form.setValue({ video: f, timestampsList: f.timestamps });
                        set(f);
                    }
                    else {
                        label.setValue('file_deleted');
                        value = v;
                    }
                }
            }
        },
    }

    constructor();

    andy.UI._instances.push(_public);
    return _public;
}