import { DeviceStatusView } from './views/status.view';
import { DeviceProfileView } from './views/profile.view';
import { DeviceSettingsView } from './views/settings.view';
import { ProjectSelectModal } from './views/projectSelect.modal';
import { DirectDeviceInstance } from './device.instance';
import { DeviceRemoteActionsToolbar } from './views/remote.actions.toolbar';
import { RemoteTerminalModal } from './views/terminal.modal';

export function DeviceController() {

    const devicesDataset = andy.backend.services.devices.dataset;
    const projectsDataset = andy.backend.services.projects.dataset;

    let device = null;
    let directDevice = new DirectDeviceInstance();
    window.directDevice = directDevice;

    const statusView = new DeviceStatusView();
    let statusContainer;
    let statusMenuItem;

    const settingsView = new DeviceSettingsView();
    let settingsContainer;
    let settingsMenuItem;

    const profileView = new DeviceProfileView();
    let profileContainer;
    let profileMenuItem;

    const remoteActionsToolbar = new DeviceRemoteActionsToolbar();
    const remoteTerminal = RemoteTerminalModal();

    const deviceMenuSection = new andy.UI.MainMenuSection({ code: 'root.device.section', label: 'device', order: 0, hide: true });

    function constructor() {

        statusViewController();
        profileViewController();
        settingsViewController();
        directDeviceActionsController();

        andy.mainMenu.append(deviceMenuSection);
    }

    function directDeviceActionsController() {

        directDevice.on('connected', ()=>{
            remoteActionsToolbar.connected(); 
            remoteTerminal.connected(); 
        });
        directDevice.on('reconnected', () => {
            remoteActionsToolbar.connected();
            remoteTerminal.connected(); 
        });
        directDevice.on('disconnected', () => {
            remoteActionsToolbar.disconnected()
            remoteTerminal.disconnected(); 
            statusView.reset(); 
        });
        directDevice.on('device.details', statusView.setDetails);
        directDevice.on('uptime', statusView.setUptime);
        directDevice.on('screenshot', statusView.setScreenshot);
        directDevice.on('cpu.usage', statusView.setCpuUsage);
        directDevice.on('free.memory',statusView.setFreeMemory);

        directDevice.on('attempt.connect', remoteActionsToolbar.attemptToConnect);
        directDevice.on('connection.failed', remoteActionsToolbar.failed);

        remoteActionsToolbar.on('refresh.device', directDevice.refresh);
        remoteActionsToolbar.on('pause.device', directDevice.pause);
        remoteActionsToolbar.on('resume.device', directDevice.resume);
        remoteActionsToolbar.on('sync.device', directDevice.sync);
        remoteActionsToolbar.on('reboot.device', directDevice.reboot);
        remoteActionsToolbar.on('poweroff.device', directDevice.shutdown);
        remoteActionsToolbar.on('terminal.open', remoteTerminal.show);

        directDevice.on('terminal.data', remoteTerminal.write);
        directDevice.on('terminal.close', remoteTerminal.clear); 
        remoteTerminal.on('data', directDevice.terminalWrite);

        statusView.on('screenshot.request', directDevice.requestScreenshot);

    }

    function profileViewController() {
        profileContainer = andy.mainContainer.newView({
            code: 'device.profile',
            route: '/device/profile'
        });

        profileContainer.append(...profileView.element);

        profileMenuItem = new andy.UI.MainMenuItem({ code: 'device.profile', label: 'profile', icon: 'mdi-clipboard-text-outline', order: 1 });
        profileMenuItem.connect(profileContainer, 'show', 'select');
        deviceMenuSection.append(profileMenuItem);

        profileView.on('save', (values) => {
            device = { ...device, ...values };
            devicesDataset.save(device, (doc) => {
                console.log('saved', doc);  //TODO - Give visual feedback 
            });
        })
    }

    function settingsViewController() {
        settingsContainer = andy.mainContainer.newView({
            code: 'device.settings',
            route: '/device/settings'
        });

        settingsContainer.append(...settingsView.element);

        settingsMenuItem = new andy.UI.MainMenuItem({ code: 'device.settings', label: 'settings', icon: 'mdi-cog-outline', order: 6 });
        settingsMenuItem.connect(settingsContainer, 'show', 'select');
        deviceMenuSection.append(settingsMenuItem);

        const projectSelectModal = new ProjectSelectModal();
        projectSelectModal.projectSelect.bind(andy.backend.services.projects.dataset);

        projectSelectModal.on('add', (projectId) => {
            settingsView.projectsList.add(projectsDataset.data[projectId]);
            projectSelectModal.hide();
        });

        settingsView.on('add.project', projectSelectModal.show);

        settingsView.on('save', (values) => {
            device = { ...device, ...values };
            devicesDataset.save(device, (doc) => {
                console.log('saved', doc);  //TODO - Give visual feedback 
            });
        })

    }

    function statusViewController() {
        statusContainer = andy.mainContainer.newView({
            code: 'device.status',
            route: '/device'
        });

        statusContainer.append(statusView.element);

        statusMenuItem = new andy.UI.MainMenuItem({ code: 'device.status', label: 'status', icon: 'mdi-clipboard-pulse-outline', order: 0 });
        statusMenuItem.connect(statusContainer, 'show', 'select');
        deviceMenuSection.append(statusMenuItem);

        statusContainer.on('enter', () => {
            andy.toolbarSection.append('remote.actions', remoteActionsToolbar.element);
        })
    }

    function updateViewsWithData() {
        // Populate Projects
        let notFounds = 0;
        const populatedProjects = device.projects.map(projectId => {
            let project = projectsDataset.data[projectId];
            return (project) ? project : { _id: notFounds++, name: 'project_not_found_or_deleted' };
        });

        profileView.setValue(device);
        settingsView.setValue({ ...device, ...{ projects: populatedProjects } });
    }

    function resetViews() {
        profileView.reset();
        settingsView.reset();
    }

    function show() {
        statusContainer.show();
        deviceMenuSection.show();
    }

    function hide() {
        deviceMenuSection.hide();
    }

    function open(deviceId) {
        if (device) {
            console.error('Another device is current opened. Close it first');
            return 0;
        }

        device = devicesDataset.data[deviceId];
        console.log(device);
        directDevice.open(device);

        updateViewsWithData();

        deviceMenuSection.props.description = device.name;
        deviceMenuSection.render();

        show();
    }

    function close(cb) {
        directDevice.close();
        resetViews();
        hide();
        device = null;
        if(cb){
            setTimeout(cb, 100); // give some time to let all the close process finish before open the next device
        }
    }

    constructor();

    return {
        get isOpen() {
            return !!device;
        },
        get device() {
            return device;
        },
        open,
        close
    }
}