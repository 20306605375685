export function DashboardController() {

    const statisticsContainer = andy.mainContainer.newView({
        code: 'statistics-container',
        route: '/statistics'
    });

    const dashboardMenuItem = new andy.UI.MainMenuItem({code : 'root.dashboard', label: 'dashboard', icon: 'mdi-rocket-launch-outline', order: 0});
    andy.mainMenu.append(dashboardMenuItem);

    statisticsContainer.show(); 

    dashboardMenuItem.connect(statisticsContainer, 'show', 'select');

    let dashboardForm = new andy.UI.Form("dashboard", {
       label: {item: new andy.UI.Label('without_info_to_show')}
    });

    statisticsContainer.element.appendChild(dashboardForm.getElement());


    return {
        start: {

        }
    }

}
