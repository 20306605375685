export function StudioListItem(p) {

    const events = new andy.EventManager();

    const props = {
        ...{
            label: 'item',
            icon: 'mdi-check',
            selectable: false,
            selected: false,
            clickable: true,
            draggable: false,
            subMenu: false
        },
        ...p
    }


    let buttonWrapper, subMenuGroup;
    let element, label, icon;

    function constructor() {
        element = new DIV('andy-ui-studiolist-item');
        buttonWrapper = new DIV('button-wrapper');

        label = new andy.UI.Label(props.label);
        icon = new andy.UI.Icon(props.icon);

        subMenuGroup = new andy.UI.Group({
            duplicate: new andy.UI.Button({ icon: 'mdi-content-duplicate' }, () => { events.dispatchEvent('duplicate', _public); }),
            delete: new andy.UI.Button({ icon: 'mdi-delete' }, () => {
                events.dispatchEvent('delete', _public);
            })
        });

        buttonWrapper.append(icon.element, label.element),

            element.append(buttonWrapper);

        element.addEventListener('click', () => {
            if (props.clickable) {
                events.dispatchEvent('click', _public);
            }
        });

        element.addEventListener('dragstart', (e) => {
            events.dispatchEvent('dragstart', e);
            e.dropEffect = 'copy';
            e.dataTransfer.effectAllowed = 'move';
        });

        update();
    }

    function remove() {
        element.remove();
        events.dispatchEvent('remove', _public);
    }

    function update() {
        if (props.selectable && props.selected) {
            element.classList.add('selected');
        } else {
            element.classList.remove('selected');
        }
        if (props.draggable && props.subMenu) {
            subMenuGroup.getElement().remove();
        } else if (props.subMenu) {
            element.append(subMenuGroup.getElement());
        }
        element.draggable = props.draggable;
    }

    function select() {
        if (props.selectable) {
            props.selected = true;
        }
        update();
    }

    function deselect() {
        if (props.selectable) {
            props.selected = false;
        }
        update();
    }

    constructor();

    const _public = {
        get props() {
            return props;
        },
        get element() {
            return element;
        },
        remove,
        select,
        deselect,
        update,
        on: events.addEventListener
    }

    return _public;

}