import { AudioTabs } from './views/collection.audio.tabs';
import { VideoTabs } from './views/collection.video.tabs';
import { GalleryTabs } from './views/collection.gallery.tabs';
import { PDFTabs } from './views/collection.pdf.tabs';
import { ExcelParser } from './excel.controller';
import { DatabaseListView } from './views/databases.list.view';
import { DatabaseFormModal } from './views/databases.form.modal';
import { TagFormModal } from './views/databases.tags.form.modal';
import { CollectionListModal } from './views/collection.list.modal';
import { CollectionFormModal } from './views/collection.form.modal';

export function DatabasesController() {

    const assetPath = window.location.href;

    if (typeof andy.db === 'undefined') {
        andy.db = {
            vars: {},
            templates: require('./templates/collectionTemplates')
        };
    }

    const databasesContainer = andy.mainContainer.newView({
        code: 'databases-container',
        route: '/content/databases'
    });

    const databasesMenuItem = new andy.UI.MainMenuItem({ code: 'content.databases', label: 'databases', icon: 'mdi-database', order: 0 });
    andy.mainMenu.props.children['root.content.section'].append(databasesMenuItem);
    databasesMenuItem.connect(databasesContainer, 'show', 'select');

    const databaseService = andy.backend.services.databases;
    const fileService = andy.backend.services.files;
    andy._datasets['andy.db'] = databaseService.dataset; // just for legacy purposes

    const excelParser = new ExcelParser();
    const databaseListView = new DatabaseListView();
    const databaseFormModal = new DatabaseFormModal();
    const tagFormModal = new TagFormModal();
    const collectionListModal = new CollectionListModal();
    const collectionFormModal = new CollectionFormModal(AudioTabs, VideoTabs, GalleryTabs, PDFTabs);

    function constructor() {
        //General database service
        databaseService.dataset.on('fetch', (res) => {
            Object.values(res.dataset).forEach((collection) => {
                databaseService.emit('fetch.collection', collection);
            });
        });

        databaseService.emit('fetch.collections.list');
        databaseService.on('databases.connected', () => {
            databaseService.dataset.fetch();
        });

        // Databases list
        databaseListView.list.bind(databaseService.dataset);
        databasesContainer.append(databaseListView.element);
        databaseListView.on('database.edit', editDatabase);
        databaseListView.on('database.remove', deleteDatabase);
        databaseListView.on('database.open', openDatabase);
        databaseListView.on('database.create', createNewDatabase);

        // Database form
        databaseFormModal.on('database.tags.show', showDatabaseTags);
        databaseFormModal.on('database.tags.add', createDatabaseTag);
        databaseFormModal.on('database.tags.remove', removeDatabaseTag);
        databaseFormModal.on('database.tags.import', importTags);
        databaseFormModal.on('database.tags.template', downloadTagsTemplate);
        databaseFormModal.on('database.preloader.change', triggerDatabaseTemplate);
        databaseFormModal.on('database.save', saveDatabase);
        databaseFormModal.modal.on('show', modalShowHandler);

        // Tags form
        tagFormModal.modal.on('hide', hideTagsHandler);
        tagFormModal.modal.on('show', showTagsHandler);
        tagFormModal.on('databases.tags.save', saveTagsHandler);

        // Collection list
        collectionListModal.on('collection.item.show', showCollectionItem);
        collectionListModal.on('collection.create', createCollectionItem);
        collectionListModal.on('collection.item.delete', deleteCollectionItem);
        collectionListModal.on('collection.template', downloadCollectionTemplate);
        collectionListModal.on('collection.import', importCollection);

        // Collection Form
        collectionFormModal.on('collection.item.save', saveCollectionItem);
        collectionFormModal.modal.on('show', collectionFormModalShowHandler);
    }

    function collectionFormModalShowHandler() {
        collectionFormModal.init();
        if (!andy.db.vars.selectedCollectionItem) {
            collectionFormModal.form.setValue({
                id: '',
                language: {
                    en_US: {},
                    pt_PT: {}
                },
                accessibility: {
                    en_US: {},
                    pt_PT: {}
                },
                date: {
                    startDate: '',
                    endDate: ''
                },
                gps: {
                    lat: null,
                    lng: null
                },
                favorite: null,
                price: null,
                promoPrice: null,
                quantity: null,
                rating: null,
                order: null,
                contacts: {
                    email: null,
                    phone: null,
                    website: null,
                    link: null,
                    pdf: null
                },
                image: null,
                icon: null,
                color: null,
                video: null,
                audio: null,
                gallery: null,
                pdf: null
            });
        }
        else {
            collectionFormModal.form.setValue({
                id: andy.db.vars.selectedCollectionItem.id ? andy.db.vars.selectedCollectionItem.id : '',
                language: andy.db.vars.selectedCollectionItem.language,
                accessibility: andy.db.vars.selectedCollectionItem.accessibility,
                date: andy.db.vars.selectedCollectionItem.date,
                gps: andy.db.vars.selectedCollectionItem.gps,
                favorite: andy.db.vars.selectedCollectionItem.favorite,
                price: andy.db.vars.selectedCollectionItem.price,
                promoPrice: andy.db.vars.selectedCollectionItem.promoPrice,
                quantity: andy.db.vars.selectedCollectionItem.quantity,
                rating: andy.db.vars.selectedCollectionItem.rating,
                order: andy.db.vars.selectedCollectionItem.order,
                contacts: andy.db.vars.selectedCollectionItem.contacts,
                image: andy.db.vars.selectedCollectionItem.image,
                gallery: andy.db.vars.selectedCollectionItem.gallery,
                tags: andy.db.vars.selectedCollectionItem.tags,
                icon: andy.db.vars.selectedCollectionItem.icon,
                color: andy.db.vars.selectedCollectionItem.color,
                video: andy.db.vars.selectedCollectionItem.video,
                audio: andy.db.vars.selectedCollectionItem.audio,
                pdf: andy.db.vars.selectedCollectionItem.pdf,
            });
        }
    }

    function showCollectionItem(item) {
        andy.db.vars.selectedCollectionItem = item;
        collectionFormModal.modal.show();
    }

    function createCollectionItem() {
        andy.db.vars.selectedCollectionItem = null;
        collectionFormModal.modal.show();
    }

    function saveCollectionItem(data) {
        andy.db.vars.selectedCollection.save(data.values, (res) => {
            collectionFormModal.modal.hide();
        });
    }

    function deleteCollectionItem(item) {
        if (confirm(new andy.UI.Label('confirm_delete').getElement().innerHTML)) {
            andy.db.vars.selectedCollection.remove(item);
        }
    }

    function downloadCollectionTemplate() {
        window.open(assetPath + '/assets/js/controllers/content/templates/DatabaseTemplate.xlsx');
    }

    function importCollection() {
        let inputEl = document.createElement('input');
        inputEl.type = 'file';

        inputEl.addEventListener('change', (a) => {
            let files = inputEl.files;

            if (files[0].name.split('.').pop() !== 'xlsx') {
                alert('File extension is not valid. Please, upload a .XLSX file.');
            }
            else {
                excelParser.readExcelForContent(files[0], (res) => {
                    res.forEach((item) => {
                        andy._datasets[andy.db.vars.selectedDatabase.code].save(item);
                    });
                });
            }
        });

        inputEl.click();
    }

    function generateUID() {
        let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    }

    function editDatabase(db) {
        andy.db.vars.selectedDatabase = db;
        andy.db.vars.currentTags = db.tags;
        databaseFormModal.modal.show();
    }

    function deleteDatabase(db) {
        if (confirm(new andy.UI.Label('confirmation_delete').getElement().innerHTML)) {
            databaseService.dataset.remove(db);
        }
    }

    function openDatabase(db) {
        andy.db.vars.selectedDatabase = db;
        andy.db.vars.selectedCollection = andy._datasets[db.code];
        andy._datasets[db.code].fetch();
        collectionListModal.component.setTitle(db.code);
        collectionListModal.list.bind(andy._datasets[db.code]);
        collectionListModal.modal.show();
    }

    function createNewDatabase() {
        andy.db.vars.selectedDatabase = null;
        andy.db.vars.currentTags = [];
        databaseFormModal.modal.show();
    }

    function showDatabaseTags(tag) {
        andy.db.vars.selectedTag = tag;
        tagFormModal.modal.show();
    }

    function createDatabaseTag() {
        andy.db.vars.selectedTag = null;
        tagFormModal.modal.show();
    }

    function removeDatabaseTag(e) {
        const existingTag = andy.db.vars.currentTags.findIndex(tag => tag._id === e.item._id);

        if (existingTag < 0) {
            return;
        }

        andy.db.vars.currentTags.splice(existingTag, 1);
    }

    function importTags() {
        let inputEl = document.createElement('input');
        inputEl.type = 'file';

        inputEl.addEventListener('change', (a) => {
            let files = inputEl.files;

            if (files[0].name.split('.').pop() !== 'xlsx') {
                alert('File extension is not valid. Please, upload a .XLSX file.');
            }
            else {
                excelParser.readTagsFile(files[0], (res) => {
                    andy.db.vars.currentTags = [...andy.db.vars.currentTags, ...res];
                    andy.db.vars.currentTags.forEach((tag) => {
                        if (!tag._id) {
                            tag._id = generateUID();
                        }
                    });

                    databaseFormModal.form.items.tags.item.items.list.item.reset();
                    databaseFormModal.form.items.tags.item.items.list.item.add(andy.db.vars.currentTags, true);
                });
            }
        });

        inputEl.click();
    }

    function downloadTagsTemplate() {
        window.open(assetPath + '/assets/js/controllers/content/templates/TagsTemplate.xlsx');
    }

    function triggerDatabaseTemplate(e) {
        let currentValue = e.getValue();
        databaseFormModal.form.items.fields.item.setValue(andy.db.templates[currentValue]);
    }

    function saveDatabase(data) {
        databaseService.dataset.save(data.values, () => {
            if (!data.values._id) {
                databaseService.emit('fetch.collection', data.values);
            }

            databaseFormModal.modal.hide();
        });
    }

    function modalShowHandler() {
        if (andy.db.vars.selectedDatabase) {
            databaseFormModal.form.setValue(andy.db.vars.selectedDatabase);
            databaseFormModal.form.items.tags.item.items.list.item.setValue(andy.db.vars.selectedDatabase.tags);
            databaseFormModal.form.items.code.item.getElement().setAttribute('disabled', true);
        }
        else {
            databaseFormModal.form.reset();
            databaseFormModal.form.setValue({
                language: { en_US: { name: '' }, pt_PT: { name: '' } },
                accessibility: { en_US: { name: '' }, pt_PT: { name: '' } }
            });
            databaseFormModal.form.items.code.item.getElement().removeAttribute('disabled');
        }
    }

    function hideTagsHandler() {
        databaseFormModal.form.items.tags.item.items.list.item.reset();
        databaseFormModal.form.items.tags.item.items.list.item.add(andy.db.vars.currentTags, true);
    }

    function showTagsHandler() {
        if (andy.db.vars.selectedTag) {
            tagFormModal.form.setValue(andy.db.vars.selectedTag);
        }
        else {
            tagFormModal.form.setValue({
                _id: '',
                id: '',
                language: {
                    en_US: {},
                    pt_PT: {}
                },
                tags: [],
                icon: null,
                gradient: 'linear-gradient(0deg, rgb(255,255,255), rgb(255,255,255) 0%)',
                color: '#000',
                order: 0
            });
        }
    }

    function saveTagsHandler(data) {
        let values = { ...data.values };

        let existingTag = andy.db.vars.currentTags.findIndex(tag => tag._id === values._id);

        if (existingTag < 0) {
            values._id = generateUID();
            andy.db.vars.currentTags.push(values);
        }
        else {
            andy.db.vars.currentTags[existingTag] = values;
        }

        tagFormModal.modal.hide();
    }

    constructor();

    return {
        generateUID
    }
}