export function Avatar(p) {
    
    const props = {
        ...{
            name: null,
            imageUrl: null,
        },
        ...p
    }

    const el = new DIV('andy-ui-avatar');

    function constructor() {
        render(); 
    }

    function getElement() {
        return el;
    }

    function getValue() {
        return props.value;
    }

    function setValue(value) {
        props.name = value.name;
        props.imageUrl = value.imageUrl;
        render();
    }

    function render() {
        if (props.image) {
            el.style.backgroundImage = `url(${props.imageUrl})`;
        } else {
            let initials = extractInitials(props.name);
            if(initials){
                el.innerHTML = initials; 
            }else{
                // TODO - grab a generic avatar image
            }
        }
    }

    function extractInitials(name) {
        if (typeof name !== 'string') return null;
        let initials;
        let splitName = name.trim().split(' ');
        if (splitName.length > 1) {
            initials = splitName.shift()[0] + splitName.pop()[0];
        } else {
            if (splitName.length > 0) {
                initials = splitName.shift()[0];
            }
        }
        return (initials) ? initials : null;
    }

    constructor();

    return {
        get element() {
            return el;
        },
        get props() {
            return props;
        },
        getElement,
        getValue,
        setValue
    }

}