export function UserProfileController({user}) { //TODO - needs refactor

    const userProfileContainer = andy.mainContainer.newView({
        code: 'user-profile-container',
        route: '/profile'
    });

    let profileDataset = new andy.Dataset('andy.user.profile');

    let USER;
    let scope = this;

    let userLocationForm = new andy.UI.Form('user_location', {
        city: {
            label: 'city',
            item: new andy.UI.Input()
        },
        country: {
            label: 'country',
            item: new andy.UI.Input()
        }
    });

    let userContactsForm = new andy.UI.Form('user_contacts', {
        email: {
            label: 'email',
            item: new andy.UI.Input({
                validators: [andy.Validators.email, andy.Validators.minSize]
            })
        },
        phone: {
            label: 'phone',
            item: new andy.UI.Input()
        },
        mobile: {
            label: 'mobile',
            item: new andy.UI.Input()
        },
        skype: {
            label: 'skype',
            item: new andy.UI.Input()
        },
        hangouts: {
            label: 'hangouts',
            item: new andy.UI.Input()
        }
    });

    let userCredentialsForm = new andy.UI.Form('user_credentials', {
        username: {
            label: 'username',
            item: new andy.UI.Input()
        },
        oldpassword: {
            label: 'old_password',
            item: new andy.UI.Input({
                type: "password"
            })
        },
        newpassword: {
            label: 'new_password',
            item: new andy.UI.Input({
                type: "password"
            })
        },
        repeatpassword: {
            label: 'repeat_password',
            item: new andy.UI.Input({
                type: "password"
            })
        },
    }, {
        save: new andy.UI.Button({
            icon: 'lock',
            label: 'change_credentials'
        }, () => {
            let user = userCredentialsForm.getValue();
            if (user.newpassword == user.repeatpassword) {
                user._id = USER._id;
            } else {
                Notifications.new("password_not_valid", "error");
            }
        })
    });

    let languageSelect = new andy.UI.Select(andy.Language.getLanguagesAvailable());
    languageSelect.getElement().addEventListener('change', function () {
        andy.Language.selectLanguage(languageSelect.getValue());
    });

    let userForm = new andy.UI.Form('user_profile', {
        name: {
            label: 'name',
            item: new andy.UI.Input()
        },
        surname: {
            label: 'surname',
            item: new andy.UI.Input()
        },
        organization: {
            label: 'organization',
            item: new andy.UI.Input()
        },
        department: {
            label: 'department',
            item: new andy.UI.Input()
        },
        language: {
            label: 'language',
            item: languageSelect
        },
        location: {
            label: '',
            item: userLocationForm
        },
        contacts: {
            label: '',
            item: userContactsForm
        }

    }, {
        save: new andy.UI.Button({
            icon: 'save',
            label: 'save'
        }, () => {
            let user = userForm.getValue();
            user._id = USER._id;

            profileDataset.save(user);
        })
    });

    function setUserData(data) {
        if (data.language.trim() != '') {
            andy.Language.selectLanguage(data.language);
        }

        USER = data;
        userForm.setValue(data);
        if (data.credentials) {
            userCredentialsForm.setValue({
                username: data.credentials.username,
                oldpassword: "",
                newpassword: "",
                repeatpassword: "",
            });
        }
    }

    userProfileContainer.append(userForm.getElement());
    userProfileContainer.append(userCredentialsForm.getElement());

    setUserData(user);

    profileDataset.on('save', ({ document }) => {
        setUserData(document);
    });

    function show() {

    }

    return {

    };
}