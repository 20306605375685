export function SchedulerView() {

    const events = new andy.EventManager();
    const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    let calendar;

    function constructor() {

        calendar = new andy.UI.Calendar();

        calendar.on('date.range.change', (dateInfo)=>{
            events.dispatchEvent('date.range.change', getCurrentDateString(dateInfo));
        })
    

        window.onresize = function (event) {
            // calendar.fixBugs();
        };

    }

    function getCurrentDateString(dateInfo) {
         let sD = dateInfo.start;
         let eD = dateInfo.end;

         return `${sD.getDate()} ${andy.Language.get(months[sD.getMonth()])} - ${eD.getDate()} ${andy.Language.get(months[eD.getMonth()])} | ${sD.getFullYear()}`;
    }

    function update(){
        calendar.render(); 
    }

    constructor();


    return {
        get calendar(){
            return calendar;
        },
        getCurrentDateString,
        update,
        get element() {
            return calendar.element;
        },
        on: events.addEventListener
    }

}
