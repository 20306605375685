export function UserService ({socket}) {

    const events = new andy.EventManager();

    const dataset = new andy.Dataset('andy.user.profile');
    
    return  {
        dataset,
        on: events.addEventListener
    }
}