import { ModulesListView } from './views/modules.list.view';

export function ModulesViewController() {

    const modulesContainer = andy.mainContainer.newView({ code: 'modules-container', route: '/content' });
    const modulesListView = new ModulesListView();

    function constructor() {

        // addToMainMenu();

        modulesContainer.append(modulesListView.element);

        andy.modules.on('module.load.success', addModuleToView);

        modulesListView.on('module.open', (item)=>{
            andy.mainContainer.show(item.package.name + '-container');
        });

    }

    function addModuleToView(module) {
        if (module.package.type.includes('module')) {
            modulesListView.gridList.addItem(module);
        }
    }

    function addToMainMenu() {
        const modulesMenuItem = new andy.UI.MainMenuItem({ code: 'content.modules', label: 'modules', icon: 'mdi-puzzle-outline', order: 2 });
        andy.mainMenu.props.children['root.content.section'].append(modulesMenuItem);

        modulesMenuItem.connect(modulesContainer, 'show', 'select');
    }

    constructor();

    return {

    }
}