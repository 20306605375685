export function PDFTabs() {
    let pdfValues = {};
    let pdfContent = new DIV('content');
    let currentPdf = 0;
    let pdfTabs = new andy.UI.Tabs(null, {
        multiview: false,
        view: pdfContent,
        close: true
    });

    let pdfForm = new andy.UI.Form('pdf_item', {
        media: {
            label: 'media',
            item: new andy.UI.GFMSelect({})
        },
        tags: {
            label: 'Tags',
            item: new andy.UI.Tags()
        },
        language: {
            label: 'language',
            item: new andy.UI.LanguageObject({
                title: {
                    label: 'title',
                    item: new andy.UI.Input({})
                },
                shortDescription: {
                    label: 'short_description',
                    item: new andy.UI.Input({})
                },
                longDescription: {
                    label: 'long_description',
                    item: new andy.UI.Input({})
                },
                extraDescription: {
                    label: 'extra_description',
                    item: new andy.UI.Input({})
                }
            })
        },
    });
    pdfForm.getElement().querySelector('.header').style.display = 'none';
    pdfForm.getElement().querySelector('.footer').style.display = 'none';
    pdfForm.items.language.item.getElement().querySelector('.header').style.display = 'none';
    pdfForm.items.language.item.getElement().querySelector('.footer').style.display = 'none';

    function removeTab(cA) {
        if (pdfValues[cA]) {
            delete pdfValues[cA];
        }

        if (pdfTabs.tabs[cA]) {
            pdfTabs.tabs[cA].remove();
        }

        currentPdf = -1;
    }

    function openTab(cA){
        if (currentPdf >= 0) {
            pdfValues[currentPdf] = pdfForm.getValue();
        }

        currentPdf = cA;

        if (pdfValues[cA]) {
            pdfForm.setValue(pdfValues[cA]);
        }
        else {
            pdfForm.setValue({
                media: null,
                tags: "",
                language: {}
            })
        }
    }

    function addButtonCb(cA) {
        if (pdfValues[cA]) {
            return false;
        }

        pdfContent.append(pdfForm.getElement());
        pdfForm.getElement().style.display = 'initial';

        pdfTabs.add(cA, cA, null, function() {
            openTab(cA);
        }, null, () => {
            removeTab(cA);
        });
       
        openTab(cA);
    }
    
    pdfTabs.addButton('+', (args) => {
        let i = !Object.keys(pdfTabs.tabs)[0] ? 0 : Math.max.apply(null, Object.keys(pdfTabs.tabs)) + 1;
        addButtonCb(i);
    }, {});

    pdfTabs.getValue = function (){
        if (pdfValues[currentPdf]) {
            pdfValues[currentPdf] = pdfForm.getValue();
        }
        return pdfValues;
    }

    pdfTabs.setValue = function(values) {
        pdfForm.getElement().style.display = 'none';
        pdfForm.setValue({
            media: null,
            tags: "",
            language: {}
        });
        pdfTabs.clear();
        for (let key in pdfValues) {
            delete pdfValues[key];
        } 
        pdfValues = {};
        currentPdf = -1;
        
        for (let key in values) {
            currentPdf = parseInt(key);
            addButtonCb(parseInt(key));
            setTimeout((keyValue) => {
                pdfValues[keyValue] = values[keyValue];
                pdfForm.setValue(pdfValues[keyValue]);
            }, 1, key);
        }
    }

    return pdfTabs;
}