export function StudioProjectData() {

    let events = new andy.EventManager();

    let projectsDataset = andy.backend.services.projects.dataset;

    let data = {};

    let hasChanges = false;

    function constructor(){ 
        data = newProject({});  // create a clear model before open any project
    }

    function save(cb) {
        projectsDataset.save(JSON.parse(JSON.stringify(data)), (saved) => {

            if (saved.isNew) {
                data._id = saved.value._id;
            }

            console.log('save:', data);

            if (cb) cb();
        });
        hasChanges = false;
    }

    function newProject(project) {
        hasChanges = false;
        return {
            ... {
                name: "",
                description: "",
                timelines: {},
                schedule: {},
                pages: {},
                structure: []
            },
            ...project
        }
    }

    function clear() {
        data = {};
        // events.dispatchEvent('data', data);
        // events.dispatchEvent('structure', data.structure);
        // events.dispatchEvent('pages', data.pages);
        // events.dispatchEvent('timelines', data.timelines);
        // events.dispatchEvent('schedule', data.schedule);
    }

    constructor(); 

    return {
        set data(p) {
            console.log('open', p);
            data = newProject(p);
            events.dispatchEvent('data', data);
        },
        get data() {
            return data;
        },
        set pages(p) {
            data.pages = p;
            hasChanges = true;
            events.dispatchEvent('pages', data.pages);
        },
        get pages() {
            return data.pages;
        },
        set structure(p) {
            data.structure = p;
            hasChanges = true;
            events.dispatchEvent('structure', data.structure);
        },
        get structure() {
            return data.structure;
        },
        set timelines(p) {
            data.timelines = p;
            hasChanges = true;
            events.dispatchEvent('timelines', data.timelines);
        },
        get timelines() {
            return data.timelines;
        },
        set schedule(p) {
            data.schedule = p;
            hasChanges = true;
            events.dispatchEvent('schedule', data.schedule);
        },
        get schedule() {
            return (data.schedule) ? data.schedule : {};
        },
        get hasChanges() {
            return hasChanges;
        },
        save,
        clear,
        newProject,
        on: events.addEventListener
    }

}