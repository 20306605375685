import { RolesListView } from './views/role.list.view';
import { EditRoleModal } from './views/edit.role.modal';
import { DeleteModal } from './../common/views/delete.modal';

export function RolesController() {


    const rolesContainer = andy.mainContainer.newView({ code: 'admin-roles', route: '/admin/roles' });

    const rolesDataset = andy.backend.services.roles.dataset;

    const rolesListView = new RolesListView();
    const editRoleModal = new EditRoleModal();
    const roleDeleteModal = new DeleteModal();

    function constructor() {
        addToMainMenu();
        rolesContainer.append(rolesListView.element);

        rolesListView.rolesGridList.bind(rolesDataset);


        rolesListView.on('add', editRoleModal.show);
        rolesListView.on('role.edit', editRoleModal.show);
        rolesListView.on('role.delete', (role) => roleDeleteModal.show(role.name, role));

        roleDeleteModal.on('yes', roleDeleteHandler);

        editRoleModal.on('save', saveRoleHandler);
    }

    function roleDeleteHandler(role) {
        rolesDataset.remove(role, () => {
            roleDeleteModal.hide();
        })
    }

    function saveRoleHandler(role) {
        rolesDataset.save(role, () => {
            editRoleModal.hide();
        });
    }

    function addToMainMenu() {
        const rolesMenuItem = new andy.UI.MainMenuItem({ code: 'admin.roles', label: 'roles', icon: 'mdi-account-group', order: 1 });
        andy.mainMenu.props.children['root.admin.section'].append(rolesMenuItem);
        rolesMenuItem.connect(rolesContainer, 'show', 'select');
    }

    constructor();

    return {};
}