let addLanguageCallback = null;
let firstTime = true; 
let newLanguageForm, newLanguageFormModal, langSelect;

function initAfterFramework(){

    langSelect = new andy.UI.Select([], (option, eOption) => {
        return eOption(option, option);
    });

    newLanguageForm = new andy.UI.Form('add_language', {
        language: {
            label: '',
            item: langSelect
        }
    }, {
        add: new andy.UI.Button({
            icon: 'add',
            label: 'add'
        }, function () {
            if (addLanguageCallback) {
                addLanguageCallback(newLanguageForm.getValue().language);
            }
            newLanguageFormModal.hide();
            addLanguageCallback = null;
        })
    })
    
    newLanguageFormModal = new andy.UI.Modal(newLanguageForm);

    firstTime = false; 
}


function addLanguageFromModal(cb) {
    langSelect.clear(); 
    andy.Content.languages.get((data) => {
        if (data.value && data.value[0]) {
            const langObj = data.value[0];
            langSelect.addBulk(langObj.langAvailable);
        }
    });
    addLanguageCallback = cb;
    newLanguageForm.reset();
    newLanguageFormModal.show();
}

export function LanguageObject (fields, options) {

    if(firstTime){
        initAfterFramework(); 
    }
    
    let content = new DIV('content');

    let tabs = new andy.UI.Tabs(null, {
        multiview: false,
        view: content
    });

    let emptyTemplate = null;

    let languages = {};
    let currentLanguage = null;

    let form = new andy.UI.Form('', fields, {});
    emptyTemplate = form.getValue();

    tabs.addButton('+', () => { addLanguageFromModal(lang => addNewLanguage(lang)) }, 'true');

    function saveValues() {
        if (currentLanguage) {
            languages[currentLanguage] = form.getValue();
        }
    }

    function addNewLanguage(code) {
        if (languages[code]) return;

        function openLanguage() {
            saveValues();
            currentLanguage = code;
            if (languages[code]) {
                form.setValue(languages[code]);
            } else {
                if (emptyTemplate) {
                    form.setValue(emptyTemplate);
                }
            }
        }

        tabs.add(code, code, null, openLanguage, null);

        openLanguage();
    }

    function reset() {
        languages = {};
        currentLanguage = null;
        tabs.clear();
    }

    content.append(form.getElement());

    let _public = {
        form: form,
        clear: function () {
            form.clear();
        },
        getElement: function () {
            return tabs.getElement();
        },
        getValue: function () {
            saveValues();
            return languages;
        },
        setValue: function (values) {
            reset();
            if (typeof values === 'object') {
                let valKeys = Object.keys(values);
                valKeys.map(function (lang) {
                    addNewLanguage(lang);
                })
                languages = values;
                if (valKeys.length > 0) {
                    form.setValue(languages[valKeys[0]]);
                    currentLanguage = valKeys[0];
                }
                else {
                    addNewLanguage(andy.Content.default);
                    //form.items[Object.keys(form.items)[0]].item.setValue(values);
                }
                tabs.selectFirst();
            } 
            else {
                addNewLanguage(andy.Content.default);
                //form.items[Object.keys(form.items)[0]].item.setValue(values);
            }
        },
        // bind: function() { console.info('Not implemented yet: form.bind()'); },
        // on: function() { console.info('Not implemented yet: form.on()'); }
    }

    andy.UI._instances.push(_public);
    return _public;
}