export function SequenceModal() {

    const events = new andy.EventManager();

    let sequenceForm, sequenceModal, sequenceList;
    let pages = {}, sequence = {};


    function constructor() {

        sequenceList = new andy.UI.List(null, sequenceItemTemplate, { removable: true, draggable: true, form: {}, placeholder: "without_entries_in_sequence" });

        sequenceForm = new andy.UI.Form('edit_sequence', {
            name: { label: 'name', item: new andy.UI.Input({ validators: andy.Validators.notEmpty }) },
            description: { label: 'description', item: new andy.UI.Input() },
            color: { label: 'color', item: new andy.UI.ColorPicker('rgb(32, 114, 236)') },
            intervals: { label: 'sequence', item: sequenceList }

        }, {
            add: new andy.UI.Button({ label: 'add', icon: 'mdi-add' }, () => {
                sequenceList.add({});
            }),
            save: new andy.UI.Button({ label: 'save', icon: 'mdi-check' }, saveSequenceHandler)
        });

        sequenceModal = new andy.UI.Modal(sequenceForm);
        sequenceModal.on('dismiss', cancelHandler);

    }

    function sequenceItemTemplate(item, eItem) {

        let input = new andy.UI.Input({
            type: 'number',
            value: item.duration ? item.duration : 0
        });

        const selectPages = new andy.UI.Select(pages, (page, add) => {
            add(page._id, page.name, page._id === item.pageId);
        });

        const selectTime = new andy.UI.Select([], (v, add) => {
            add(v, v, v);
        });
        selectTime.add('Seconds');
        selectTime.add('Minutes');
        selectTime.add('Hours');
        selectTime.setValue('Seconds');

        let pageGroup = new andy.UI.Group(
            {
                pageLabel: new andy.UI.Label('Page: '),
                selectPages,
                duration: new andy.UI.Label('Duration: '),
                input,
                selectTime
            },
            {
                style: { justifyContent: 'flex-start' }
            }
        );

        eItem.item.getValue = () => {
            return {
                pageId: selectPages.getValue(),
                duration: (() => {
                    switch (selectTime.getValue()) {
                        case 'Seconds':
                            return input.getValue();
                        case 'Minutes':
                            return input.getValue() * 60;
                        case 'Hours':
                            return input.getValue() * 60 * 60;
                    }
                })()
            };
        };
        return pageGroup.getElement();
    }

    function saveSequenceHandler() {
        if (sequenceForm.validate()) {
            events.dispatchEvent('save', { ...sequence, ...sequenceForm.getValue() });
        }
    };

    function cancelHandler() {
        events.dispatchEvent('cancel');
        reset();
    };

    function reset() {
        sequence = {};
        sequenceForm.reset();
    }

    // function dismissable(val){
    //     sequenceModal.props.dismissable = val; 
    // }

    function show(seq) {
        if (seq) {
            sequence = seq;
            sequenceForm.setValue(sequence);
        }
        sequenceModal.show();
    }

    function hide() {
        sequenceModal.hide();
        reset();
    }


    constructor();

    return {
        set pages(v) {
            pages = v;
        },
        show,
        hide,
        reset,
        // dismissable,
        on: events.addEventListener
    }

}