export function DatabaseListView() {
	
	const events = new andy.EventManager();

	function databaseTemplate(item, render) {
		const wrapper = new DIV('tile');
		const icon = new andy.UI.Icon('mdi-database');
		const label = new andy.UI.Label(item.code);
		const moreDropdownItems = {
            edit: new andy.UI.Button({ label: 'edit', icon: 'mdi-pencil' }, () => events.dispatchEvent('database.edit', item)),
            delete: new andy.UI.Button({ label: 'delete', icon: 'mdi-delete' }, () => events.dispatchEvent('database.remove', item)),
        };

		const moreDropdown = new andy.UI.DropDown({ icon: 'mdi-dots-vertical', items: moreDropdownItems , customClass: 'device-item-more'});
		moreDropdown.getElement().style.marginRight = '0';

		wrapper.addEventListener('click', (e) => {
			e.stopPropagation();
			events.dispatchEvent('database.open', item);
		});

		wrapper.append(
			new andy.UI.Group(
				{ moreDropdown, icon, label },
				{ style: { justifyContent: 'center', alignItems: 'center', flexDirection: 'column' } }
			).getElement()
		);

		render(wrapper);
		return item;
	}

	let databaseGridList = new andy.UI.GridList({
		template: databaseTemplate
	});

	let databaseComponent = new andy.UI.Form('databases', {
		listDatabases: {
			label: null,
			item: databaseGridList
		}
	}, {
		add: new andy.UI.Button({
			icon: 'mdi-plus',
			label: 'create_new_database'
		}, () => {
			events.dispatchEvent('database.create');
		})
	}, {});

	return {
		component: databaseComponent,
		list: databaseGridList,
		element: databaseComponent.getElement(),
		on: events.addEventListener
	};
}