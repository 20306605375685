
export function ProjectSelectModal() {

    const events = new andy.EventManager();

    let projectSelect, projectSelectorForm, projectSelectorModal;

    function constructor() {

        projectSelect = new andy.UI.Select(null, projectOptionTemplate);

        projectSelectorForm = new andy.UI.Form('studio_applications', {
            app: { label: 'project', item: projectSelect }
        }, {
            add: new andy.UI.Button({ icon: 'add', label: 'add' }, addHandler)
        });

        projectSelectorModal = new andy.UI.Modal(projectSelectorForm);

        projectSelectorModal.on('dismiss', cancelHandler);

    }

    function projectOptionTemplate(item, option) {
        option(item._id, item.name, false);
    }

    function addHandler() {
        events.dispatchEvent('add', projectSelect.getValue());
    }

    function cancelHandler() {
        events.dispatchEvent('cancel', projectSelect.getValue());
    }

    function show() {
        projectSelectorModal.show();
    }

    function hide() {
        projectSelectorModal.hide();
    }

    constructor();

    return {
        get projectSelect() {
            return projectSelect;
        },
        show,
        hide,
        on: events.addEventListener
    }
}