export function LoginForm() {

    let events = new andy.EventManager();
    let form;
    let hostnameField = {};

    function constructor() {

        let hostname = andy.data.get('andy.server.hostname');

        if (hostname) {
            hostnameField = {
                hostname: {
                    label: 'hostname',
                    item: new andy.UI.Group({
                        label: new andy.UI.Label(hostname),
                        button: new andy.UI.Button({ icon: 'mdi-close' }, () => {
                            events.dispatchEvent('forget');
                        })
                    })
                }
            }
        } else {
            hostnameField = {
                hostname: {
                    label: 'hostname',
                    item: new andy.UI.Input({ validators: [andy.Validators.notEmpty, hostnameValidator] })
                }
            }
        }

        form = new andy.UI.Form('login', {
            ...hostnameField,
            username: {
                label: 'username',
                item: new andy.UI.Input({ validators: andy.Validators.notEmpty })
            },
            password: {
                label: 'password',
                item: new andy.UI.Input({
                    type: 'password',
                    validators: andy.Validators.notEmpty
                })
            },
        }, {
            recover: new andy.UI.Button({
                icon: 'autorenew',
                label: 'recover_password'
            }, () => {
                events.dispatchEvent('recover');
            }),
            login: new andy.UI.Button({
                icon: 'arrow_forward',
                label: 'login'
            }, submit),
        });

        form.getElement().addEventListener('keypress', (e) => {
            if (e.which == 13) {
                submit();
            }
        });
    }

    function submit() {
        if (form.validate()) {
            events.dispatchEvent('submit', form.getValue());
        }
    }

    function hostnameValidator(hostname) {
        if (window._env.ignoreHostnameValidation) {
            return true;
        }
        const split = hostname.split('.');
        if (split.length == 3) {
            if ((split[1] == 'andy' && split[2] == 'media') || (split[1] == 'crossinganswers' && split[2] == 'com') || (split[1] == 'crossing' && split[2] == 'pt')) {
                return true;
            }
        }else{
            if(split.length == 1){
                return true;
            }
        } 
        return false;
    }

    constructor();

    return {
        get element() {
            return form.getElement();
        },
        on: events.addEventListener
    }
}