import { AdminSettingsView } from './views/settings.view';


export function SettingsController() {

    const settingsContainer = andy.mainContainer.newView({ code: 'admin-settings', route: '/admin/settings' });

    const adminSettingsView = new AdminSettingsView();
 

    function constructor() {
        addToMainMenu();
        settingsContainer.append(...adminSettingsView.elements);


        andy.backend.services.settings.on('settings.data', adminSettingsView.setValues);
        andy.backend.services.settings.get(); 


        adminSettingsView.on('save', (values)=>{
            andy.backend.services.settings.save(values); 
        })

    }

    function addToMainMenu() {
        const settingsMenuItem = new andy.UI.MainMenuItem({ code: 'admin.settings', label: 'settings', icon: 'mdi-wrench-outline', order: 3 });
        andy.mainMenu.props.children['root.admin.section'].append(settingsMenuItem);
        settingsMenuItem.connect(settingsContainer, 'show', 'select');
    }


    constructor();

    return {};
}