import { Calendar } from '@fullcalendar/core';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import allLocales from '@fullcalendar/core/locales-all';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

export function FullCalendar() {

    const events = new andy.EventManager();

    let calendar, calEl, element;

    function constructor() {

        element = new DIV('andy-ui-calendar');
        calEl = new DIV();

        calendar = new Calendar(calEl, {
            plugins: [rrulePlugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
            initialView: 'timeGridWeek',
            timeFormat: "H:mm",
            height: "100%",
            headerToolbar: false,
            nowIndicator: true,
            locales: allLocales,
            locale: 'en', // the initial locale,
            firstDay: 1,
            eventOverlap: false,
            eventResizableFromStart: true,
            eventStartEditable: true,
            eventDurationEditable: true,
            editable: true,
            droppable: true,
            dropAccept: true,
            drop: (info) => events.dispatchEvent('external.drop', info),
            eventReceive: (info) => events.dispatchEvent('event.receive', info),
            eventDrop: (info) => events.dispatchEvent('event.drop', info),
            eventClick: (info) => events.dispatchEvent('event.click', info)
        });

        calendar.on('datesSet', (dateInfo) => {
            events.dispatchEvent('date.range.change', dateInfo);
        })

        element.append(calEl);
    }


    function render() {
        calendar.updateSize();
        calendar.render()
    }

    function weekView() {
        calendar.changeView('timeGridWeek');
    }

    function monthView() {
        calendar.changeView('dayGridMonth');
    }

    function listView() {
        calendar.changeView('listWeek');
    }

    function clear(){
        calendar.getEvents().forEach(event=>event.remove());
    }

    function addEvent() {
        return calendar.addEvent(...arguments);
    }

    function getEventById() {
        return calendar.getEventById(...arguments);
    }


    function makeDraggable() {
        return new Draggable(...arguments);
    }

    constructor();

    return {
        get element() {
            return element;
        },
        render,
        next: () => calendar.next(),
        prev: () => calendar.prev(),
        today: () => calendar.today(),
        weekView,
        monthView,
        listView,
        addEvent,
        makeDraggable,
        getEventById,
        clear,
        on: events.addEventListener
    }

}