import { changeDeviceGroupModal } from './views/change.group.modal';
import { createDeviceGroupModal } from './views/create.group.modal';
import { requestDeviceModal } from './views/request.device.modal';
import { ListDevicesView } from './views/list.devices.view';
import { DeleteModal } from './../common/views/delete.modal';


export function DevicesListController() {

  const devicesContainer = andy.mainContainer.newView({ code: 'devices-container', route: '/devices' });
  const listView = new ListDevicesView();
  const requestModal = new requestDeviceModal();
  const changeGroupModal = new changeDeviceGroupModal();
  const createGroupModal = new createDeviceGroupModal();
  const deleteDeviceModal = new DeleteModal();

  function constructor() {

    addToMainMenu();

    devicesContainer.append(listView.element);


    listView.on('device.open', openDevice);
    listView.gridList.bind(andy.backend.services.devices.dataset);


    //Device Groups

    listView.on('group.device', (deviceId) => {
      changeGroupModal.show();
    });

    changeGroupModal.on('create.group', () => {
      changeGroupModal.hide();
      createGroupModal.show();
    })

    createGroupModal.on('save', (group) => {

    });

    createGroupModal.on('cancel', () => {
      changeGroupModal.show();
    });


    // Request Device 

    listView.on('add', requestModal.show);

    requestModal.on('request', (code) => {
      andy.backend.services.masterServer.requestDeviceFromPool(code);
      requestModal.hide();
    });

    requestModal.on('cancel', () => {
      requestModal.hide();
    });

    listView.on('remove.device', (device) => deleteDeviceModal.show(device.name, device));
    deleteDeviceModal.on('yes', removeDeviceHandler);

  }

  function openDevice(device) {
    if (andy.device.isOpen) {
      andy.device.close(() => {
        andy.device.open(device._id);
      });
    } else {
      andy.device.open(device._id);
    }
  }

  function addToMainMenu() {
    const devicesMenuItem = new andy.UI.MainMenuItem({ code: 'network.devices', label: 'devices', icon: 'mdi-devices', order: 1 });
    andy.mainMenu.props.children['root.network.section'].append(devicesMenuItem);

    devicesMenuItem.connect(devicesContainer, 'show', 'select');
  }

  function removeDeviceHandler(device) {
    andy.backend.services.devices.dataset.save({_id: device._id, enabled: false, deregister: true}, ()=>{
      andy.backend.services.devices.deregister({_id: device._id});
      deleteDeviceModal.hide(); 
    });
  }

  constructor();
  
  return {};

};
