export function AuthController () {

    let events = new andy.EventManager();
    let token = null;

    function constructor() {
        let storedToken = andy.data.get('andy.auth.token');
        if (storedToken) {
            token = storedToken;
        }
    }

    function validateToken() {
        if (token) {
            andy.backend.services.auth.on('login.success', (data) => {
                token = data.token._id;
                andy.data.put('andy.auth.token', token);
                events.dispatchEvent('success', data.user);
            });
            andy.backend.services.auth.on('login.failed', (data) => {
                events.dispatchEvent('failed');
            });
            andy.backend.services.auth.validateToken(token);
            return true; 
        } else {
            return false;
        }
    }

    function login(username, password) {
        andy.backend.services.auth.on('login.success', (data) => {
            token = data.token._id;
            andy.data.put('andy.auth.token', token);
            events.dispatchEvent('success', data.user);
        });
        andy.backend.services.auth.on('login.failed', (data) => {
            events.dispatchEvent('failed', data.user);
        });
        andy.backend.services.auth.login(username, password);
    }

    function logout() {
        andy.backend.services.auth.logout(token); 
        andy.data.remove('andy.auth.token');
        window.location.reload(); 
    }

    function can() {
        return true;
    }

    function getUser() {

    }


    constructor();

    return {
        get token() {
            return token;
        },
        login,
        logout,
        getUser,
        validateToken,
        can,
        on: events.addEventListener
    }

}