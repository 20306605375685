export function Section(p) {

    const events = new andy.EventManager();

    let parent = null;

    const props = {
        ...{
            code: null,
            label: 'section',
            description: '',
            order: 0,
            children: {},
            hide: false
        },
        ...p
    }

    const element = new DIV('section');
    const childrenEl = new DIV('children');
    const headerEl = new DIV('header');
    const descriptionWrapper = new DIV('description');
    const label = new andy.UI.Label();
    const description = new andy.UI.Label();

    function constructor() {

        render();

        descriptionWrapper.append(description.element);
        headerEl.append(label.element, descriptionWrapper);
        element.append(headerEl, childrenEl);
    }

    function append(item) {
        const code = item.props.code;
        if (props.children[code]) {
            console.error('Menu Section - item code already exists', code, item);
            return;
        } else {
            if (item.type == 'item') {
                item.parent = _public;
                props.children[code] = item;
                childrenEl.append(item.element);
                events.dispatchEvent('child.append', item);
            } else {
                console.error('Menu Section - only menu items are accepted', code, item);
            }
        }
    }

    function render() {
        if (props.hide) {
            element.classList.add('hide');
        } else {
            element.classList.remove('hide');
        }
        element.style.order = props.order;
        label.setValue(props.label);
        description.setValue(props.description);
    }

    function removeChild(code) {
        if (props.children[code]) {
            props.children[code].remove();
            props.children[code] = null;
            delete props.children[code];
        }
    }

    function select({ stopProp } = { stopProp: false }) {

        if (parent) {
            parent.deselect({ stopProp: true, except: props.code });
        }

    }

    function deselect({ stopProp, except } = { stopProp: false, except: null }) {
        Object.values(props.children).forEach(child => {
            if (child.props.code != except) child.deselect();
        });

    }

    function hide() {
        props.hide = true;
        render();
    }

    function show() {
        props.hide = false;
        render();
    }

    function remove() {
        element.remove();
    }

    constructor();

    const _public = {
        get element() {
            return element;
        },
        get type() {
            return 'section';
        },
        get props() {
            return props;
        },
        set parent(p) {
            parent = p;
        },
        deselect,
        select,
        render,
        append,
        remove,
        removeChild,
        hide,
        show,
        on: events.addEventListener
    }

    return _public;

}