export function UploadListItem(p) {

    const events = new andy.EventManager();

    const props = {
        ...{
            file: {},
            bytesLoaded: 0
        },
        ...p
    }

    const element = new DIV('upload-list-item hide');
    const icon = new andy.UI.Icon('mdi-file-outline');
    const fileName = new DIV('filename');
    const fileSize = new DIV('filesize');
    const fileProgress = new DIV('x3fg-progress');
    const fileProgressLabel = new andy.UI.Label('progress');
    const progressBar = new DIV('progress-bar');
    const innerBar = new DIV('inner-bar');

    let cancelButton;

    function constructor() {
        cancelButton = new andy.UI.Button({ icon: 'mdi-close' }, () => {

        });

        const fileInfoWrapper = new DIV('file-name-wrapper');
        fileInfoWrapper.append(fileName, cancelButton.getElement());

        const progressWrapper = new DIV('progress-wrapper');
        progressWrapper.append(fileProgress, fileProgressLabel.getElement());

        const infoWrapper = new DIV('info-wrapper');
        infoWrapper.append(fileInfoWrapper, progressBar, progressWrapper);

        progressBar.append(innerBar);

        element.append(/*icon.getElement(),*/ infoWrapper);

        update();
        setTimeout(() => {
            element.classList.remove('hide', 200);
        })

    }

    function update() {
        fileName.innerHTML = props.file.name;
        let progress = props.bytesLoaded / props.file.size;

        innerBar.style.width = parseInt(progress * 100) + '%';
        fileProgress.innerHTML = parseInt(progress * 100) + '%';
        fileProgressLabel.setValue('done');
    }

    function complete() {
        fileProgress.remove();
        fileProgressLabel.setValue('complete');
        element.classList.add('complete');
        setTimeout(() => {
            element.classList.add('hide');
            setTimeout(() => element.remove(), 1000);
        }, 4000);
    }

    constructor();

    return {
        get element() {
            return element;
        },
        get props() {
            return props;
        },
        complete,
        update,
        on: events.addEventListener
    }

}