export function Item(p) {

    const events = new andy.EventManager();

    let parent = null;

    const props = {
        ...{
            code: null,
            label: 'item',
            icon: 'home',
            order: 0,
            children: {},
            selected: false,
            selectable: true,
            collapsed: true
        },
        ...p
    }

    const element = new DIV('item collapsed');
    const childrenEl = new DIV('children');
    const wrapperEl = new DIV('item-wrapper');
    const carotEl = new DIV('carot');

    function constructor() {

        const labelEl = new andy.UI.Label(props.label);
        const iconEl = new andy.UI.Icon(props.icon);

        wrapperEl.append(iconEl.getElement(), labelEl.getElement());
        element.append(wrapperEl, childrenEl);

        element.style.order = props.order;


        wrapperEl.addEventListener('click', e => {
            if (props.selectable) {
                select();
            }
            events.dispatchEvent('click', e);
        });

        carotEl.append(new andy.UI.Icon('mdi-chevron-down').getElement());

        carotEl.addEventListener('click', e=>{
            if(props.collapsed){
                expand(); 
            }else{
                collapse(); 
            }
        });

        update(); 
    }

    function update(){
        let childArray = Object.values(props.children);
        if(childArray.length > 0){
            wrapperEl.append(carotEl);  
            let calculatedHeight = getComputedStyle(childArray[0].element).height.split('px')[0]; ;
            childrenEl.style.height = calculatedHeight * childArray.length + 'px';
        }else{
            carotEl.remove();
        }
    }

    function expand(){
        props.collapsed = false; 
        element.classList.remove('collapsed');
    }

    function collapse(){
        props.collapsed = true; 
        element.classList.add('collapsed');
    }

    function append(item) {
        const code = item.props.code;
        if (props.children[code]) {
            console.error('Menu Child - item code already exists', code, item);
            return;
        } else {
            if (item.type == 'item') {
                item.parent = _public;
                props.children[code] = item;
                childrenEl.append(item.element);
            } else {
                console.error('Menu Child - only menu items are accepted', code, item);
                return;
            }
        }
        update(); 
    }

    function removeChild(code) {
        if (props.children[code]) {
            props.children[code].remove();
            props.children[code] = null;
            delete props.children[code];
            update(); 
        }
    }

    function remove() {
        element.remove();
    }

    function select({ stopProp } = { stopProp: false}) {

        props.selected = true;
        element.classList.add('selected');

        if(parent){
            parent.select({stopProp: true});
            parent.deselect({stopProp: true, except: props.code});
        }

        if (!stopProp) {
            events.dispatchEvent('select', props.selected);
        }

    }

    function deselect({ stopProp , except} = { stopProp: false, except : null }) {

        if(!except){
            props.selected = false;
            element.classList.remove('selected');
        }

        Object.values(props.children).forEach(child => {
            if(child.props.code != except) child.deselect(); 
        });

        if (!stopProp) {
            events.dispatchEvent('deselect', props.selected);
        }
 
    }

    function connect(component, paramA, paramB) {
        component.on(paramA, () => _public[paramB]({ stopProp: true }));
        events.addEventListener(paramB, () => component[paramA]({ stopProp: true }));
    }

    constructor();

    const _public = {
        get element() {
            return element;
        },
        get type() {
            return 'item';
        },
        get props() {
            return props;
        },
        set parent(p){
            parent = p; 
        },
        append,
        remove,
        removeChild,
        connect,
        select,
        deselect,
        expand,
        collapse,
        on: events.addEventListener
    }

    return _public;

}