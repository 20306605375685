
export function EditRoleModal() {

    const events = new andy.EventManager();

    let roleAdministrationForm, roleDevicesForm, roleApplicationsForm, roleLayoutsForm, roleContentForm, roleModulesForm, roleStatisticsForm, rolePermissionsForm, roleForm, roleModal;
    let role = null;

    function constructor() {
        roleAdministrationForm = new andy.UI.Form('administrator', {
            usersCreate: { label: 'users_create', item: new andy.UI.Switch() },
            usersDelete: { label: 'users_delete', item: new andy.UI.Switch() },
            usersCredentials: { label: 'users_credentials', item: new andy.UI.Switch() }
        });

        roleDevicesForm = new andy.UI.Form('devices', {
            devicePower: { label: 'device_power', item: new andy.UI.Switch() },
            deviceReboot: { label: 'device_reboot', item: new andy.UI.Switch() },
            deviceDisplays: { label: 'device_displays', item: new andy.UI.Switch() },
            deviceInputs: { label: 'device_inputs', item: new andy.UI.Switch() },
            deviceNetwork: { label: 'device_network', item: new andy.UI.Switch() },
            deviceSettings: { label: 'device_settings', item: new andy.UI.Switch() },
            deviceTerminal: { label: 'device_terminal', item: new andy.UI.Switch() }
        });

        roleApplicationsForm = new andy.UI.Form('applications', {
            applicationEdit: {
                label: 'application_edit',
                item: new andy.UI.Switch()
            },
            applicationCreate: {
                label: 'application_create',
                item: new andy.UI.Switch()
            },
            applicationRemove: {
                label: 'application_delete',
                item: new andy.UI.Switch()
            }
        });

        roleLayoutsForm = new andy.UI.Form('layouts', {
            layoutEdit: {
                label: 'layout_edit',
                item: new andy.UI.Switch()
            },
            layoutCreate: {
                label: 'layout_create',
                item: new andy.UI.Switch()
            },
            layoutRemove: {
                label: 'layout_delete',
                item: new andy.UI.Switch()
            }
        });

        roleContentForm = new andy.UI.Form('content', {
            contentEdit: {
                label: 'content_edit',
                item: new andy.UI.Switch()
            },
            mediaManagerView: {
                label: 'mediaManager_View',
                item: new andy.UI.Switch()
            },
            mediaManagerAdd: {
                label: 'mediaManager_Add',
                item: new andy.UI.Switch()
            },
            mediaManagerDelete: {
                label: 'mediaManager_Delete',
                item: new andy.UI.Switch()
            },
        });

        roleModulesForm = new andy.UI.Form('modules', {
            moduleEdit: {
                label: 'module_edit',
                item: new andy.UI.Switch()
            }
        });

        roleStatisticsForm = new andy.UI.Form('statistics', {
            statisticsView: {
                label: 'statistics_view',
                item: new andy.UI.Switch()
            }
        });

        // rolePermissionsForm = new andy.UI.Form('role_permissions', {
        //     administration: { label: '', item: roleAdministrationForm },
        //     devices: { label: '', item: roleDevicesForm },
        //     // applications: { label: '', item: roleApplicationsForm },
        //     // layouts: { label: '', item: roleLayoutsForm },
        //     content: { label: '', item: roleContentForm },
        //     // modules: { label: '', item: roleModulesForm },
        //     statistics: { label: '', item: roleStatisticsForm }
        // });

        roleForm = new andy.UI.Form('role', {
            name: { label: 'name', item: new andy.UI.Input() },
            description: { label: 'description', item: new andy.UI.Input() },
            enabled: { label: 'enabled', item: new andy.UI.Switch() },
            tags: { label: 'tags', item: new andy.UI.Tags() },
            // permissions: { label: '', item: rolePermissionsForm }
        }, {
            save: new andy.UI.Button({ icon: 'save', label: 'save' }, saveHandler)
        });

        roleModal = new andy.UI.Modal(roleForm);
        roleModal.on('dismiss', cancelHandler);
    }

    function saveHandler() {
        if(roleForm.validate()){
            events.dispatchEvent('save', (role) ? {...role, ...roleForm.getValue()} : roleForm.getValue());
        }
    };

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function reset(){
        role = null;
        roleForm.reset(); 
    }

    function show(r) {
        if(r){
            role = r; 
            roleForm.setValue(r);
        }else{
            reset(); 
        }
        roleModal.show();
    }

    function hide() {
        roleModal.hide();
    }

    constructor();

    return {
        show,
        hide,
        reset,
        on: events.addEventListener
    }
}