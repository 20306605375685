
import { UsersListView } from './views/user.list.view';
import { EditUserModal } from './views/edit.user.modal';
import { DeleteModal } from './../common/views/delete.modal';


export function AccountsController() {

    const usersContainer = andy.mainContainer.newView({ code: 'admin-users', route: '/admin/users' });

    const usersDataset = andy.backend.services.users.dataset;
    const rolesDataset = andy.backend.services.roles.dataset;

    const usersListView = new UsersListView();
    const editUserModal = new EditUserModal();
    const deleteAccountModal = new DeleteModal(); 


    function constructor() {
        addToMainMenu();
        usersContainer.append(usersListView.element);

        usersListView.usersGridList.bind(usersDataset);
        editUserModal.roleSelect.bind(rolesDataset);
        editUserModal.on('save', saveAccountHandler);

        usersListView.on('add', editUserModal.show);
        usersListView.on('account.delete', (account)=>deleteAccountModal.show(account.name, account));
        usersListView.on('account.edit', editUserModal.show); 

        deleteAccountModal.on('yes', deleteAccountHandler);

    }

    function addToMainMenu() {
        const accountsMenuItem = new andy.UI.MainMenuItem({ code: 'admin.accounts', label: 'accounts', icon: 'mdi-account-cog-outline', order: 0 });
        andy.mainMenu.props.children['root.admin.section'].append(accountsMenuItem);
        accountsMenuItem.connect(usersContainer, 'show', 'select');
    }

    function deleteAccountHandler(account){  // TODO - Protect and Error Handle - needs to exist at least one admin account
        usersDataset.remove(account, ()=>{
            deleteAccountModal.hide()
        })
    }

    function saveAccountHandler(account){
        usersDataset.save(account, ()=>{
            editUserModal.hide(); 
        });
    }


    constructor();

    return {};
}