export function LanguageService ({socket}) {

    const events = new andy.EventManager();

    socket.on('device.languages', (data) => {
        switch (data.action) {
            case 'list':
                events.dispatchEvent('device.languages.list', data);
                break;
            case 'update':
                events.dispatchEvent('device.languages.update', data)
                break;
        }
    }); 
    
    events.addEventListener('device.languages.get', () => {
        socket.emit('device.languages', {
            action: 'get',
            value: '',
            token: andy.Auth.token
        });
    });

    events.addEventListener('device.languages.save', (data) => {
        socket.emit("device.languages", {
            action: 'save',
            value: data.values,
            token: andy.Auth.token
        });
    })

    return  {
        on: events.addEventListener,
        emit: events.dispatchEvent
    }
}