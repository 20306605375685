export function NewProjectModal() {

    const events = new andy.EventManager();

    let newProjectForm, newProjectModal, importGroup, fileImporter, importData;

    function constructor() {

        fileImporter = new andy.UI.FileImport({ accept: 'application/json' }, importProjectHandler)

        importGroup = new andy.UI.Group({
            filename: new andy.UI.Label('without_file'),
            remove: new andy.UI.Button({ icon: 'mdi-close' }, removeImportHandler)
        });

        newProjectForm = new andy.UI.Form(
            'new_project',
            {
                name: { label: 'name', item: new andy.UI.Input({ validators: andy.Validators.notEmpty }) },
                description: { label: 'description', item: new andy.UI.Input() },
                importInfo: { label: 'import', item: importGroup, filter: 'import' }
            },
            {
                fileImporter,
                create: new andy.UI.Button({ label: 'create', icon: 'mdi-plus' }, newProjectHandler)
            }
        );

        newProjectModal = new andy.UI.Modal(newProjectForm);
        newProjectModal.on('dismiss', cancelHandler);
    }

    function validateImportStructure(value) {
        if (Array.isArray(value.structure) && typeof value.name == 'string' && typeof value.pages == 'object') {
            return value;
        }
        return null;
    }

    function importProjectHandler(value) {
        importData = validateImportStructure(value);
        if (importData) {
            newProjectForm.setValue(importData);
            importGroup.setValue({
                filename: fileImporter.file.name
            });
            newProjectForm.applyFilter('import');
        } else {
            //notify error
        }
    }

    function removeImportHandler() {
        fileImporter.reset();
        importData = null;
        importGroup.setValue({
            filename: 'without_file'
        });
        newProjectForm.applyFilter('none');
    }

    function newProjectHandler() {
        if (newProjectForm.validate()) {
            if (importData) {
                events.dispatchEvent('import.project', { ...importData, ...newProjectForm.getValue() });
            } else {
                events.dispatchEvent('new.project', newProjectForm.getValue());
            }
        }
    };

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function reset() {
        newProjectForm.reset();
        removeImportHandler();
    }

    function show() {
        reset();
        newProjectModal.show();
    }

    function hide() {
        newProjectModal.hide();
    }

    constructor();

    return {
        show,
        hide,
        reset,
        on: events.addEventListener
    }
}