import { LoginForm } from './forms/login';
import { RecoverForm } from './forms/recover';

export function EntryPointView() {

    const events = new andy.EventManager(); 
    let wrapper = new DIV('login-wrapper');
    let loginFormWrapper = new DIV('login-form-wrapper');
    let left = new DIV('left');
    let right = new DIV('right');
    // let loginC = new DIV('');
    // let recoverC = new DIV('');
    // let loadingC = new DIV('loading');
    // let successC = new DIV('success');
    let logoCut = new DIV('andy-logo');
    let domAppended = false;
    let isVisible = true;

    let loginContainer = new andy.UI.Container({ code: 'entrypoint', route: '', wrapper: true });

    let loginForm = new LoginForm();
    let recoverForm = new RecoverForm();

    function constructor() {


        let loginC = loginContainer.newView({
            code: 'login',
            route: '/login'
        });

        let recoverC = loginContainer.newView({
            code: 'recover',
            route: '/recover-account'
        });


        let loadingC = loginContainer.newView({
            code: 'loading',
            route: '/loading'
        });

        let successC = loginContainer.newView({
            code: 'sendSuccess',
            route: '/sent'
        });

        loadingC.append(renderLoginSpinner());
        successC.append(new H2("success", "success"));
        right.append(loginContainer.getElement());
        loginFormWrapper.append(left,right);
        wrapper.append(loginFormWrapper);

        left.append(logoCut);

        loginC.append(loginForm.element);
        recoverC.append(recoverForm.element);

        loginForm.on('recover', () => {
            loginContainer.show('recover');
        });

        loginForm.on('forget', () => {
            andy.data.remove('andy.server.hostname');
            window.location.reload();
        });

        loginForm.on('submit', (data) => {

            loadingC.show();

            if (typeof data.hostname == 'string') {
                andy.backend.on('auth.available', () => {
                    andy.Auth.login(data.username, data.password);
                })

                andy.backend.connect(data.hostname, () => {
                    andy.data.put('andy.server.hostname', data.hostname);
                });

            } else {
                andy.Auth.login(data.username, data.password);
            }

        });

        recoverForm.on('cancel', () => {
            loginContainer.show('login');
        })

        recoverForm.on('submit', () => {

        })
    }

    function renderLoginSpinner() {
        let spinner = new DIV('spinner');
        spinner.append(new DIV('bounce1'), new DIV('bounce2'), new DIV('bounce3'));
        return spinner;
    }

    function hide() {
        isVisible = false;
        wrapper.classList.add('open');
        setTimeout(() => {
            if (!isVisible) {
                wrapper.remove(); 
            }
        }, 1000);
    }

    function show(view) {
        if (!isVisible) {
            isVisible = true;
            wrapper.classList.remove('open');
        }
        switch (view) {
            case 'login':
                loginContainer.show('login');
                break;
        }
    }

    function appendToDocument() {
        if (!domAppended) {
            domAppended = true;
            document.body.appendChild(wrapper);
        }
    }

    constructor();

    return {
        get element() {
            return wrapper;
        },
        appendToDocument,
        hide,
        show,
        on: events.addEventListener
    }

}