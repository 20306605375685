import {EntryPointView} from './entrypoint.view'; 

export function EntryPointController() {

    const events = new andy.EventManager(); 

    const entryPointView = new EntryPointView();

    function constructor() {

        let hostname = andy.data.get('andy.server.hostname');
        entryPointView.hide(); 

        andy.backend.on('auth.available', ()=>{
            // TODO check if is a andy server and it's version
            console.log('Backend, connected success'); 

            andy.Auth.on('failed', ()=>{
                setTimeout(()=>{
                    entryPointView.appendToDocument();
                    entryPointView.show('login');
                    andy.events.dispatchEvent('login.required');
                }, 300);
            });

            if(!andy.Auth.validateToken()){
                entryPointView.appendToDocument();
                entryPointView.show('login');
                andy.events.dispatchEvent('login.required');
            };
        })

        if(hostname){
            andy.backend.connect(hostname);
        }else{
            entryPointView.appendToDocument();
            entryPointView.show('login');
            andy.events.dispatchEvent('login.required');
        }
    }

    function  hide() {
        entryPointView.hide(); 
    }

    
    constructor(); 

    return {
        hide,
        on: events.addEventListener
    }

}