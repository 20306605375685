export function FilesListView() {

    const events = new andy.EventManager();

    let filesGridList, gfuForm;

    function constructor() {

        filesGridList = new andy.UI.GridList({
            template: fileTemplate,
            groupFunction: groupByType
        });

        gfuForm = new andy.UI.Form('files', {
            grid: { item: filesGridList }
        }, {
            upload: new andy.UI.Button({ label: 'upload', icon: 'cloud_upload' }, () => events.dispatchEvent('upload')),
            // save: saveButton,
            // cancel: cancelButton
        }, {
            search: new andy.UI.SearchBox(filesGridList)
        });

    }

    function fileTemplate(item, render) {
        let wrapper = new DIV('gfu-file-item');

        wrapper.classList.add(item.type);

        if (item.type) {
            item.type = item.type.split('/')[0];
        }

        let svgIcon = '';
        if (item.type == 'image' /*  && item.filename.split('.').pop().indexOf('svg') > -1 */ ) {
            svgIcon = new andy.UI.Icon('mdi-file-image-outline');
        } else if (item.type == 'application' || item.type == 'document') {
            svgIcon = new andy.UI.Icon('mdi-file-outline');
        } else if (item.type == 'audio') {
            svgIcon = new andy.UI.Icon('mdi-file-music-outline');
        } else if (item.type == 'video') {
            svgIcon = new andy.UI.Icon('mdi-file-video-outline')
        } else {
            svgIcon = new andy.UI.Icon('mdi-file-alert-outline');
        }

        if (svgIcon != '' && svgIcon.getElement) {
            svgIcon.getElement().style.fontSize = "90px";
            svgIcon.getElement().style.opacity = 0.1;
        }

        if (item.type == 'image' || item.type == 'video') {
            if (window.location.host.indexOf('http:') > -1 || window.location.host.indexOf('https:') > -1) {
                testImage(`${andy.backend.location}/thumbnails/${item.thumbnail}`, (url, success) => {
                    if (success) {
                        wrapper.style.backgroundImage = `url('${andy.backend.location}/thumbnails/${item.thumbnail}')`;
                    } else {
                        if (svgIcon != '' && svgIcon.getElement) {
                            wrapper.append(svgIcon.getElement());
                        }
                    }
                })

            } else {
                testImage(`${andy.backend.location}/thumbnails/${item.thumbnail}`, (url, success) => {
                    if (success) {
                        wrapper.style.backgroundImage = `url('${andy.backend.location}/thumbnails/${item.thumbnail}')`;
                    } else {
                        if (svgIcon != '' && svgIcon.getElement) {
                            wrapper.append(svgIcon.getElement());
                        }
                    }
                });
            }
        } else {
            if (svgIcon != '' && svgIcon.getElement) {
                wrapper.append(svgIcon.getElement());
            }
        }

        const moreDropdownItems = {
            // edit: new andy.UI.Button({ label: 'edit', icon: 'mdi-pencil' }, () => events.dispatchEvent('file.edit', item)),
            edit: new andy.UI.Button({ label: 'copy_id_clipboard', icon: 'mdi-content-copy' }, () => events.dispatchEvent('file.copy.id', item)),
            download: new andy.UI.Button({ label: 'download', icon: 'mdi-cloud-download-outline' }, () => events.dispatchEvent('file.download', item)),
            delete: new andy.UI.Button({ label: 'delete', icon: 'mdi-delete-outline' }, () => events.dispatchEvent('file.delete', item))
        };

        const moreDropdown = new andy.UI.DropDown({ icon: 'mdi-dots-vertical', items: moreDropdownItems, customClass: 'device-item-more' });


        let label = new andy.UI.Label((item.name) ? item.name : 'item');

        wrapper.addEventListener('click', () => events.dispatchEvent('file.details', item));

        wrapper.append(label.getElement(), moreDropdown.getElement());
        render(wrapper);
        return item;
    }

    function groupByType(item) {
        if (item.type) {
            const type = item.type.split('/');
            if (type.length) {
                return type[0];
            }
        }
        return 'document';
    }

    function testImage(url, callback, timeout) {
        timeout = timeout || 5000;
        var timedOut = false,
            timer;
        var img = new Image();
        img.onerror = img.onabort = function() {
            if (!timedOut) {
                clearTimeout(timer);
                callback(url, false);
            }
        };
        img.onload = function() {
            if (!timedOut) {
                clearTimeout(timer);
                callback(url, true);
            }
        };
        img.src = url;
        timer = setTimeout(function() {
            timedOut = true;
            callback(url, false);
        }, timeout);
    }

    constructor();

    return {
        get element() {
            return gfuForm.element;
        },
        get filesGridList() {
            return filesGridList;
        },
        on: events.addEventListener
    }
}

// let saveButton = new andy.UI.Button({ label: 'save', icon: 'save' }, function () {
//     if (selectModalCallback) {
//         selectModalCallback(filesGridList.getValue());
//         selectModalCallback = null;
//     }
//     showInBlock();
// });

// let cancelButton = new andy.UI.Button({ label: 'cancel', icon: 'cancel' }, function () {
//     showInBlock();
// });