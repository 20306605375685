export function PageSettingsModal() {

    const events = new andy.EventManager();

    let pageSettingsForm, pageSettingsModal;

    function constructor() {
        pageSettingsForm = new andy.UI.Form('page_settings', {
            name: { label: 'name', item: new andy.UI.Input({ validators: andy.Validators.notEmpty }) },
            description: { label: 'description', item: new andy.UI.Input() },
            code: { label: 'code', item: new andy.UI.Input(), description: 'page_code_description' },
            width: { label: 'width', item: new andy.UI.Input({ type: 'number' }) },
            height: { label: 'height', item: new andy.UI.Input({ type: 'number' }) },
            backgroundColor: { label: 'background_color', item: new andy.UI.ColorPicker() },
            masterPage: { label: 'master_page', item: new andy.UI.Switch(false), description: 'master_page_description' }
        }, {
            done: new andy.UI.Button({ label: 'done', icon: 'mdi-check' }, saveHandler)
        });

        pageSettingsModal = new andy.UI.Modal(pageSettingsForm, true);
        pageSettingsModal.on('dismiss', cancelHandler);
    }

    function saveHandler() {
        if (pageSettingsForm.validate()) {
            events.dispatchEvent('save', pageSettingsForm.getValue());
        }
    };

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function reset() {
        pageSettingsForm.reset();
    }

    function show(page) {
        if (page) {
            pageSettingsForm.setValue({
                ...page,
                width: page.studio.size.w,
                height: page.studio.size.h,
                backgroundColor: page.studio.backgroundColor
            });
        }
        pageSettingsModal.show();
    }

    function hide() {
        pageSettingsModal.hide();
        reset();
    }

    constructor();

    return {
        show,
        hide,
        reset,
        on: events.addEventListener
    }
}