export function BrandspotKeyComponent(opts) {

    const events = new andy.EventManager();
    const service = require('./service/brandspotService')();

    let value = {
        id: null,
        public: null,
        private: null
    }

    const publicKeyLabel = new andy.UI.Label('public_key');
    publicKeyLabel.getElement().style.minWidth = "80px";
    const publicKey = new andy.UI.Input({ type: 'text' })
    const registerButton = new andy.UI.Button({ label: 'register', icon: 'mdi-lock-outline' }, registerPublicKey);
    const unregisterButton = new andy.UI.Button({ label: 'unregister', icon: 'mdi-lock-open-outline' }, unregisterKeys);

    unregisterButton.hide();
    registerButton.show();

    const groupComponent = new andy.UI.Group({
        publicKeyLabel,
        publicKey,
        registerButton,
        unregisterButton
    });

    groupComponent.getElement().style.maxWidth = "50%";

    function getValue() {
        return {
            public: publicKey.getValue(),
            private: value.private ? value.private : null,
            id: value.id ? value.id : null
        };
    }

    function getElement() {
        return groupComponent.getElement();
    }

    function setValue(val) {
        value = {
            public: val.public,
            private: val.private,
            id: val.id
        }

        publicKey.setValue(val.public);

        if (value.private) {
            registerButton.hide();
            unregisterButton.show();
            publicKey.getElement().setAttribute('disabled', true);
        }
        else {
            unregisterButton.hide();
            registerButton.show();
            publicKey.getElement().removeAttribute('disabled');
        }
    }

    function registerPublicKey() {
        service.authenticate({ key: publicKey.getValue() }, ({ err, res }) => {
            if (err) {
                events.dispatchEvent('error', err);
            }
            else {
                console.log('Brandspot key registered: ', res);

                setValue({
                    public: publicKey.getValue(),
                    private: res.secretToken,
                    id: res.id
                });

                events.dispatchEvent('force-save');
            }
        });
    }

    function unregisterKeys() {
        if (window.confirm("Do you really want to unregister this key? This action cannot be undone.")) {
            setValue({
                public: null,
                private: null,
                id: null
            });
    
            events.dispatchEvent('force-save');
        }
    }

    return Object.freeze({
        getElement,
        getValue,
        setValue,
        on: events.addEventListener
    });
}