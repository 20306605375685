import { CpuInfoWidget } from './../widgets/cpuinfo';
import { MemoryInfoWidget } from './../widgets/memoryinfo';
import { UptimeInfoWidget } from './../widgets/uptimeinfo';
import { LiveScreen } from './../widgets/livescreen';
// import {PlayerInfoWidget} from './../widgets/playerinfo';
import { NetworkInfoWidget } from './../widgets/networkinfo';

export function DeviceStatusView() {

    const events = new andy.EventManager();

    const cpuinfo = new CpuInfoWidget();
    const uptime = new UptimeInfoWidget();
    const memoryinfo = new MemoryInfoWidget();
    const livescreen = new LiveScreen();
    // const playerinfo = new PlayerInfoWidget();
    const networkInfo = new NetworkInfoWidget();

    let liveScreenForm;
    let networkInfoForm;
    let cpuStatusForm;
    let memoryStatusForm;
    let uptimeStatusForm;
    let osStatusForm;
    let statusForm;

    function constructor() {

        livescreen.init({ width: 450, height: 200 });
        livescreen.request = (settings) => events.dispatchEvent('screenshot.request', settings);
        livescreen.stop();

        liveScreenForm = new andy.UI.Form('live_screen', {
            screen: { label: '', item: livescreen }
        });

        networkInfoForm = new andy.UI.Form('network_info', {
            networkInfo: { label: '', item: networkInfo }
        })

        cpuStatusForm = new andy.UI.Form('cpu_info', {
            model: { label: '', item: cpuinfo.getModel() },
            cores: { label: '', item: cpuinfo.getCores() },
            usage: { label: '', item: cpuinfo }
        });

        memoryStatusForm = new andy.UI.Form('memory_info', {
            memory: { label: 'memory_usage', item: memoryinfo },
        });

        uptimeStatusForm = new andy.UI.Form('uptime_info', {
            uptime: { label: 'uptime', item: uptime },
        });

        osStatusForm = new andy.UI.Form('os_info', {
            type: { label: 'type', item: new andy.UI.Label() },
            os: { label: 'system', item: new andy.UI.Label() },
            architecture: { label: 'architecture', item: new andy.UI.Label() }
        });

        let groupOptions = { style: { alignItems: 'flex-start' } };

        statusForm = new andy.UI.Form('status', {
            livescreen: {
                label: '', item: new andy.UI.Group({ live: liveScreenForm, uptime: uptimeStatusForm, os: osStatusForm }, groupOptions)
            },
            hardware: {
                label: '', item: new andy.UI.Group({ cpu: cpuStatusForm, memory: memoryStatusForm, network: networkInfoForm }, groupOptions)
            }
        });

    }

    function setUptime(data) {
        uptime.update(data);
    }

    function setCpuUsage(data) {
        cpuinfo.updateStreams(data);
    }

    function setScreenshot(data) {
        livescreen.update(data.buffer);
    }

    function setDetails(data) {
        memoryinfo.setTotalMem(data.totalMemory);

        osStatusForm.setValue({
            type: data.type,
            os: `${data.os.name} | ${data.os.version}`,
            architecture: data.architecture
        });

        uptime.update(data.uptime);
        networkInfo.set(data.networkInterfaces);

        cpuinfo.init(data.cpus);

        livescreen.play();

    }

    function setFreeMemory(data) {
        memoryinfo.update(data);
    }

    function reset() {
        osStatusForm.setValue({
            type: '',
            os: '',
            architecture: ''
        });
        uptime.delete();
        networkInfo.delete();
        cpuinfo.delete();
        memoryinfo.delete();
        livescreen.stop();
    }

    constructor();

    return {
        get element() {
            return statusForm.getElement();
        },
        reset,
        setUptime,
        setFreeMemory,
        setCpuUsage,
        setScreenshot,
        setDetails,
        on: events.addEventListener
    }
}