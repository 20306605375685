export function ProjectListView() {

    const events = new andy.EventManager();

    let gridList, searchBox, projectsListForm;

    function constructor() {
        gridList = new andy.UI.GridList({
            groupFunction: item => { return item.group || 'No group'; },
            groupTemplate: groupProjectTemplate,
            template: createProjectItemTemplate,
        });

        searchBox = new andy.UI.SearchBox(gridList, { key: 'name' });

        projectsListForm = new andy.UI.Form('projects', {
            list: { item: gridList }
        }, {
            add: new andy.UI.Button({ label: 'add', icon: 'mdi-plus' }, () => events.dispatchEvent('add')),
        }, {
            search: searchBox
            // filter: new andy.UI.DropDown({ //TODO - Sort & Filter
            //     icon: 'mdi-sort',
            //     label: 'sort_by',
            //     items: sortByDropdown
            // })
        });

    }

    function groupProjectTemplate(code, wrapper) {
        let group = {
            code,
            label: new andy.UI.Label(code),
            header: new DIV('header'),
            element: new DIV('content')
        };

        let options = new optionsMenu();
        if (code !== 'No group') {
            options.addOption('Delete group', () => {
                Object.keys(projectsDataset.data).forEach(projectKey => {
                    let object = projectsDataset.data[projectKey];
                    if (object.group == code) {
                        object.group = 'No group';
                        projectsDataset.save(object);
                    }
                });
            });
        }

        let groupUI = new andy.UI.Group(
            {
                label: group.label,
                options: options
            },
            {
                style: { justifyContent: 'flex-start', position: 'relative' }
            }
        );
        group.header.append(groupUI.getElement());

        let groupWrapper = new DIV('group-wrapper');
        groupWrapper.append(group.header, group.element);

        wrapper.append(groupWrapper);
        return group;
    }

    const createProjectItemTemplate = (item, render) => {
        let wrapper = new DIV('item');

        const moreDropdownItems = {
            duplicate: new andy.UI.Button({ label: 'duplicate', icon: 'mdi-content-duplicate' }, () => events.dispatchEvent('project.duplicate', item)),
            export: new andy.UI.Button({ label: 'export', icon: 'mdi-folder-download-outline' }, () => events.dispatchEvent('project.export', item)),
            delete: new andy.UI.Button({ label: 'delete', icon: 'mdi-delete-outline' }, () => events.dispatchEvent('project.delete', item))
        };

        const moreDropdown = new andy.UI.DropDown({ icon: 'mdi-dots-vertical', items: moreDropdownItems, customClass: 'device-item-more' });

        const label = new andy.UI.Label(item.name ? item.name : 'item');

        wrapper.addEventListener('click', () => {
            studio.open(item);
        });

        wrapper.append(moreDropdown.getElement(), label.element);
        render(wrapper);
        return item;
    };


    constructor();

    return {
        get element() {
            return projectsListForm.element;
        },
        get gridList() {
            return gridList;
        },
        on: events.addEventListener
    }
}