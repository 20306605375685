export function LiveScreen() {

    var scope = this;

    this.rate = 10;
    this.quality = 20;


    var screen = new DIV('live-screen');


    function restartScreen() {
        screen.style.backgroundSize = "24px";
        screen.style.backgroundImage = "url('assets/img/ic_cast.png')";
    }

    var stop = true;

    this.update = (buffer) => {
        screen.style.backgroundSize = "contain";
        screen.style.backgroundImage = "url('" + 'data:image/jpeg;base64,' + buffer + "')";
    };

    this.getElement = () => {
        return screen;
    }

    this.init = (screenSize, rate) => {
        restartScreen();
        if (typeof rate != 'undefined') scope.rate = rate;

        screen.style.width = screenSize.width + 'px';
        screen.style.height = screenSize.height + 'px';


        stop = false;

        thick();
    };

    this.stop = () => {
        stop = true;
    }

    this.close = () => {
        scope.end();
        restartScreen();
    }

    this.play = () => {
        stop = false;
        thick();
    }

    function thick() {
        if (!stop) {
            scope.request({ quality: scope.quality });
            setTimeout(thick, parseInt(60000 / scope.rate));
        }
    }

    this.request = () => {}

}