export function SettingsService({ socket }) {

    const events = new andy.EventManager();

    function constructor() {
        socket.on('settings', (data) => {
            switch (data.action) {
                case 'list':
                    events.dispatchEvent('settings.data', data.value[0])
                    break;
                case 'update':
                    events.dispatchEvent('settings.data', data.value[0])
                    break;
            }
        });
    }

    function save(value) {
        socket.emit('settings', {
            action: 'save',
            value,
            token: andy.Auth.token
        });
    }

    function get() {
        socket.emit('settings', {
            action: 'get',
            value: '',
            token: andy.Auth.token
        });
    }

    constructor();

    return {
        save,
        get,
        on: events.addEventListener
    }
}