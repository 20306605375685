export function FileImport(p, callback) {

    const events = new andy.EventManager(); 

    const props = {
        ...{
            label: 'import',
            icon: 'mdi-folder-upload-outline',
            json: true,
            accept: ''
        },
        ...p
    }

    let button, fileInput, fileReader, readValue;


    function constructor() {
        fileReader = new FileReader();
        fileInput = new INPUT('');
        fileInput.type = 'file';
        fileInput.accept = props.accept;
        button = new andy.UI.Button({ label: props.label, icon: props.icon }, fileInputHandler);

        fileInput.addEventListener('change', fileSelectHandler);
        fileReader.addEventListener('load', fileLoadedHandler);
    }

    function fileInputHandler() {
        fileInput.click();
    }

    function fileSelectHandler(e) {
        fileReader.readAsText(fileInput.files[0]); 
        events.dispatchEvent('selected', fileInput.files); 
    }

    function fileLoadedHandler(e) {
        const content = e.target.result;
        if(props.json){
            readValue = JSON.parse(content);
        }else{
            readValue = content;
        }

        events.dispatchEvent('loaded', readValue); 
        if(callback){
            callback(readValue); 
        }
    }

    function getElement() {
        return button.getElement();
    }

    function getValue() {
        return readValue;
    }

    function reset(){
        readValue = null;
    }

    constructor();

    return {
        get element() {
            return getElement();
        },
        get props() {
            return props;
        },
        get file(){
            return fileInput.files[0];
        },
        getElement,
        getValue,
        reset,
        on: events.addEventListener
    }

}