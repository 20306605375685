export  function UptimeInfoWidget() {

    const uptime_wrapper = new DIV('content uptime-wrapper');

    function refreshUptime(uptime) {
        let string = "";
        let minutes = Math.floor(uptime / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        if (hours > 0) {
            minutes -= hours * 60;
        }
        if (days > 0) {
            hours -= days * 24;
        }


        if (days == 1) {
            string += days + " " + andy.Language.get('day') + " | ";
        } else if (days > 1) {
            string += days + " " + andy.Language.get('days') + " | ";
        } else {
            string += "0 " + andy.Language.get('days') + " | ";
        }

        if (hours == 1) {
            string += hours + " " + andy.Language.get('hour') + " | ";
        } else if (hours > 1) {
            string += hours + " " + andy.Language.get('hours') + " | "
        } else {
            string += "0 " + andy.Language.get('hours') + " | "
        }

        if (minutes == 1) {
            string += minutes + " " + andy.Language.get('minute') + "";
        } else if (minutes > 1) {
            string += minutes + " " + andy.Language.get('minutes');
        } else {
            string += "0 " + andy.Language.get('minutes');
        }


        return string;
    }
    this.update = function (data) {
        uptime_wrapper.innerHTML = refreshUptime(data);
    }
    
    this.delete = function () {
        uptime_wrapper.innerHTML = '';
    }

    this.getElement = function () {
        return uptime_wrapper;
    }

}