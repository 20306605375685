export function createDeviceGroupModal() {

    const events = new andy.EventManager();

    let createGroupForm;
    let createGroupModal;

    function constructor() {
        createGroupForm = new andy.UI.Form('new_group', {
            text: { label: 'name', item: new andy.UI.Input({ validators: andy.Validators.notEmpty }) }
        }, {
            save: new andy.UI.Button({ label: 'save', icon: 'mdi-content-save-outline' }, saveHandler)
        });

        createGroupModal = new andy.UI.Modal(createGroupForm);

        createGroupModal.on('dismiss', cancelHandler);
    }

    function saveHandler() {
        if (createGroupForm.validate()) {
            events.dispatchEvent('save', createGroupForm.getValue().text);
        }
    }

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function show() {
        createGroupModal.show();
    }

    function hide() {
        createGroupModal.hide();
    }

    constructor();

    return {
        show,
        hide,
        on: events.addEventListener
    }
}


        // currentlySelectedDevice.group = createGroupForm.getValue().text;
        // devicesDataset.save(currentlySelectedDevice, el => {
        //     createNewGroupModal.hide();
        //     changeGroupModal.hide();
        // });




