export function MemoryInfoWidget() {

    let total = 0;

    const ram_usage_wrapper = new DIV('content ram-wrapper');

    this.update = function (data) {
        const mem = total - data;
        if(mem < 0){
            ram_usage_wrapper.innerHTML = ""; 
        }else{
            ram_usage_wrapper.innerHTML = andy.Utils.formatBytes(mem, 0) + ' | ' + andy.Utils.formatBytes(total, 0);
        }
    }

    this.setTotalMem = function (totalMemory) {
        total = totalMemory;
    }

    this.delete = function () {
        ram_usage_wrapper.innerHTML = '';
    }

    this.getElement = function () {
        return ram_usage_wrapper;
    }

}