export function GalleryTabs() {
    let galleryValues = {};
    let galleryContent = new DIV('content');
    let currentGallery = 0;
    let galleryTabs = new andy.UI.Tabs(null, {
        multiview: false,
        view: galleryContent,
        close: true
    });

    let galleryForm = new andy.UI.Form('gallery_item', {
        media: {
            label: 'media',
            item: new andy.UI.GFMSelect({ triggers: true })
        },
        tags: {
            label: 'Tags',
            item: new andy.UI.Tags()
        },
        language: {
            label: 'language',
            item: new andy.UI.LanguageObject({
                title: {
                    label: 'title',
                    item: new andy.UI.Input({})
                },
                shortDescription: {
                    label: 'short_description',
                    item: new andy.UI.Input({})
                },
                longDescription: {
                    label: 'long_description',
                    item: new andy.UI.Input({})
                },
                extraDescription: {
                    label: 'extra_description',
                    item: new andy.UI.Input({})
                }
            })
        },
    });
    galleryForm.getElement().querySelector('.header').style.display = 'none';
    galleryForm.getElement().querySelector('.footer').style.display = 'none';
    galleryForm.items.language.item.getElement().querySelector('.header').style.display = 'none';
    galleryForm.items.language.item.getElement().querySelector('.footer').style.display = 'none';

    function removeTab(cG) {
        if (galleryValues[cG]) {
            delete galleryValues[cG];
        }

        if (galleryTabs.tabs[cG]) {
            galleryTabs.tabs[cG].remove();
        }

        currentGallery = -1;
    }

    function openTab(cG) {
        if (currentGallery >= 0) {
            galleryValues[currentGallery] = galleryForm.getValue();
        }

        currentGallery = cG;

        if (galleryValues[cG]) {
            galleryForm.setValue(galleryValues[cG]);
        }
        else {
            galleryForm.setValue({
                media: null,
                tags: "",
                language: {}
            })
        }
    }

    function addButtonCb(cG) {
        if (galleryValues[cG]) {
            return false;
        }

        galleryContent.append(galleryForm.getElement());
        galleryForm.getElement().style.display = 'initial';

        galleryTabs.add(cG, cG, null, function () {
            openTab(cG);
        }, null, () => {
            removeTab(cG);
        });

        openTab(cG);
    }

    galleryTabs.addButton('+', (args) => {
        let i = !Object.keys(galleryTabs.tabs)[0] ? 0 : Math.max.apply(null, Object.keys(galleryTabs.tabs)) + 1;
        addButtonCb(i);
    }, {});

    galleryTabs.getValue = function () {
        if (galleryValues[currentGallery]) {
            galleryValues[currentGallery] = galleryForm.getValue();
        }
        return galleryValues;
    }

    galleryTabs.setValue = function (values) {
        galleryForm.getElement().style.display = 'none';
        galleryForm.setValue({
            media: null,
            tags: "",
            language: {}
        });
        galleryTabs.clear();
        for (let key in galleryValues) {
            delete galleryValues[key];
        }
        galleryValues = {};
        currentGallery = -1;
        if (values) {
            for (let key in values) {
                currentGallery = parseInt(key);
                addButtonCb(parseInt(key));
                setTimeout((keyValue) => {
                    galleryValues[keyValue] = values[keyValue];
                    galleryForm.setValue(galleryValues[keyValue]);
                }, 1, key);
            }
        }
    }

    return galleryTabs;
}