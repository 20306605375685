export function AuthService({ socket }) {

    let events = new andy.EventManager();

    function constructor() {
        socket.on('auth', (data) => {
            switch (data.action) {
                case 'granted':
                    events.dispatchEvent('login.success', { user: data.value, token: data.token });
                    break;

                case 'denied':
                    events.dispatchEvent('login.failed', data.value);
                    break;

                case 'recover.send':
                    events.dispatchEvent('recover.send', data.value);
                    break;

                case 'rc.success':
                    events.dispatchEvent('recover.success', data.value);
                    break;
            }

        });
    }

    function validateToken(token, cb) {
        socket.emit('auth', {
            action: 'token',
            value: token
        }, (data) => {
            if (cb) cb(data);
        });
    }

    function recoverPasswordRequest(value, cb) {
        socket.emit("auth", {
            action: "recover",
            value: value
        }, () => {
            if (cb) cb();
        })
    }

    function logout(token, cb) {
        socket.emit('auth', {
            action: 'logout',
            value: token
        }, () => {
            if (cb) cb();
        });
    }

    function login(username, password, cb) {
        socket.emit('auth', {
            action: 'login',
            value: {
                username,
                password
            }
        }, () => {
            if (cb) cb();
        });
    }

    constructor();
    
    return {
        validateToken,
        recoverPasswordRequest,
        logout,
        login,
        on: events.addEventListener
    }
}