
export function AdminContentView() {

    const events = new andy.EventManager();

    let contentForm;

    function constructor() {
        contentForm = new andy.UI.Form('content', {
           
        }, {
            save: new andy.UI.Button({ icon: 'mdi-content-save', label: 'save' }, saveHandler)
        });

    }

    function saveHandler() {
        events.dispatchEvent('save', {});
    }

    constructor();

    return {
        get element() {
            return contentForm.element;
        },
        on: events.addEventListener,
    }
}