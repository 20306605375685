export function DirectDeviceService({ socket }) {

    const events = new andy.EventManager();

    function constructor() {
        socket.on('direct-platform', (data) => {
            switch (data.action) {
                case 'details':
                    events.dispatchEvent('device.details', data.value);
                    break;
                case 'cpu-usage':
                    events.dispatchEvent('cpu.usage', data.value);
                    break;
                case 'free-memory':
                    events.dispatchEvent('free.memory', data.value);
                    break;
                case 'screenshot':
                    events.dispatchEvent('screenshot', data.value);
                    break;
                case 'uptime':
                    events.dispatchEvent('uptime', data.value);
                    break;
                case 'terminal.info':
                    events.dispatchEvent('terminal.info', data.value);
                    break;
                case 'terminal.data':
                    events.dispatchEvent('terminal.data', data.value);
                    break;
            }
        });
    }

    function subscribe(deviceId) {
        socket.emit('devices', {
            action: 'subscribe',
            value: deviceId,
            token: andy.Auth.token
        });
    }

    function unsubscribe(deviceId) {
        socket.emit('devices', {
            action: 'unsubscribe',
            value: deviceId,
            token: andy.Auth.token
        });
    }

    function reqTerminalClose(deviceId, terminalId) {
        socket.emit('direct-device', {
            action: 'terminal.close',
            to: deviceId,
            value: {
                id: terminalId,
            },
            token: andy.Auth.token
        });
    }

    function reqTerminalWrite(deviceId, terminalId, data) {
        socket.emit('direct-device', {
            action: 'terminal.write',
            to: deviceId,
            value: {
                id: terminalId,
                cmd: data
            },
            token: andy.Auth.token
        });
    }

    function reqTerminalOpen(deviceId) {
        socket.emit('direct-device', {
            action: 'terminal.new',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }

    function reqTerminalInfo(deviceId) {
        socket.emit('direct-device', {
            action: 'terminal.info',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }

    function reqDeviceDetails(deviceId) {
        socket.emit('direct-device', {
            action: 'pc.details',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }

    function reqAppUpdate(deviceId) {
        socket.emit('direct-device', {
            action: 'app.update',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }

    function reqAppRefresh(deviceId) {
        socket.emit('direct-device', {
            action: 'app.refresh',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }

    function reqAppPause(deviceId) {
        socket.emit('direct-device', {
            action: 'app.pause',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }

    function reqAppResume(deviceId) {
        socket.emit('direct-device', {
            action: 'app.resume',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }


    function reqLLPRestart(deviceId) {
        socket.emit('direct-device', {
            action: 'llp.restart',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }

    function reqContentUpdate(deviceId) {
        socket.emit('direct-device', {
            action: 'content.update',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }

    function reqReboot(deviceId) {
        socket.emit('direct-device', {
            action: 'pc.reboot',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }

    function reqShutdown(deviceId) {
        socket.emit('direct-device', {
            action: 'pc.shutdown',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }

    function reqScreenshot(deviceId) {
        socket.emit('direct-device', {
            action: 'screenshot.get',
            to: deviceId,
            value: '',
            token: andy.Auth.token
        });
    }

    constructor();

    return {
        subscribe,
        unsubscribe,
        reqTerminalClose,
        reqTerminalInfo,
        reqTerminalOpen,
        reqTerminalWrite,
        reqContentUpdate,
        reqAppRefresh,
        reqAppUpdate,
        reqAppPause,
        reqAppResume,
        reqLLPRestart,
        reqShutdown,
        reqReboot,
        reqDeviceDetails,
        reqScreenshot,
        on: events.addEventListener
    }
}