
export function ContentLanguagesView() {

    const events = new andy.EventManager();
    const assetPath = window.location;

    let contentForm, languagesList, selectedLanguagesList, defaultLanguagesSelect, languageSelectorModal, langIndex;

    function constructor() {
        languagesList = new List(undefined, 'multi', 'tiles');

        languagesList.setSearch(undefined, andy.Language.dictionary['search']);

        let searchElement = {
            getElement: function () {
                return languagesList.getSearchElement();
            }
        }

        langIndex = 0;
        selectedLanguagesList = new andy.UI.List(null, function (item) {
            item.num = langIndex;
            
            let iconEl = {
                element: new IMG('svg-icon',  `${assetPath}/assets/img/flags/1x1/${item.icon.replace('.SVG', '.svg')}`),
                getElement: function() {
                    return this.element;
                },
            }
            iconEl.getElement().style.cssText = 'max-width: 32px; margin-right: 16px; border-radius: 60px;';

            let element = new andy.UI.Group({
                icon: iconEl,
                label: new andy.UI.Label(`${item.name} (${item.code})`),
                delete: new andy.UI.Button({
                    icon: 'mdi-close',
                    label: ''
                }, () => {
                    events.dispatchEvent('languages.remove', item);       
                })
            });

            langIndex++;
            return element.getElement();
        }, {
            removable: false,
            draggable: false
        });

        let languagesSelectorForm = new andy.UI.Form('assign_languages', {
            list: {
                label: '',
                item: languagesList
            }
        }, {
            add: new andy.UI.Button({
                icon: 'mdi-plus',
                label: 'add'
            }, function () {
                events.dispatchEvent('languages.add', languagesList.getSelected());
            })
        }, {
            search: searchElement
        });

        languageSelectorModal = new andy.UI.Modal(languagesSelectorForm);
        languageSelectorModal.getElement().style.minWidth = "60%";
        languageSelectorModal.getElement().style.maxWidth = "60%";

        defaultLanguagesSelect = new andy.UI.Select([], (value, add) => {
            add(value.code, `${value.name} (${value.code})`);
        });

        contentForm = new andy.UI.Form('content_languages', {
            languages: {
                label: '',
                item: new andy.UI.Form('languages', {
                    contentLanguages: {
                        label: '',
                        item: selectedLanguagesList
                    }
                }, {
                        add: new andy.UI.Button({
                            icon: 'mdi-plus',
                            label: 'add'
                        }, () => {
                            events.dispatchEvent('languages.show.list');
                        })
                    })
            },
            defaultLanguage: {
                label: 'Default',
                item: defaultLanguagesSelect
            }
        }, {
            save: new andy.UI.Button({ icon: 'mdi-content-save', label: 'save' }, saveHandler)
        });

        events.dispatchEvent('render.languages.selector');
    }

    function saveHandler() {
        let values = {
            defaultCode: defaultLanguagesSelect.getValue(),
            langAvailable: [],
            _id: andy.Content.storedId ? andy.Content.storedId : null
        }

        selectedLanguagesList.getValue().map((v) => {
            values.langAvailable.push(v.code);
        });

        events.dispatchEvent('languages.save', { values });
    }

    constructor();

    return {
        get element() {
            return contentForm.element;
        },
        on: events.addEventListener,
        languagesList, selectedLanguagesList, defaultLanguagesSelect, languageSelectorModal, langIndex
    }
}