export function GradientPicker (options) {

    // CSS
    let style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = '.andy-ui-gradient-visualizer { width: 20px; height: 20px; box-sizing: border-box; border-radius: 28px; overflow: hidden; margin-top: 12px; }';
    document.getElementsByTagName('head')[0].appendChild(style);

    // General
    let visualizerEl = new DIV('andy-ui-gradient-visualizer');
    let visualizerValue = null;
    let defaultOptions = {
        angle: options && options.angle ? options.angle + 'deg' : 207,
        percentage: options && options.percentage ? options.percentage + '%' : '17%'
    }

    const visualizer = {
        getElement: () => {
            return visualizerEl
        },
        setValue: (colorObj) => {
            if (typeof colorObj === 'object') {
                visualizerValue = `linear-gradient(${colorObj.angle ? colorObj.angle + 'deg' : defaultOptions.angle}, ${colorObj.primaryColor}, ${colorObj.secondaryColor} ${colorObj.percentage ? colorObj.percentage + '%' : defaultOptions.percentage})`;
            }
            else {
                visualizerValue = colorObj;
            }

            visualizerEl.style.background = visualizerValue;
        },
        getValue: () => {
            return visualizerValue;
        },
        reset: () => {
            visualizerValue = 'linear-gradient(0deg, rgb(255,255,255), rgb(255,255,255) 0%)';
            visualizerEl.style.background = visualizerValue;
        }
    } 
   
    let gradientForm = new andy.UI.Form('gradient_picker', {
        primaryColor: {
            label: 'primary_color',
            item: new andy.UI.ColorPicker()
        },
        secondaryColor: {
            label: 'secondary_color',
            item: new andy.UI.ColorPicker()
        },
        angle: {
            label: 'angle',
            item: new andy.UI.Input({ type: 'number' })
        },
        percentage: {
            label: 'percentage',
            item: new andy.UI.Input({ type: 'number' })
        },
        apply: {
            item: new andy.UI.Button({
                label: 'apply',
                icon: 'done'
            }, () => {
                visualizer.setValue({
                    angle: gradientForm.getValue().angle,
                    primaryColor:  gradientForm.getValue().primaryColor,
                    secondaryColor:  gradientForm.getValue().secondaryColor,
                    percentage:  gradientForm.getValue().percentage,
                });
            })
        },
        color: {
            label: 'gradient_result',
            item: visualizer
        }
    }, {});

    if (gradientForm && gradientForm.getElement()) {
        gradientForm.getElement().querySelector('.footer').style.display = 'none';
    }

    function getValue() {
        return visualizer.getValue();
    }

    function getElement() {
        return gradientForm.getElement();
    }

    function setValue(val) {
        
        if (!val) {
            return;
        }

        let parsedValue = val.replace('linear-gradient(', '').split(" ");
        let valueToSet = {
            primaryColor: parsedValue[1].replace(/,(?=[^,]*$)/, ''),
            secondaryColor: parsedValue[2],
            angle: parseInt(parsedValue[0].replace(/,/, '')),
            percentage: parseInt(parsedValue[3].replace('%)', '')),
            color: val
        }

        gradientForm.setValue(valueToSet);
    }

    function reset() {
        gradientForm.setValue({
            primaryColor: 'rgb(0,0,0)',
            secondaryColor: 'rgb(0,0,0)',
            angle: 0,
            percentage: 0,
            color: 'linear-gradient(0deg, rgb(255,255,255), rgb(255,255,255) 0%)'
        });
        visualizer.reset();
    }

    let _public = {
        getElement,
        getValue,
        setValue,
        reset
    }

    andy.UI._instances.push(_public);
    return _public;
}