export function VideoTabs() {
    let videoValues = {};
    let videoContent = new DIV('content');
    let currentVideo = 0;
    let videoTabs = new andy.UI.Tabs(null, {
        multiview: false,
        view: videoContent,
        close: true
    });

    let videoForm = new andy.UI.Form('video_item', {
        media: {
            label: 'media',
            item: new andy.UI.GFMSelect({ triggers: true })
        },
        tags: {
            label: 'Tags',
            item: new andy.UI.Tags()
        },
        language: {
            label: 'language',
            item: new andy.UI.LanguageObject({
                title: {
                    label: 'title',
                    item: new andy.UI.Input({})
                },
                shortDescription: {
                    label: 'short_description',
                    item: new andy.UI.Input({})
                },
                longDescription: {
                    label: 'long_description',
                    item: new andy.UI.Input({})
                },
                extraDescription: {
                    label: 'extra_description',
                    item: new andy.UI.Input({})
                }
            })
        },
    });
    videoForm.getElement().querySelector('.header').style.display = 'none';
    videoForm.getElement().querySelector('.footer').style.display = 'none';
    videoForm.items.language.item.getElement().querySelector('.header').style.display = 'none';
    videoForm.items.language.item.getElement().querySelector('.footer').style.display = 'none';

    function removeTab(cV) {
        if (videoValues[cV]) {
            delete videoValues[cV];
        }

        if (videoTabs.tabs[cV]) {
            videoTabs.tabs[cV].remove();
        }

        currentVideo = -1;
    }

    function openTab(cV){
        if (currentVideo >= 0) {
            videoValues[currentVideo] = videoForm.getValue();
        }

        currentVideo = cV;

        if (videoValues[cV]) {
            videoForm.setValue(videoValues[cV]);
        }
        else {
            videoForm.setValue({
                media: null,
                tags: "",
                language: {}
            })
        }
    }

    function addButtonCb(cV) {
        if (videoValues[cV]) {
            return false;
        }

        videoContent.append(videoForm.getElement());
        videoForm.getElement().style.display = 'initial';

        videoTabs.add(cV, cV, null, function() {
            openTab(cV);
        }, null, () => {
            removeTab(cV);
        });
       
        openTab(cV);
    }
    
    videoTabs.addButton('+', (args) => {
        let i = !Object.keys(videoTabs.tabs)[0] ? 0 : Math.max.apply(null, Object.keys(videoTabs.tabs)) + 1;
        addButtonCb(i);
    }, {});

    videoTabs.getValue = function (){
        if (videoValues[currentVideo]) {
            videoValues[currentVideo] = videoForm.getValue();
        }
        return videoValues;
    }

    videoTabs.setValue = function(values) {
        videoForm.getElement().style.display = 'none';
        videoForm.setValue({
            media: null,
            tags: "",
            language: {},
        });
        videoTabs.clear();
        for (let key in videoValues) {
            delete videoValues[key];
        } 
        videoValues = {};
        currentVideo = -1;
        
        for (let key in values) {
            currentVideo = parseInt(key);
            addButtonCb(parseInt(key));
            setTimeout((keyValue) => {
                videoValues[keyValue] = values[keyValue];
                videoForm.setValue(videoValues[keyValue]);
            }, 1, key);
        }
    }

    return videoTabs;
}