export function EventSelectorModal() {

    const events = new andy.EventManager();

    let eventSelect, eventSelectorForm, eventSelectorModal

    function constructor() {

        eventSelect = new andy.UI.Select(['onIdle']);

        eventSelectorForm = new andy.UI.Form('add_event', {
            event: { label: 'event', item: eventSelect }
        }, {
            save: new andy.UI.Button({ icon: 'mdi-check', label: 'add' }, saveHandler)
        });

        eventSelectorModal = new andy.UI.Modal(eventSelectorForm);
        eventSelectorModal.on('dismiss', cancelHandler);
    }

    function saveHandler(){

        events.dispatchEvent('add', eventSelectorForm.getValue().event);
   
            // let values = eventSelectorForm.getValue();
            // createEventNode(values.event, values.event);
            // eventSelectorModal.hide();
        
    }

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function reset() {
        eventSelectorForm.reset();
    }

    function show() {
        reset();
        eventSelectorModal.show();
    }

    function hide() {
        eventSelectorModal.hide();
    }

    constructor();


    return {
        show, 
        hide, 
        on: events.addEventListener
    }

}




