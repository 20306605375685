export function DatabaseService ({socket}) {

    const events = new andy.EventManager();
    const dataset = new andy.Dataset('andy.db'); 
    
    socket.on('databases', (data) => {
        switch (data.action) {
            case 'connected':
                events.dispatchEvent('databases.connected');
                break;  
        }
    });

    events.addEventListener('fetch.collections.list', () => {
        socket.emit('databases', {
            action: 'get',
            value: '',
            token: andy.Auth.token
        });
    });

    events.addEventListener('fetch.collection', (collection) => {
        socket.emit('collections', {
            action: 'create',
            value: {
                channel: collection.code
            },
            token: andy.Auth.token
        });

        andy._datasets[collection.code] = new andy.Dataset(collection.code);
    })

    return  {
        dataset,
        emit: events.dispatchEvent,
        on: events.addEventListener
    }
}
