export  function NetworkInfoWidget() {

    let network_wrapper = new DIV('content network-wrapper');
    // var networkDeviceLabel = new andy.UI.Label('network', true);

    this.set = function (networkDevices) {
        network_wrapper.innerHTML = "";
        Object.keys(networkDevices).map((key) => {
            let netD = networkDevices[key].filter(nD => nD.family == "IPv4");
            let label = new andy.UI.Label('network', true);
            if (netD[0]) {
                label.setValue(key + ' : ' + netD[0].address);
            }
            network_wrapper.appendChild(label.getElement());
        })
    }

    this.delete = function () {
        network_wrapper.innerHTML = '';
    }

    this.getElement = function () {
        return network_wrapper;
    }

}