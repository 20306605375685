export function UploadDropzone() {

    const events = new andy.EventManager();
    let dropZoneElement = new DIV('upload-dropzone-overlay');
    let isEnter = false;

    function constructor() {

        document.body.append(dropZoneElement);

        dropZoneElement.append(new andy.UI.Icon('mdi-cloud-upload').element, new andy.UI.Label('drop_files_to_upload').element);

        document.body.addEventListener('dragenter', (e) => {
            if (e.dataTransfer.items.length == 0) {
                return;
            } else {
                if(e.dataTransfer.items[0].kind !== 'file'){
                    return;
                }
                e.stopPropagation();
                e.preventDefault();
                if (!isEnter) {
                    isEnter = true;
                    dropZoneElement.classList.add('show');
                }
            }
        }, true);

        dropZoneElement.addEventListener('dragleave', () => {
            if (isEnter) {
                isEnter = false;
                dropZoneElement.classList.remove('show');
            }
        });

        dropZoneElement.addEventListener('dragover', (e) => {
            e.preventDefault();
        });

        dropZoneElement.addEventListener('drop', (e) => {
            if (isEnter) {
                e.preventDefault();
                dropZoneElement.classList.remove('show');
                isEnter = false;
                events.dispatchEvent('drop');
            }
        });

    }

    constructor();

    return {
        get element() {
            return dropZoneElement;
        },
        on: events.addEventListener
    }
}