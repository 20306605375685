
export function DeviceProfileView() {

    const events = new andy.EventManager();

    let devicePropertiesForm, deviceLocationForm;

    function constructor() {

        const saveButtonProps = { icon: 'mdi-content-save-outline', label: 'save' };

        devicePropertiesForm = new andy.UI.Form('device_properties', {
            name: { label: 'name', item: new andy.UI.Input({ validators: andy.Validators.notEmpty }) },
            description: { label: 'description', item: new andy.UI.Input() }
        }, {
            save: new andy.UI.Button(saveButtonProps, saveHandler)
        });

        deviceLocationForm = new andy.UI.Form('location', {
            country: { label: 'country', item: new andy.UI.Input() },
            city: { label: 'city', item: new andy.UI.Input() },
            zipcode: { label: 'zipcode', item: new andy.UI.Input() },
            lat: { label: 'latitude', item: new andy.UI.Input() },
            lng: { label: 'longitude', item: new andy.UI.Input() },
        }, {
            save: new andy.UI.Button(saveButtonProps, saveHandler)
        });

    }

    function saveHandler() {
        if (devicePropertiesForm.validate()) {
            const values = {
                location: deviceLocationForm.getValue(), 
                ...devicePropertiesForm.getValue()
            }
            events.dispatchEvent('save', values);
        }
    }

    function setValue({ location, name, description }) {
        if (name || description) devicePropertiesForm.setValue({ name, description });
        if (location) deviceLocationForm.setValue(location);
    }

    function reset(){
        devicePropertiesForm.reset(); 
        deviceLocationForm.reset(); 
    }

    constructor();

    return {
        get element() {
            return [devicePropertiesForm.element, deviceLocationForm.element];
        },
        setValue,
        reset, 
        on: events.addEventListener
    }
}