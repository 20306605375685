import { ContentLanguagesView } from './views/content.languages.view';

export function ContentLanguagesController(contentController) {

    const contentLanguagesView = new ContentLanguagesView();
    const languageService = andy.backend.services.languages;

    andy.Content = {
        flags: require('./resources/flags'),
        locales: require('./resources/locales'),
        availableLang: [],
        default: null,
        languages: {
            get: (cb) => {
                languageService.on('device.languages.list', (data) => {
                    if (cb) {
                        cb(data)
                    }
                });

                languageService.emit('device.languages.get');
            }
        }
    }

    const contentContainer = contentController.container;

    function constructor() {
        contentContainer.append(contentLanguagesView.element);

        languageService.on('device.languages.list', listLanguagesHandler);
        languageService.on('device.languages.update', updateLanguageHandler);
        
        contentLanguagesView.on('languages.save', saveAdminHandler);
        contentLanguagesView.on('languages.add', addLangHandler);
        contentLanguagesView.on('languages.show.list', showListHandler);
        contentLanguagesView.on('languages.remove', removeLangHandler);
        contentLanguagesView.on('render.languages.selector', renderLanguagesHandler);

        languageService.emit('device.languages.get');
    }

    function addLangHandler(selected){
        contentLanguagesView.languageSelectorModal.hide();
        
        selected.map((v) => {
            if (checkLang(v)) {
                contentLanguagesView.selectedLanguagesList.add(v, false);
                contentLanguagesView.defaultLanguagesSelect.add(v);
            }
        });

        contentLanguagesView.languagesList.clearSelected();
    }

    function removeLangHandler(item){
        contentLanguagesView.selectedLanguagesList.remove(item.num);
        contentLanguagesView.defaultLanguagesSelect.remove(item.code);  
    }

    function showListHandler(){
        contentLanguagesView.languageSelectorModal.show();
    }

    function saveAdminHandler(data) {
        languageService.emit('device.languages.save', data);
    }

    function listLanguagesHandler(data){
        if (data.value && data.value[0]) {
            contentLanguagesView.selectedLanguagesList.setValue(formatLanguage(data.value[0].langAvailable));
            
            andy.Content.availableLang = [];

            data.value[0].langAvailable.map((v) => {
                andy.Content.availableLang.push(v);
                contentLanguagesView.defaultLanguagesSelect.add(formatLanguage(v));
            });

            andy.Content.default = data.value[0].defaultCode;

            contentLanguagesView.defaultLanguagesSelect.setValue(data.value[0].defaultCode);

            andy.Content.storedId = (data.value[0] && data.value[0]._id) ? data.value[0]._id : null;
        }

        renderLanguagesHandler();
    }

    function updateLanguageHandler(data) {
        andy.Content.availableLang = [];
        data.value.langAvailable.map((v) => {
            andy.Content.availableLang.push(formatLanguage(v));
        });
        andy.Content.default = data.value.defaultCode;
    }

    function renderLanguagesHandler(){
        if (andy.Content.flags) {
            contentLanguagesView.languagesList.clear();
            Object.keys(andy.Content.flags).forEach((lang) => {
                renderLanguagesItem(andy.Content.flags[lang], lang);
            });
        }
    }

    function checkLang(data) {
        let aux = true;

        if (data.name) {
            contentLanguagesView.selectedLanguagesList.getValue().map((v) => {
                if (v.code && v.code === data.code) {
                    aux = false;
                }
            });
        }
        return aux;
    }

    function formatLanguage(val){
        if (Array.isArray(val)) {
            return val.map((l) => {
                return {
                    code: l,
                    name: andy.Content.locales[l],
                    locale: andy.Content.locales[l],
                    icon: andy.Content.flags[l]
                }
            });
        }

        return {
            code: val,
            name: andy.Content.locales[val],
            locale: andy.Content.locales[val],
            icon: andy.Content.flags[val]
        }
    }

    function renderLanguagesItem(lang, key) {
        let newLang = {
            code: key,
            name: andy.Content.locales[key],
            locale: andy.Content.locales[key],
            icon: lang
        }

        return contentLanguagesView.languagesList.add(new ListButton(`${newLang.name} (${newLang.code})`, newLang.icon ? newLang.icon : 'dashboard', function () { }), newLang.name, newLang);
    }

    constructor();

    return {

    };
}