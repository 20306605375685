export function UserBarWidget({name, imageUrl}) {

    const events = new andy.EventManager(); 

    let widgetGroup; 
    let userDropdown;

    function constructor(){

        const userProfileDropdown = {
            profile: new andy.UI.Button({ label: 'profile', icon: 'mdi-face' }, () => events.dispatchEvent('profile')),
            logout: new andy.UI.Button({ label: 'logout', icon: 'mdi-logout' }, () => events.dispatchEvent('logout'))
        };

        userDropdown = new andy.UI.DropDown({label: name, items: userProfileDropdown })

        widgetGroup = new andy.UI.Group({
            avatar: new andy.UI.Avatar({name , imageUrl}),
            user: userDropdown
        }, { style: { flex: 'none' }, customClass: 'app-user-navbar-widget' });
    }

    function getElement(){
        return widgetGroup.getElement(); 
    }

    function setValue({name, imageUrl}){
        widgetGroup.setValue({
            avatar: {name , imageUrl},
        });
        userDropdown.setLabel(name); 
    }

    constructor(); 

    return {
        get element(){
            return  widgetGroup.getElement(); 
        },
        getElement,
        setValue,
        on: events.addEventListener

    }
}