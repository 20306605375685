
export function FileDeleteModal() {

    const events = new andy.EventManager();

    let confirmDeleteForm, deleteProjectModal;
    let file = null;

    function constructor() {
        confirmDeleteForm = new andy.UI.Form('confirm_delete', {
            msg: { item: new andy.UI.Label('confirm_delete_message') },
            file: { item: new andy.UI.Label('file') }
        },
            {
                yes: new andy.UI.Button({ label: 'yes', icon: 'delete' }, yesHandler),
                no: new andy.UI.Button({ label: 'no', icon: 'cancel' }, cancelHandler),
            });

        deleteProjectModal = new andy.UI.Modal(confirmDeleteForm);
        deleteProjectModal.on('dismiss', cancelHandler);
    }

    function yesHandler() {
        events.dispatchEvent('yes', file);
        file = null;
    };

    function cancelHandler() {
        file = null;
        events.dispatchEvent('cancel');
    };

    function show(f) {
        if(f){
            file = f; 
        }

        if (file) {
            confirmDeleteForm.setValue({
                file: file.originalName
            })
        }
        deleteProjectModal.show();
    }

    function hide() {
        deleteProjectModal.hide();
    }

    constructor();

    return {
        show,
        hide,
        on: events.addEventListener
    }
}