import { AdminContentView } from './views/content.view';
import { AdminAPISView } from './views/apis.view';


export function ContentController() {

    const contentContainer = andy.mainContainer.newView({ code: 'admin-content', route: '/admin/content' });

    const adminContentView = new AdminContentView();
    const adminAPISView = new AdminAPISView();

    function constructor() {
        addToMainMenu();
        contentContainer.append(adminContentView.element, adminAPISView.element);
        adminContentView.on('save', saveAdminHandler);
        adminAPISView.on('save', saveAdminHandler);
    }

    function addToMainMenu() {
        const contentMenuItem = new andy.UI.MainMenuItem({ code: 'admin.content', label: 'content', icon: 'mdi-harddisk', order: 2 });
        andy.mainMenu.props.children['root.admin.section'].append(contentMenuItem);
        contentMenuItem.connect(contentContainer, 'show', 'select');
    }

    function saveAdminHandler(data) {

    }

    constructor();

    return {
        container: contentContainer
    };
}