
export function AdminAPISView() {

    const events = new andy.EventManager();

    let contentAPIForm;

    function constructor() {

        contentAPIForm = new andy.UI.Form('content_apis', {
            googleKey: { label: 'google_key', item: new andy.UI.Input() }
        }, {
            save: new andy.UI.Button({ icon: 'mdi-content-save', label: 'save' }, saveHandler)
        });

    }

    function saveHandler() {
        events.dispatchEvent('save', {});
    }

    constructor();

    return {
        get element() {
            return contentAPIForm.element;
        },
        on: events.addEventListener,
    }
}