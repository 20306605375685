export function AudioTabs() {
    let audioValues = {};
    let audioContent = new DIV('content');
    let currentAudio = 0;
    let audioTabs = new andy.UI.Tabs(null, {
        multiview: false,
        view: audioContent,
        close: true
    });

    let audioForm = new andy.UI.Form('audio_item', {
        media: {
            label: 'media',
            item: new andy.UI.GFMSelect({})
        },
        tags: {
            label: 'Tags',
            item: new andy.UI.Tags()
        },
        language: {
            label: 'language',
            item: new andy.UI.LanguageObject({
                title: {
                    label: 'title',
                    item: new andy.UI.Input({})
                },
                shortDescription: {
                    label: 'short_description',
                    item: new andy.UI.Input({})
                },
                longDescription: {
                    label: 'long_description',
                    item: new andy.UI.Input({})
                },
                extraDescription: {
                    label: 'extra_description',
                    item: new andy.UI.Input({})
                }
            })
        },
    });
    audioForm.getElement().querySelector('.header').style.display = 'none';
    audioForm.getElement().querySelector('.footer').style.display = 'none';
    audioForm.items.language.item.getElement().querySelector('.header').style.display = 'none';
    audioForm.items.language.item.getElement().querySelector('.footer').style.display = 'none';

    function removeTab(cA) {
        if (audioValues[cA]) {
            delete audioValues[cA];
        }

        if (audioTabs.tabs[cA]) {
            audioTabs.tabs[cA].remove();
        }

        currentAudio = -1;
    }

    function openTab(cA){
        if (currentAudio >= 0) {
            audioValues[currentAudio] = audioForm.getValue();
        }

        currentAudio = cA;

        if (audioValues[cA]) {
            audioForm.setValue(audioValues[cA]);
        }
        else {
            audioForm.setValue({
                media: null,
                tags: "",
                language: {}
            })
        }
    }

    function addButtonCb(cA) {
        if (audioValues[cA]) {
            return false;
        }

        audioContent.append(audioForm.getElement());
        audioForm.getElement().style.display = 'initial';

        audioTabs.add(cA, cA, null, function() {
            openTab(cA);
        }, null, () => {
            removeTab(cA);
        });
       
        openTab(cA);
    }
    
    audioTabs.addButton('+', (args) => {
        let i = !Object.keys(audioTabs.tabs)[0] ? 0 : Math.max.apply(null, Object.keys(audioTabs.tabs)) + 1;
        addButtonCb(i);
    }, {});

    audioTabs.getValue = function (){
        if (audioValues[currentAudio]) {
            audioValues[currentAudio] = audioForm.getValue();
        }
        return audioValues;
    }

    audioTabs.setValue = function(values) {
        audioForm.getElement().style.display = 'none';
        audioForm.setValue({
            media: null,
            tags: "",
            language: {}
        });
        audioTabs.clear();
        for (let key in audioValues) {
            delete audioValues[key];
        } 
        audioValues = {};
        currentAudio = -1;
        
        for (let key in values) {
            currentAudio = parseInt(key);
            addButtonCb(parseInt(key));
            setTimeout((keyValue) => {
                audioValues[keyValue] = values[keyValue];
                audioForm.setValue(audioValues[keyValue]);
            }, 1, key);
        }
    }

    return audioTabs;
}