export function FilesUploadModal() {

    const events = new andy.EventManager();

    let uploadsForm, uploadsModal, uploadsList;

    function constructor() {

        uploadsList = new andy.UI.UploadsList();

        uploadsForm = new andy.UI.Form('files_upload', {
            list: { item: uploadsList }
        },
            {
                choose: new andy.UI.Button({ label: 'choose_file', icon: 'mdi-upload' }, () => events.dispatchEvent('choose.file'))
            });

        uploadsModal = new andy.UI.Modal(uploadsForm);
        uploadsModal.on('dismiss', cancelHandler);

    }

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function show() {
        uploadsModal.show();
    }

    function hide() {
        uploadsModal.hide();
    }

    constructor();

    return {
        get uploadsList(){
            return uploadsList;
        },
        show,
        hide,
        on: events.addEventListener
    }
}