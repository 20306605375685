export function MasterServerService({ socket }) {

    const events = new andy.EventManager();


    function requestDeviceFromPool(code) {
        socket.emit('pool.request', {
            action: 'code',
            value: code,
            token: andy.Auth.token
        });
    }

    return {
        requestDeviceFromPool,
        on: events.addEventListener
    }
}
