export function FilesService({ socket }) {

    const events = new andy.EventManager();
    const dataset = new andy.Dataset('andy.gfupload');

    function preInsertFile(file, cb) {
        const doc = {
            enabled: false,
            insertDate: new Date(),
            originalName: file.name,
            name: divideExtension(file.name).name,
            filesize: file.size,
            type: file.type,
            extension: divideExtension(file.name).ext,
            tags: [divideExtension(file.name).ext, divideExtension(file.name).name, divideType(file.type).type].join(' '),
        }

        dataset.save(doc, cb);
    }

    function insertFile(file, detail, cb) {

        const doc = {
            _id: file.meta._id,
            enabled: true,
            filename: detail.filename,
            thumbnail: detail.thumbnail,
        }

        dataset.save(doc, cb);

    }

    function divideExtension(filename) {
        let lastDotPosition = filename.lastIndexOf(".");
        if (lastDotPosition === -1) {
            return {
                name: filename
            }
        } else {
            return {
                name: filename.substr(0, lastDotPosition),
                ext: filename.substr(lastDotPosition)
            }
        };
    }

    function divideType(name) {
        let lastDashPosition = name.lastIndexOf("/");
        if (lastDashPosition === -1) {
            return {
                name: name
            }
        } else {
            return {
                type: name.substr(0, lastDashPosition),
                ext: name.substr(lastDashPosition)
            }
        };
    }

    return {
        dataset,
        preInsertFile,
        insertFile,
        on: events.addEventListener
    }
}
