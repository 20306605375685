export function ProjectSettingsModal() {

    const events = new andy.EventManager();

    let projectSettingsForm, projectSettingsModal;

    function constructor() {

        projectSettingsForm = new andy.UI.Form('project_settings', {
            name: { label: 'name', item: new andy.UI.Input({ validators: andy.Validators.notEmpty }) },
            description: { label: 'description', item: new andy.UI.Input() }
        }, {
            create: new andy.UI.Button({ label: 'save', icon: 'mdi-check' }, saveHandler)
        });

        projectSettingsModal = new andy.UI.Modal(projectSettingsForm, true);
        projectSettingsModal.on('dismiss', cancelHandler);

    }

    function saveHandler() {
        if (projectSettingsForm.validate()) {
            events.dispatchEvent('save', projectSettingsForm.getValue());
        }
    };

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function reset() {
        projectSettingsForm.reset();
    }

    function show(project) {
        if(project){
            projectSettingsForm.setValue(project);
        }
        projectSettingsModal.show();
    }

    function hide() {
        projectSettingsModal.hide();
        reset(); 
    }

    constructor();

    return {
        show,
        hide,
        reset,
        on: events.addEventListener
    }

}