import { AccountsController } from './accounts';
import { ContentController } from './content';
import { ContentLanguagesController } from './content.languages';
import { SettingsController } from './settings';
import { RolesController } from './roles';

export function AdminController() {

    const accountsController = new AccountsController();
    const contentController = new ContentController();
    const contentLanguagesController = new ContentLanguagesController(contentController);
    const settingsController = new SettingsController();
    const rolesController = new RolesController();

    function constructor() {
  
    }

    constructor();

    return {};
}