export function LogicToolbars() {

    const events = new andy.EventManager();

    let rightGroup, leftGroup;

    function constructor() {
        rightGroup = new andy.UI.Group({
            events: new andy.UI.Button({ icon: 'mdi-flash' }, () => events.dispatchEvent('logic.events')),
        }, { style: { justifyContent: 'flex-end' } });

        leftGroup = new andy.UI.Group({
            // today: new andy.UI.Button({ icon: 'mdi-calendar-today' }, () => events.dispatchEvent('calendar.today')),
            // prev: new andy.UI.Button({ icon: 'mdi-calendar-arrow-left' }, () => events.dispatchEvent('calendar.previous')),
            // currentDate: new andy.UI.Label(''),
            // next: new andy.UI.Button({ icon: 'mdi-calendar-arrow-right' }, () => events.dispatchEvent('calendar.next'))
        }, { style: { justifyContent: 'flex-start' } });
    }

    constructor();

    return {
        get elements() {
            return [leftGroup.getElement(), rightGroup.getElement()]
        },
        on: events.addEventListener
    }

}