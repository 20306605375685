export function CollectionListModal () {

    let events = new andy.EventManager();

    function collectionTemplate(item) {
        let deleteButton = new andy.UI.Button({
            icon: 'mdi-delete',
            label: '',
        }, () => {
            events.dispatchEvent('collection.item.delete', item);
        });

        let editButton = new andy.UI.Button({
            icon: 'mdi-pencil',
            label: ''
        }, () => {
            events.dispatchEvent('collection.item.show', item);
        });

        deleteButton.getElement().style.marginLeft = '2px';
        deleteButton.getElement().style.marginRight = '2px';
        editButton.getElement().style.marginLeft = '2px';
        editButton.getElement().style.marginRight = '2px';

        return new andy.UI.Group({
            icon: new andy.UI.Icon('mdi-file-document'),
            label: new andy.UI.Label(`[${item._id}] ` + item.language['en_US']['name'] ),
            edit: editButton,
            delete: deleteButton
        }).getElement();
    }

    let collectionsList = new andy.UI.List(null, collectionTemplate, { removable: false, draggable: false });

    let collectionComponent = new andy.UI.Form('XYZ', {
        listCollection: {
            label: 'database',
            item: collectionsList
        }
    },{
        download: new andy.UI.Button({
            icon: 'mdi-cloud-download',
            label: 'download_template'
        }, () => {
            events.dispatchEvent('collection.template')
        }),
        import: new andy.UI.Button({
            icon: 'mdi-cloud-upload',
            label: 'import'
        }, () => {
            events.dispatchEvent('collection.import');
        }),
        add: new andy.UI.Button({
            icon: 'mdi-plus',
            label: 'add'
        }, () => {
            events.dispatchEvent('collection.create');
        })
    },{});

    let collectionsListModal = new andy.UI.Modal(collectionComponent);
    collectionsListModal.getElement().style.width = '80%';

    return {
        component: collectionComponent,
        modal: collectionsListModal,
        list: collectionsList,
        on: events.addEventListener
    };
}