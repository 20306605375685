export function CpuInfoWidget() {

    var scope = this;

    this.initiated = false;

    var cpu_usage_wrapper = new DIV('content cpu-wrapper');

    var streams = [];
    var charts = [];

    var cpus;

    var model = new andy.UI.Label('', true);
    var cores = new andy.UI.Label('', true);




    function createStreams() {


        for (var i = 0; i < cpus.length; i++) {
            streams.push(new TimeSeries());
        }

    }

    this.updateStreams = function(data) {
        if (data.length < 1) return;

        var date = new Date().getTime();
        for (var j = 0; j < data.length; j++) {
            streams[j].append(date, data[j].cpu);
        }
    }


    function createChart(stream) {
        var cpu_info_wrapper = new DIV('cpu-info');
        var canvas = document.createElement('canvas');
        canvas.className = 'cpu-info';
        canvas.width = 115;
        canvas.height = 45;

        var chart = new SmoothieChart({ millisPerPixel: 28, labels:{fillStyle:'#303030'}, grid: { fillStyle: '#e8e8e8', strokeStyle: 'transparent', verticalSections: 0, borderVisible: false }, maxValue: 100, minValue: 0 });
        chart.addTimeSeries(stream, { strokeStyle: '#4665ff', fillStyle: '#c4cff9', lineWidth: 2 });
        cpu_info_wrapper.appendChild(canvas)
        cpu_usage_wrapper.appendChild(cpu_info_wrapper);
        chart.streamTo(canvas, 1000);
    }


    function createCharts() {
        for (var i = 0; i < cpus.length; i++) {
            createChart(streams[i]);
        }
    }



    this.init = function(data) {
        cpu_usage_wrapper.innerHTML = '';
        streams = [];
        charts = [];
        cpus = data;
        model.setValue(cpus[0].model);
        cores.setValue(cpus.length + ' Cores');
        createStreams();
        createCharts();
        scope.initiated = true;
    }


    this.delete = function() {
        cpu_usage_wrapper.innerHTML = '';
        scope.initiated = false;
        model.setValue(''); 
        cores.setValue('');
    }


    this.getElement = function() {
        return cpu_usage_wrapper;
    }

    this.getCores = function() {
        return cores;
    }


    this.getModel = function() {
        return model;
    }


}