
export function DeviceSettingsView() {

    const events = new andy.EventManager();

    let shutdownForm, updateForm, envVarsForm, exeCmdForm, deviceAddprojectForm, projectsList, envVarsList, exeCmdList;

    function constructor() {

        const saveButtonProps = { icon: 'mdi-content-save-outline', label: 'save' };
        const addButtonProps = { icon: 'mdi-plus', label: 'add' };

        window.projectsList =  projectsList = new andy.UI.List(null, projectListItemTemplate, { removable: true, draggable: true, placeholder: 'no_device_projects_list_placeholder' });

        projectsList.on('itemRemoved', (item) => events.dispatchEvent('project.removed', item))

        deviceAddprojectForm = new andy.UI.Form('device_projects', {
            list: { label: '', item: projectsList }
        }, {
            add: new andy.UI.Button(addButtonProps, addProjectHandler),
            save: new andy.UI.Button(saveButtonProps, saveHandler),
        });

        shutdownForm = new andy.UI.Form('schedule_shutdown', {
            enabled: { label: 'enable', item: new andy.UI.Switch() },
            hour: { label: 'hour', item: new andy.UI.Input({ type: "time" }) },
        }, {
            save: new andy.UI.Button(saveButtonProps, saveHandler)
        });

        updateForm = new andy.UI.Form('schedule_sync', {
            enabled: { label: 'enable', item: new andy.UI.Switch() },
            hour: { label: 'hour', item: new andy.UI.Input({ type: "time" }) },
        }, {
            save: new andy.UI.Button(saveButtonProps, saveHandler)
        });

        envVarsList = new andy.UI.List(null, envVarListItemTemplate, { removable: true, placeholder: 'no_env_vars_list_placeholder' });

        envVarsForm = new andy.UI.Form('environment_variables', {
            envVars: { label: '', item: envVarsList }
        }, {
            add: new andy.UI.Button(addButtonProps, () => envVarsList.add({ key: '', value: '' })),
            save: new andy.UI.Button(saveButtonProps, saveHandler),
        });

        exeCmdList = new andy.UI.List(null, exeCmdListItemTemplate, { removable: true, placeholder: 'no_commands_list_placeholder' });

        exeCmdForm = new andy.UI.Form('executable_command', {
            exeCmd: { label: '', item: exeCmdList }
        }, {
            add: new andy.UI.Button(addButtonProps, () => exeCmdList.add({ cmd: '', args: '', delay: '' })),
            save: new andy.UI.Button(saveButtonProps, saveHandler),
        });
    }

    function addProjectHandler() {
        events.dispatchEvent('add.project');
    }

    function saveHandler() {
        const values = {
            projects: projectsList.getValue(),
            update: updateForm.getValue(),
            shutdown: shutdownForm.getValue(),
            envVars: envVarsList.getValue((item) => item.getValue()),
            exeCmd: exeCmdList.getValue((item) => item.getValue())
        }
        events.dispatchEvent('save', values);
    }

    function envVarListItemTemplate(item, eitem) {
        let entry = new andy.UI.Group({
            key: new andy.UI.Input({ placeholder: 'key' }),
            value: new andy.UI.Input({ placeholder: 'value' })
        });
        entry.setValue(item);
        eitem.item = entry;
        return entry.getElement();
    }

    function exeCmdListItemTemplate(item, eitem) {
        let entry = new andy.UI.Group({
            cmd: new andy.UI.Input({ placeholder: 'cmd' }),
            args: new andy.UI.Input({ placeholder: 'args' }),
            delay: new andy.UI.Input({ placeholder: 'delay' })
        });
        entry.setValue(item);
        eitem.item = entry;
        return entry.getElement();
    }

    function projectListItemTemplate(item, eitem) {
        return new andy.UI.Group({
            icon: new andy.UI.Icon('dashboard'),
            label: new andy.UI.Label(item.name),
        }).getElement();
    }

    function setValue({ update, shutdown, projects, exeCmd, envVars }) {
        shutdownForm.setValue((shutdown) ? shutdown : {});
        updateForm.setValue((update) ? update : {});
        exeCmdForm.setValue({ exeCmd: (exeCmd) ? exeCmd : [] });
        envVarsForm.setValue({ envVars: (envVars) ? envVars : [] });
        deviceAddprojectForm.setValue({ list: (projects) ? projects : [] });
    }

    function reset(){
        deviceAddprojectForm.reset();
        shutdownForm.reset(); 
        updateForm.reset(); 
        envVarsForm.reset(); 
        exeCmdForm.reset();
    }

    constructor();

    return {
        get element() {
            return [deviceAddprojectForm.element, shutdownForm.element, updateForm.element, envVarsForm.element, exeCmdForm.element];
        },
        get projectsList() {
            return projectsList;
        },
        setValue,
        reset,
        on: events.addEventListener
    }
}