export function MainMenu(p) {

    const props = {
        ...{
            children: {}
        },
        ...p
    }

    const element = new DIV('andy-ui-main-menu');

    function constructor() {
        if (props.style) {
            element.style = { ...element.style, ...props.style };
        }
    }

    function digChilds(children, forEachCB) {
        const digged = {};

        function iterator(children) {
            Object.values(children).forEach(child => {
                if (forEachCB) {
                    forEachCB(child);
                }
                digged[child.props.code] = child;
                if (Object.values(child.props.children).length) {
                    iterator(child.props.children);
                }
            })
        }

        iterator(children);

        return digged;
    }

    function append(item) {
        const code = item.props.code;
        if (props.children[code]) {
            console.error('Menu Child - item code already exists', code, item);
            return;
        } else {
            item.parent = _public;
            props.children[code] = item;
            element.append(item.element);
        }
    }

    function removeChild(code) {
        if (props.children[code]) {
            props.children[code].remove();
            props.children[code] = null;
            delete props.children[code];
        }
    }

    function deselect({ except } = { except: null }) {

        Object.values(props.children).forEach(child => {
            if (child.props.code != except) child.deselect();
        });

    }

    function select(){
            
    }

    function remove() {
        element.remove();
    }

    function getElement() {
        return element;
    }

    constructor();

    const _public = {
        get element() {
            return element;
        },
        get props() {
            return props;
        },
        getElement,
        append,
        remove,
        removeChild,
        deselect,
        select
    }

    return _public;
}