export function ZoomInput(p) {

    const events = new andy.EventManager();

    const props = {
        ...{
            initialValue: 100,
            step: 10,
            min: 10,
            max: 150
        },
        ...p
    }

    let zoomValue = 0;


    let wrapper, minusBtn, plusBtn, input;


    function constructor() {
        wrapper = new DIV('andy-ui-zoominput');
        minusBtn = new andy.UI.Button({ icon: 'mdi-minus' }, zoomOutHandler);
        plusBtn = new andy.UI.Button({ icon: 'mdi-plus' }, zoomInHandler);

        input = new andy.UI.Input({});
        input.on('change', inputChangeHandler);

        wrapper.append(minusBtn.element, input.element, plusBtn.element);

        zoomValue = props.initialValue;
        update();

    }

    function inputChangeHandler(me) {
        zoomValue = parseInt(me.value);
        update(); 
        events.dispatchEvent('change', zoomValue);
    }

    function zoomInHandler() {
        zoomValue += props.step;
        update();
        events.dispatchEvent('change', zoomValue);
    }

    function zoomOutHandler() {
        zoomValue -= props.step;
        update();
        events.dispatchEvent('change', zoomValue);
    }

    function update() {
        if(zoomValue < props.min){
            zoomValue = props.min;
        }
        if(zoomValue > props.max){
            zoomValue = props.max; 
        }

        input.setValue(`${zoomValue}%`);
    }

    function getElement() {
        return wrapper;
    }

    constructor();

    return {
        get element() {
            return wrapper;
        },
        get props() {
            return props;
        },
        set value(v) {
            zoomValue = v;
            update();
        },
        get value() {
            return zoomValue;
        },
        getElement,
        on: events.addEventListener
    }

}