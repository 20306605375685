export function SaveProjectModal() {

    const events = new andy.EventManager();

    let saveProjectForm, saveProjectModal;

    function constructor() {

        saveProjectForm = new andy.UI.Form('save_changes', {
            label: { label: '', item: new andy.UI.Label('save_confirm') }
        },
            {
                cancel: new andy.UI.Button({ icon: 'clear', label: 'cancel' }, cancelHandler),
                save: new andy.UI.Button({ icon: 'check', label: 'save' }, saveHandler)
            }
        );

        saveProjectModal = new andy.UI.Modal(saveProjectForm, true);
        saveProjectModal.on('dismiss', cancelHandler);
        
    }

    function saveHandler(me) {
        events.dispatchEvent('save');
        saveProjectModal.props.dismissable = false; 
        me.disable(); 
    };

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function show() {
        saveProjectForm.buttons.items.save.enable(); 
        saveProjectModal.props.dismissable = true; 
        saveProjectModal.show();
    }

    function hide() {
        saveProjectModal.hide();
    }

    constructor();

    return {
        show,
        hide,
        on: events.addEventListener
    }
}

// me.disable();
// pagesList.save();
// logicEditor.saveApplication();
// scheduleEditor.saveSchedules();
// currentProject.save(function () {
//     saveProjectModal.hide();
//     setTimeout(() => {
//         me.enable();
//     }, 1000);
// });