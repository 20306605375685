export function StudioNodeEditor() {

    const events = new andy.EventManager();

    let nodeEditorEl = new DIV('node-editor-wrapper');

    let svg = null;

    let currentNode = null;
    let NODES = {};
    let OUTPUTS = [];
    let INPUTS = [];

    let offset = {
        top: 119,
        left: 74
    };

    let currentOutput = null;


    function constructor() {
        createSvg();
        dragEvents();

        nodeEditorEl.addEventListener('dragenter', (e) => {
            events.dispatchEvent('dragenter', e);
        });

        nodeEditorEl.addEventListener('dragover', (e) => {
            e.preventDefault();
        });

        nodeEditorEl.addEventListener('drop', (e) => {
            events.dispatchEvent('drop', e);
        });
    }

    function createSvg() {
        svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.ns = svg.namespaceURI;
        var svgs = nodeEditorEl.getElementsByTagName("svg");
        if (svgs.length < 1) {
            nodeEditorEl.appendChild(svg);
        }
    }

    function add(node) {

        if (NODES[node._id]) {
            return;
        }

        NODES[node._id] = node;

        node.on('removed', () => {
            delete NODES[node._id];
        })

        nodeEditorEl.appendChild(node.getElement());

        function dragMousedownHandler() {
            currentNode = node.getElement();
        }
        node.getTitleElement().addEventListener('mousedown', dragMousedownHandler);

        for (let i = 0; i < node.outputs.length; i++) {
            initOutput(node.outputs[i]);
        }

        for (let i = 0; i < node.inputs.length; i++) {
            initInput(node.inputs[i]);
        }
    }

    function findOutputByCode(code) {
        for (let i = 0; i < OUTPUTS.length; i++) {
            var output = OUTPUTS[i];
            if (output.code == code) {
                return output;
            }
        }
        return null;
    }


    function findInputByCode(code) {
        for (let i = 0; i < INPUTS.length; i++) {
            var input = INPUTS[i];
            if (input.code == code) {
                return input;
            }
        }
        return null;
    }

    function completeConnections() {
        for (let i = 0; i < OUTPUTS.length; i++) {
            let oP = OUTPUTS[i];
            if (oP.connectedTo) {
                let iP = findInputByCode(oP.connectedTo);

                if (iP) {

                    iP.attach(oP);
                }
            }
        }
        setTimeout(updatePaths, 500);
    }

    function getElement() {
        return nodeEditorEl;
    }

    function initInput(input) {
        INPUTS.push(input);
        input.el.addEventListener('mouseup', (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (currentOutput) {

                currentOutput.attach(input);
                currentOutput = null;
                updatePaths();
            }
        })
    }

    function initOutput(output) {
        OUTPUTS.push(output);
        let path = new createPath();
        svg.appendChild(path);
        output.path = path;

        output.el.addEventListener('mouseup', (e) => {

            e.preventDefault();
            e.stopPropagation();

            currentOutput = output;
            output.el.className = 'node connecting';
        })
    }

    function createPath() {
        let path = document.createElementNS(svg.ns, 'path');
        path.setAttributeNS(null, 'stroke', 'white');
        path.setAttributeNS(null, 'stroke-width', '2');
        path.setAttributeNS(null, 'fill', 'none');

        return path;
    }

    function drawPath(a, b) {
        var diff = {
            x: b.x - a.x,
            y: b.y - a.y
        };

        var pathStr = 'M' + a.x + ',' + a.y + ' ';
        pathStr += 'C';
        pathStr += a.x + diff.x / 3 * 2 + ',' + a.y + ' ';
        pathStr += a.x + diff.x / 3 + ',' + b.y + ' ';
        pathStr += b.x + ',' + b.y;

        return pathStr;
    }

    function updatePaths() {
        for (let i = 0; i < OUTPUTS.length; i++) {
            if (OUTPUTS[i].connectedTo) {
                let p = OUTPUTS[i].path;
                let oPos = OUTPUTS[i].el.getBoundingClientRect();
                let iPos = OUTPUTS[i].connectedTo.el.getBoundingClientRect();

                let iP = {
                    x: (iPos.left - offset.left),
                    y: ((iPos.top + 15) - offset.top)
                }
                let oP = {
                    x: (oPos.right - offset.left),
                    y: ((oPos.top + 15) - offset.top)
                }
                updatePath(p, oP, iP);
            }
        }

    }

    function updatePath(p, oP, iP) {
        let s = drawPath(oP, iP);
        p.setAttributeNS(null, 'd', s);
    }

    function dragEvents() {

        let initPos, elPos, drag = false;
        let parentRects = null;
        let currentNodePos = {};



        function mousedownHandler(e) {
            parentRects = nodeEditorEl.getBoundingClientRect();
            initPos = {
                x: e.clientX,
                y: e.clientY
            }
            if (currentNode) {
                elPos = currentNode.getBoundingClientRect();
                drag = true;
            }
        }

        function mousemoveHandler(e) {
            if (drag) {
                let currentPos = {
                    x: e.clientX,
                    y: e.clientY
                }
                let newPos = {
                    x: (currentPos.x - initPos.x),
                    y: (currentPos.y - initPos.y)
                }
                if (currentNode) {
                    currentNodePos = { left: (elPos.left + newPos.x) - parentRects.left, top: (elPos.top + newPos.y) - parentRects.top }
                    currentNode.style.left = (currentNodePos.left) + 'px';
                    currentNode.style.top = (currentNodePos.top) + 'px';
                }
                updatePaths();
            }
            if (currentOutput) {
                let p = currentOutput.path;
                let el = currentOutput.el.getBoundingClientRect();
                let oP = {
                    x: el.right - offset.left,
                    y: ((el.top + 15) - offset.top)
                };
                let iP = {
                    x: e.pageX - offset.left,
                    y: (e.pageY - offset.top)
                };
                updatePath(p, oP, iP);
            }
        }

        function mouseupHandler(e) {
            if (drag) {
                drag = false;
                currentNode.update(currentNodePos);
                currentNode = null;
            }
            if (currentOutput) {
                updatePath(currentOutput.path, {
                    x: 0,
                    y: 0
                }, {
                    x: 0,
                    y: 0
                });
                currentOutput.detach();
                currentOutput = null;
            }

        }

        nodeEditorEl.addEventListener('mousedown', mousedownHandler);
        nodeEditorEl.addEventListener('mousemove', mousemoveHandler);
        nodeEditorEl.addEventListener('mouseup', mouseupHandler);
    }


    function returnValue() {
        return Object.values(NODES).map(node => {
            return node.returnValue();
        });
    }

    function clearEditor() {
        nodeEditorEl.innerHTML = '';
        createSvg();
        NODES = {};
        OUTPUTS = [];
        INPUTS = [];
    }

    constructor();

    return {
        clearEditor,
        findInputByCode,
        findOutputByCode,
        returnValue,
        updatePath,
        updatePaths,
        add,
        getElement,
        completeConnections,
        on: events.addEventListener
    }

}


export function Node() {

    const events = new andy.EventManager();

    let _id;
    let layout;
    let removed = false;

    let nodeEl = new DIV('node-element');

    let titleEl = new DIV('title');
    let connectorsWrapperEl = new DIV('connectors-wrapper');
    let inputsWrapperEl = new DIV('inputs-wrapper');
    let outputsWrapperEl = new DIV('outputs-wrapper');

    let deleteEl = new andy.UI.Icon('mdi-delete').getElement();

    let inputs = [];
    let outputs = [];

    let title, _removable = false;
    let position = { top: 0, left: 0 };

    function constructor() {

        connectorsWrapperEl.appendChild(inputsWrapperEl);
        connectorsWrapperEl.appendChild(outputsWrapperEl);

        nodeEl.appendChild(titleEl);
        nodeEl.appendChild(connectorsWrapperEl);

        nodeEl.update = function ({ top, left }) {
            position = { top, left };
        }

        deleteEl.addEventListener('click', deleteNode);

        let w = window.innerWidth / 3;
        let h = window.innerHeight / 3;

        setPosition(w, h);
    }


    function removable() {
        nodeEl.appendChild(deleteEl);
        _removable = true;
    }

    function deleteNode() {
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].detach();

        }

        for (let i = 0; i < outputs.length; i++) {
            outputs[i].detach();
        }

        nodeEl.remove();
        removed = true;
        events.dispatchEvent('removed');
    };

    function addInput(uid, code, label, type, accepts, connectedTo) {
        let el = new DIV('node', label);
        inputsWrapperEl.appendChild(el);

        function returnConnectedCodes(nodes) {
            return nodes.map(function (node) {
                return node ? node.code : null;
            })
        }

        let input = {
            el,
            uid,
            label,
            code,
            type,
            accepts,
            connectedTo: connectedTo ? connectedTo : [],
            attach: (output) => {
                input.connectedTo.push(output);
                output.connectedTo = input;
                input.el.className = 'node connected';
                output.el.className = 'node connected';

            },
            detach: () => {
                if (input.connectedTo) {
                    if (input.connectedTo.el) {
                        input.connectedTo.el.className = 'node';
                    }
                    input.connectedTo.connectedTo = null;
                    input.connectedTo = null;
                }
                if (input.path) {
                    input.path.setAttributeNS(null, 'd', '');
                }
                input.el.className = 'node';
            },
            returnValue: () => {
                let value = {
                    _id: input.uid,
                    code: input.code,
                    label: input.label,
                    type: input.type,
                    accepts: input.accepts,
                    connectedTo: (input.connectedTo) ? returnConnectedCodes(input.connectedTo) : null
                }
                return value;
            }
        }

        inputs.push(input);
    }

    function addOutput(uid, code, label, type, accepts, connectedTo) {
        let el = new DIV('node', label);
        outputsWrapperEl.appendChild(el);

        let output = {
            el,
            uid,
            code,
            label,
            type,
            accepts,
            connectedTo,
            path: null,
            attach: (input) => {
                input.attach(output);
                // output.connectedTo = input;
                // input.connectedTo = output;
                input.path = output.path;
                // output.el.className = 'node connected';
                // input.el.className = 'node connected';
            },
            detach: () => {
                if (output.connectedTo) {
                    if (output.connectedTo.el) {
                        output.connectedTo.el.className = 'node';
                    }
                    output.connectedTo.connectedTo = null;
                    output.connectedTo = null;
                }
                if (output.path) {

                    output.path.setAttributeNS(null, 'd', '');
                }
                output.el.className = 'node';
            },
            returnValue: () => {
                let value = {
                    _id: output.uid,
                    code: output.code,
                    label: output.label,
                    type: output.type,
                    accepts: output.accepts,
                    connectedTo: (output.connectedTo) ? output.connectedTo.code : null
                }
                return value;
            }
        };

        outputs.push(output);
    }

    function setTitle(t) {
        titleEl.innerHTML = t;
        title = t;
    }


    function returnValue() {

        let node = {
            title: title,
            removable: _removable,
            _id: _id,
            layout,
            position: {
                top: position.top,
                left: position.left
            },
            inputs: [],
            outputs: []
        };

        for (let i = 0; i < inputs.length; i++) {
            let iP = inputs[i];
            node.inputs.push(iP.returnValue());

        }

        for (let i = 0; i < outputs.length; i++) {
            let oP = outputs[i];
            node.outputs.push(oP.returnValue());
        }
        return node;
    }

    function setPosition(x, y) {
        position.left = x;
        position.top = y;
        nodeEl.style.left = x + 'px';
        nodeEl.style.top = y + 'px';
    }

    function getElement() {
        return nodeEl;
    }

    function getTitleElement() {
        return titleEl;
    }

    constructor();

    return {
        set _id(v) {
            _id = v;
        },
        set layout(v) {
            layout = v;
        },
        get _id() {
            return _id;
        },
        get layout() {
            return layout;
        },
        get inputs() {
            return inputs;
        },
        get outputs() {
            return outputs;
        },
        getElement,
        getTitleElement,
        removable,
        delete: deleteNode,
        setPosition,
        returnValue,
        setTitle,
        addOutput,
        addInput,
        on: events.addEventListener
    }

}