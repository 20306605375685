export function SchedulerToolbars() {

    const events = new andy.EventManager();

    let rightGroup, leftGroup;

    function constructor() {
        rightGroup = new andy.UI.Group({
            week: new andy.UI.Button({ icon: 'mdi-calendar-week' }, () => events.dispatchEvent('calendar.week')),
            month: new andy.UI.Button({ icon: 'mdi-calendar-month' }, () => events.dispatchEvent('calendar.month')),
            list: new andy.UI.Button({ icon: 'mdi-view-list' }, () => events.dispatchEvent('calendar.list')),
            // add: new andy.UI.Button({ icon: 'mdi-text-box-plus' }, () => events.dispatchEvent('calendar.add'))
        }, { style: { justifyContent: 'flex-end' } });

        leftGroup = new andy.UI.Group({
            today: new andy.UI.Button({ icon: 'mdi-calendar-today' }, () => events.dispatchEvent('calendar.today')),
            prev: new andy.UI.Button({ icon: 'mdi-calendar-arrow-left' }, () => events.dispatchEvent('calendar.previous')),
            currentDate: new andy.UI.Label(''),
            next: new andy.UI.Button({ icon: 'mdi-calendar-arrow-right' }, () => events.dispatchEvent('calendar.next'))
        }, { style: { justifyContent: 'flex-start' } });
    }

    function setCurrentDateRange(currentDate){
        leftGroup.setValue({currentDate});
    }

    constructor();

    return {
        get elements() {
            return [leftGroup.getElement(), rightGroup.getElement()]
        },
        setCurrentDateRange,
        on: events.addEventListener
    }

}