import { ModulesViewController } from './content.modules';
import { DatabasesController } from './content.databases';
import { FilesController } from './content.files';

export function ContentController() {

    const socket = andy.backend.socket;

    window.modules = new ModulesViewController();
    window.mediaManager = new FilesController();
    window.databases = new DatabasesController();

    return {};
}