import { AuthService } from './auth';
import { FontsService } from './fonts';
import { ModulesService } from './modules';
import { UserService } from './user';
import { DevicesService } from './devices';
import { DirectDeviceService } from './directDevice';
import { ProjectsService } from './projects';
import { MasterServerService } from './masterserver';
import { FilesService } from './files';
import { UploadService } from './upload';
import { UsersService } from './users';
import { RolesService } from './roles';
import { SettingsService } from './settings';
import { DatabaseService } from './databases';
import { LanguageService } from './languages';

const io = require('socket.io-client');

export function BackendService() {

    let events = new andy.EventManager();
    let socket;
    let backendInfo = { capabilities: {} };
    let services = {};
    let host = null;

    function connect(hostname, cb) {
        hostname = hostnameProcess(hostname);
        console.log(hostname);
        if (!socket) {
            socket = io(window.location.protocol + '//' + hostname, {
                transports: ['websocket', 'polling', 'flashsocket']
            });

            socket.on('connect_failed', function () {
                console.log('Connection Failed');
                events.dispatchEvent('connect.result', false);
                events.dispatchEvent('connect.failed');
                if (cb) cb(false);
            });

            socket.on('connect', () => {
                console.log('Connection Success');
                events.dispatchEvent('connect.result', true);
                events.dispatchEvent('connect.success');
                if (cb) cb(true, socket);
                host = hostname;
                instantiateAuthService();
            });

            socket.on('disconnect', (res) => {
                console.log('disconnected');
            });

            socket.on('backend.info', (res) => {
                console.log('Backend info: ', res);
                backendInfo = res;
            });

        } else {
            if (socket.connected && services) {
                if (cb) cb(true, socket);
            } else {
                console.log('Already connected or pending.');
            }

        }
    }

    function hostnameProcess(hostname){
        const split = hostname.split('.');
        if (split.length == 1) {
            return hostname + '.andy.media';
        }
        return hostname;
    }

    function instantiateAuthService() {
        services.auth = new AuthService(_public);
        events.dispatchEvent('auth.available');
    }

    function instantiateServices() {
        services.user = new UserService(_public);
        services.fonts = new FontsService(_public);
        services.modules = new ModulesService(_public);
        services.devices = new DevicesService(_public);
        services.projects = new ProjectsService(_public);
        services.directDevice = new DirectDeviceService(_public);
        services.masterServer = new MasterServerService(_public);
        services.files = new FilesService(_public);
        services.upload = new UploadService(_public);
        services.users = new UsersService(_public);
        services.roles = new RolesService(_public);
        services.settings = new SettingsService(_public);
        services.databases = new DatabaseService(_public);
        services.languages = new LanguageService(_public);
        setTimeout(() => { events.dispatchEvent('services.available'); }, 300);
    }

    let _public = {
        get socket() {
            return socket;
        },
        get hostname() {
            return host;
        },
        get location(){
            return window.location.protocol + '//' + host;
        },
        get services() {
            if (!services) {
                console.error('Cannot access any services because Backend is not connected');
                return null;
            }
            return services;
        },
        get connected() {
            if (socket) {
                return socket.connected;
            }
            return false;
        },
        get info() {
            return backendInfo;
        },
        connect,
        instantiateServices,
        on: events.addEventListener
    }

    return _public;
}