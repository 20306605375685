export function ModulesController() {

    const service = andy.backend.services.modules;
    const backendInfo = andy.backend.info;
    const events = new andy.EventManager();
    const loadedModules = {};

    function constructor() {

        service.on('modules.metadata', loadModules);
        service.getModules();

    }

    function getModules() {
        return loadedModules;
    }

    function getModulesAsArray() {
        return Object.values(loadedModules);
    }

    function createModuleView(code, route) {
        let view = andy.mainContainer.newView({
            code,
            route
        });
        view.element.id = code;
        return view;
    }

    function loadModule(module) {

        let moduleCode = (backendInfo.capabilities['exportPluginByName']) ? module.package.name : module.folder;

        let view = null;
        let loadPath = andy.debug ? 'dev' : 'dist';
        if (module.package.type.includes('module')) {
            view = createModuleView(module.package.name + '-container', module.package.name);
        }

        try {
            andy.Utils.loadJSFile(`${window.location.protocol}//${andy.backend.hostname}/modules/${moduleCode}/${loadPath}/main.js`, () => {
                loadedModules[module.package.name] = { module, view };
                events.dispatchEvent('module.load.success', module);
            });
        } catch (e) {
            console.error('Loading Module:', module.package.name, e);
            events.dispatchEvent('module.load.error', module);
        }

    }

    function loadModules(meta) {
        meta.forEach(module => loadModule(module));
    }

    function getModulesFromV1() {
        return {
            modules: getModulesAsArray().map(mod => {
                return mod.module;
            })
        };
    }


    constructor();

    return {
        getModules,
        getModulesAsArray,
        getModulesFromV1,
        on: events.addEventListener
    }

}