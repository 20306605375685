export function UploadService({ socket }) {

    const uploader = new SocketIOFileUpload(socket);
    const { submitFiles, addEventListener, listenOnDrop } = uploader;

    function constructor() {
    
    }

    constructor();

    return {
        get uploader() {
            return uploader;
        },
        submitFiles,
        listenOnDrop,
        on: addEventListener
    }
}
