export function TagFormModal () {

    let events = new andy.EventManager();

    andy.db.vars.currentTags = [];

    let tagsForm = new andy.UI.Form('tag', {
        _id: {
            label: 'id',
            item: new andy.UI.Input()
        },
        id: {
            label: 'import_id',
            item: new andy.UI.Input()
        },
        language: {
            label:'',
            item: new andy.UI.LanguageObject({
                name: {
                    label:'name',
                    item: new andy.UI.Input({ placeholder: 'Lorem Ipsum Dolor Sit Amet', validators: [andy.Validators.notEmpty] }),
                }
            })
        },
        icon: { 
            label: 'icon',
            item: new andy.UI.GFMSelect({ type: 'image' }) 
        },
        gradient: {
            label: 'tag_gradient',
            item: new andy.UI.GradientPicker()
        },
        color: {
            label: 'color',
            item: new andy.UI.ColorPicker()
        },
        order: {
            label: 'order',
            item: new andy.UI.Input({ type: 'number', min: 0 })
        },
    }, {
        save: new andy.UI.Button({ label: 'save', icon: 'save' }, saveTagsHandler)
    });

    if (tagsForm.items.language && tagsForm.items.language.item.getElement()) {
        tagsForm.items.language.item.getElement().querySelector('.header').style.display = 'none';
        tagsForm.items.language.item.getElement().querySelector('.footer').style.display = 'none';
        tagsForm.items.language.item.setValue({
            en_US: {},
            pt_PT: {},
        });
    }

    tagsForm.items._id.item.getElement().setAttribute('disabled', true);
    tagsForm.items.id.item.getElement().setAttribute('disabled', true);

    function saveTagsHandler() {
        let values = tagsForm.getValue();

        if (!validate(values)) {
            return;
        }

        events.dispatchEvent('databases.tags.save', { values });
    }

    function validate(values) { // to-do: validation of fields
        return true;
    }

    // Tags Control
    let tagsModal = new andy.UI.Modal([tagsForm]);
   
    return {
        form: tagsForm,
        modal: tagsModal,
        on: events.addEventListener
    };
}
