export function SectionBar(p) {

    const events = new andy.EventManager();

    const props = {
        ...{
            children: {}
        },
        ...p
    }

    const element = new DIV('andy-ui-sectionbar');

    function constructor() {
    }

    function append(code, item, order = 99) {
        if (props.children[code]) {
            console.error('Section - item code already exists', code, item);
            return;
        } else {
            item.style.order = order; 
            props.children[code] = {element: item, code};
            element.append(item);
            events.dispatchEvent('child.append', item);
        }
    }

    function removeChild(code) {
        if (props.children[code]) {
            props.children[code].element.remove();
            props.children[code] = null;
            delete props.children[code];
        }
    }

    function clear(){
        Object.values(props.children).forEach(child=>removeChild(child.code));
    }

    constructor();

    return {
        get element() {
            return element;
        },
        get props() {
            return props;
        },
        clear,
        append,
        removeChild,
        on: events.addEventListener
    }

}