
import { VideoEditor } from './videoEditor';

export function TriggerModal() {

    const events = new andy.EventManager();
    const videoEditor = new VideoEditor();

    let list, modal, form;
    let saveCallback = null; 

    function constructor() {

        list = new andy.UI.List([], listItemTemplate, {
            removable: true,
            draggable: false,
            valueProcess: listValueProcessHandler
        });

        form = new andy.UI.Form('triggers', {
            video: { label: 'video', item: videoEditor },
            timestampsList: { label: 'triggers_list', item: list }
        }, {
            save: new andy.UI.Button({ label: 'save', icon: 'mdi-content-save' }, saveHandler)
        });

        videoEditor.on('create-trigger', (data) => {
            list.add({ time: data.timestamp, trigger: "studio-event", value: "example" });
        });
    
        videoEditor.on('clear-all-triggers', () => {
            form.reset();
        });

        modal = new andy.UI.Modal(form);

    }

    function listValueProcessHandler(item){
        let {time, trigger, value, duration} = item.getValue(); 
        return {time, trigger, value, duration};
    }

    function saveHandler() {
        if(saveCallback){
            saveCallback(form.getValue().timestampsList);
        }
        // events.dispatchEvent('set-timestamps', form.getValue().timestampsList);
        modal.hide();
    }

    function listItemTemplate(item, eItem) {

        let group = new andy.UI.Group({
            icon: new andy.UI.Icon('mdi-timeline-outline'),
            label: new andy.UI.Label('timestamp'),
            time: new andy.UI.Input({ type: 'text', validators: [andy.Validators.notEmpty] }),
            label2: new andy.UI.Label('duration'),
            duration: new andy.UI.Input({ type: 'number', validators: [andy.Validators.notEmpty] }),
            label3: new andy.UI.Label('trigger'),
            trigger: new andy.UI.Select(['studio-event', 'adapter-event', 'subtitle']),
            label4: new andy.UI.Label('value'),
            value: new andy.UI.Input({ type: 'text', validators: [andy.Validators.notEmpty] })
        });

        group.setValue({
            time: item.time ? item.time : '',
            trigger: item.trigger ? item.trigger : '',
            value: item.value ? item.value : '',
            duration: item.duration ? item.duration : 0
        });

        eItem.item = group; 

        return group.getElement();
    }

    // list.setValue = function (v) {
    //     form.reset();
    //     v.forEach((sv) => {
    //         sv.id = andy.uid.generate();
    //         list.add(sv);
    //     });
    // }

    function show(triggers, callback){
        if(callback){
            saveCallback = callback;
        }
        modal.show(); 
    }

    function hide(){
        if(saveCallback){
            saveCallback = null;
        }
        modal.hide(); 
    }

    constructor(); 

    return {
        show,
        hide,
        modal,
        list,
        form,
        on: events.addEventListener
    }
}