import { UserBarWidget } from './widgets/userBar';

export function UserController({ user }) {

    const service = andy.backend.services.user;
    const userBarWidget = new UserBarWidget({ name: getUserNameString(user), imageUrl: null });

    function constructor() {
        service.dataset.on('save', ({ document }) => {
            userBarWidget.setValue({ name: getUserNameString(document), imageUrl: null }); // TODO - Pass the user image
        });

        andy.topBarSection.append('user-bar-widget', userBarWidget.element, 10); 

        userBarWidget.on('profile', ()=>andy.mainContainer.show('user-profile-container'));
        userBarWidget.on('logout', ()=>andy.Auth.logout());
    }

    function getUserNameString(u) {
        return u.name + ' ' + u.surname;
    }

    constructor();

    return {
        get element(){
            return userBarWidget.element;
        }
    }
}