import { UploadListItem } from './uploadListItem';

export function UploadsList(p) {

    const events = new andy.EventManager();

    const props = {
        ...{
            files: {}
        },
        ...p
    }

    const element = new DIV('andy-ui-uploads-list');

    function constructor() {
    }

    function addFile(code, file) {
        if (!props.files[code]) {
            const item = new UploadListItem({ file });
            props.files[code] = item;
            element.append(item.element);
        }
    }

    function updateFile(code, bytesLoaded) {
        if (props.files[code]){
            props.files[code].props.bytesLoaded = bytesLoaded; 
            props.files[code].update(); 
        }
    }

    function completeFile(code) {
        if (props.files[code]){
            props.files[code].complete(); 
            props.files[code] = null; 
            delete props.files[code];
        }
    }

    function getElement(){
        return element;
    }

    constructor();

    return {
        get element() {
            return element;
        },
        get props() {
            return props;
        },
        getElement, 
        addFile,
        updateFile,
        completeFile,
        on: events.addEventListener
    }

}