
export function StudioContainer() {

    const events = new andy.EventManager();

    let modalEl, sidebarEl, containerEl, toolbarEl, rightToolbarEl, leftToolbarEl, projectNameEl, projectNameLabel,
        studioNavMenuEl, listSectionEl, container, zoneMenu, zoneMenuWrapper;


    function constructor() {
        container = new andy.UI.Container({ wrapper: true });
        projectNameLabel = new andy.UI.Label('untitled');

        modalEl = new DIV('andy-studio-modal');
        sidebarEl = new DIV('andy-studio-sidebar');
        containerEl = new DIV('andy-studio-container');
        toolbarEl = new DIV('andy-studio-toolbar');
        zoneMenuWrapper = new DIV('andy-studio-zone-menu');

        const wrapper = new DIV('andy-studio-wrapper');
        wrapper.append(toolbarEl, containerEl, zoneMenuWrapper);
        modalEl.append(sidebarEl, wrapper);

        containerEl.append(container.getElement());

        document.body.append(modalEl);

        studioNavMenuEl = new DIV('studio-nav-menu');
        listSectionEl = new DIV('studio-menu-section')
        sidebarEl.append(listSectionEl, studioNavMenuEl);


        rightToolbarEl = new DIV('r-toolbar');
        leftToolbarEl = new DIV('l-toolbar');
        projectNameEl = new DIV('project-name');
        projectNameEl.append(projectNameLabel.element);

        toolbarEl.append(leftToolbarEl, projectNameEl, rightToolbarEl);


        zoneMenu = new andy.UI.Menu({orientation: 'horizontal'});

        zoneMenu.add({ code: 'editor', icon: 'mdi-pencil-ruler', label: '', callback: () => { events.dispatchEvent('menu.editor') } });
        zoneMenu.add({ code: 'logic', icon: 'mdi-transit-connection-variant', label: '', callback: () => { events.dispatchEvent('menu.logic') } });
        zoneMenu.add({ code: 'schedule', icon: 'mdi-calendar-month', label: '', callback: () => { events.dispatchEvent('menu.scheduler') } });

        zoneMenuWrapper.append(zoneMenu.getElement());

        const studioButtonsGroup = new andy.UI.Group({
            settings: new andy.UI.Button({ icon: 'mdi-cog-outline' }, () => { events.dispatchEvent('project.settings') }),
            save: new andy.UI.Button({ icon: 'mdi-content-save-outline' }, () => { events.dispatchEvent('project.save') }),
            exit: new andy.UI.Button({ icon: 'mdi-exit-to-app' }, () => { events.dispatchEvent('studio.exit') }),
        });

        studioNavMenuEl.append(studioButtonsGroup.getElement());

    }

    function show() {
        modalEl.classList.add('show');
        zoneMenu.selectFirst();
    }

    function hide() {
        modalEl.classList.remove('show');
    }

    function clearToolbars() {
        leftToolbarEl.innerHTML = '';
        rightToolbarEl.innerHTML = '';
    }

    function clearSidebarListSection() {
        listSectionEl.innerHTML = '';
    }

    constructor();

    return {
        get container() {
            return container;
        },
        set projectName(name) {
            projectNameLabel.setValue(name);
        },
        get rightToolbar() {
            return rightToolbarEl;
        },
        get leftToolbar() {
            return leftToolbarEl;
        },
        get sidebarSection() {
            return listSectionEl;
        },
        clearToolbars,
        clearSidebarListSection,
        hide,
        show,
        on: events.addEventListener
    }
}