import { BackendService } from './services/backend';
import { AuthController } from './controllers/auth/auth';
import { EntryPointController } from './controllers/entrypoint/entrypoint';
import { FontsController } from './controllers/fonts/fonts';
import { UserController } from './controllers/user/user';
import { UserProfileController } from './controllers/user/userprofile';
import { AdminController } from './controllers/admin/admin';
import { ProjectsController } from './controllers/projects/projects';
import { StudioController } from './controllers/studio/studio';
import { ContentController } from './controllers/content/content';
import { NotificationsController } from './controllers/notifications/notifications';
import { DevicesController } from './controllers/devices/devices';
import { DeviceController } from './controllers/device/device';
import { DashboardController } from './controllers/statistics/dashboard';
import { dictionaryEN_US } from './../languages/en_US';
import { dictionaryPT_PT } from './../languages/pt_PT';
import { ModulesController } from './controllers/modules/modules';

export function AndyFrontApp() {

  const originalPathname = window.location.pathname;
  const mainMenu = andy.mainMenu = new andy.UI.MainMenu();
  const mainContainer = andy.mainContainer = new andy.UI.Container({ route: '', wrapper: true });
  const topBarSection = andy.topBarSection = new andy.UI.SectionBar();
  const toolbarSection = andy.toolbarSection = new andy.UI.SectionBar();
  let parentEl = null;
  let initialized = false;

  // Compatibility
  // window.main_container = mainContainer;

  const elements = {
    topBarLogo: new DIV('topbar-logo'),
    topBarCenter: new DIV('topbar-center'),
    userMenu: new DIV('user-menu'),
    toolbar: new DIV('main-toolbar'),
    mainMenu: new DIV('main-menu'),
    content: new DIV('content-container')
  };

  function constructor() {

    andy.events = new andy.EventManager();
    andy.views = {};
    andy.Language.dictionaries.en_US = dictionaryEN_US();
    andy.Language.dictionaries.pt_PT = dictionaryPT_PT();

    andy.Language.dictionary = andy.Language.dictionaries.en_US;

  }

  function start() {
    andy.backend = new BackendService();
    andy.Auth = new AuthController();
    andy.entry = new EntryPointController();

    andy.backend.on('connect.success', () => {
      window.socket = andy.backend.socket;
      andy.events.dispatchEvent('connect.success');
    });

    andy.Auth.on('success', (user) => {

      andy.events.dispatchEvent('auth.success');

      andy.backend.on('services.available', () => {  // wait for services to be availble before init the app to prevent requests without token
        init(user);
      });

      andy.backend.instantiateServices();

    });

    andy.Auth.on('failed', (user) => {
      andy.events.dispatchEvent('auth.failed');
    });
  }

  function init(user) {

    if (initialized) {
      return;
    }

    initialized = true;

    const socket = andy.backend.socket;
    andy._datasets.applications = new andy.Dataset('andy.applications');

    // Compatibility 

    window.notifications = new NotificationsController(socket);

    // Create Menu Sections

    const networkMenuSection = new andy.UI.MainMenuSection({ code: 'root.network.section', label: 'network', order: 1 });
    const applicationsMenuSection = new andy.UI.MainMenuSection({ code: 'root.applications.section', label: 'applications', order: 2 });
    const contentMenuSection = new andy.UI.MainMenuSection({ code: 'root.content.section', label: 'content', order: 3 });
    const adminMenuSection = new andy.UI.MainMenuSection({ code: 'root.admin.section', label: 'admin', order: 99 });

    mainMenu.append(networkMenuSection);
    mainMenu.append(applicationsMenuSection);
    mainMenu.append(contentMenuSection);
    mainMenu.append(adminMenuSection);

    andy.fonts = new FontsController();
    // Initialize Studio
    window.studio = new StudioController();
    andy.modules = new ModulesController();
    andy.user = new UserController({ user });
    andy.views.userProfile = new UserProfileController({ user });


    if (andy.Auth.can('statistics')) {
      window.statistics = new DashboardController();
    }

    if (andy.Auth.can('devices')) {
      andy.devices = new DevicesController();
      andy.device = new DeviceController();
    }

    if (andy.Auth.can('applications') && andy.Auth.can('layouts')) {
      // document.body.appendChild(studio.getElement());
      const projects = new ProjectsController();
    }

    if (andy.Auth.can('content')) {
      const content = new ContentController();
    }

    if (andy.Auth.can('administration')) {
      window.admin = AdminController();
    }

    andy.entry.hide();

    andy.Language.update();


    if (_env && _env.disableRouter) {
      // andy.mainContainer.showFirst();
    } else {

      if (originalPathname != '/') {
        andy.router.go(originalPathname);
      } else {
        // andy.mainContainer.showFirst();
      }

      andy.router.unlock();
    }

    mainContainer.on('view.change', (code) => {
      // console.log('next-view', code);
      toolbarSection.clear();
    })

    andy.events.dispatchEvent('initialized');
  }

  function render(element) {
    parentEl = element;
    element.append(elements.topBarLogo, elements.topBarCenter, elements.toolbar, elements.userMenu, elements.mainMenu, elements.content);
    elements.content.append(mainContainer.getElement());
    elements.mainMenu.append(mainMenu.element);
    elements.userMenu.append(topBarSection.element);
    elements.toolbar.append(toolbarSection.element);
  }

  constructor();

  return {
    render,
    start,
    on: andy.events.addEventListener
  }

}