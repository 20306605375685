
export function AdminSettingsView() {

    const events = new andy.EventManager();

    let platformForm, emailConfigForm;

    function constructor() {

        platformForm = new andy.UI.Form('platform', {
            platformName: { label: 'name', item: new andy.UI.Input(), validators: andy.Validators.notEmpty }
        }, {
            save: new andy.UI.Button({ icon: 'save', label: 'save' }, saveHandler)
        });

        emailConfigForm = new andy.UI.Form("smtp_email_configuration", {
            host: { label: 'host', item: new andy.UI.Input() },
            port: { label: 'port', item: new andy.UI.Input() },
            secure: { label: 'ssl', item: new andy.UI.Switch() },
            tls: { label: 'tls', item: new andy.UI.Switch() },
            username: { label: 'username', item: new andy.UI.Input() },
            password: { label: 'password', item: new andy.UI.Input({ type: 'password' }) }
        }, {
            save: new andy.UI.Button({ icon: 'save', label: 'save' }, saveHandler)
        });

    }

    function saveHandler() {
        if (platformForm.validate() && emailConfigForm.validate()) {
            events.dispatchEvent('save', {...platformForm.getValue(), smtpConfiguration: emailConfigForm.getValue() });
        }
    }

    function setValues(values) {
        platformForm.setValue(values);
        emailConfigForm.setValue(values.smtpConfiguration);
    }

    constructor();

    return {
        get elements() {
            return [platformForm.element, emailConfigForm.element];
        },
        setValues,
        on: events.addEventListener
    }
}