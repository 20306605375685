export function TagSelector (options) {

    const LocalItemModal = function () {
        let callback;
    
        function loadData(){
            localStorageGridList.clear();

            let items = andy._datasets['andy.db'].data[andy.db.vars.selectedDatabase._id].tags;
            items.forEach((item) => {
                localStorageGridList.addItem(item);
            });
        }
    
        function fileTemplate(item, render) {
            let wrapper = new DIV('item');

            let label = new andy.UI.Label((item.language) ? item.language[andy.Language.current].name : 'item');
            wrapper.append(label.getElement());
            
            render(wrapper);
            return item;
        }
        
        let saveButton = new andy.UI.Button({ label: 'save', icon: 'save' }, function () {
           if (callback) {
                callback(localStorageGridList.getValue());
           }
           localStorageModal.hide();
        });
        
        let cancelButton = new andy.UI.Button({ label: 'cancel', icon: 'cancel' }, function () {
            localStorageModal.hide();
        });
        
        let localStorageGridList = new andy.UI.GridList({
            template: fileTemplate
        });
        
        let localStorageForm = new andy.UI.Form('tags', {
            grid: { item: localStorageGridList }
        }, {
            cancel: cancelButton,
            save: saveButton
        }, {
            search: new andy.UI.SearchBox(localStorageGridList)
        });
        
        let localStorageModal = new andy.UI.Modal(null, true);
        
        function showAsModal(cb) {
            if (cb) {
                callback = cb;
            }
            localStorageModal.add(localStorageForm);
            cancelButton.show();
            saveButton.show();
            localStorageGridList.changeMode('multiple-select');
            loadData();
            localStorageModal.show();
        }
        
        return {
            show: showAsModal
        };
    }();

    function tagTemplate(tag) {
        return new andy.UI.Group({
            icon: new andy.UI.Icon('folder'),
            label: new andy.UI.Label(tag.language[andy.Language.current][options.defaultKey ? options.defaultKey : 'name']),
        }).getElement();
    }

    let tagsList = new andy.UI.List(null, tagTemplate, { removable: false, draggable: false });

    const el = tagsList.getElement();
    let value = null;

    el.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        LocalItemModal.show((data) => {
            set(data);
        });
    });

    function set(f) {
        value = f/*.map(ls => ls.uid);*/;
        tagsList.reset();
        if (value){
            tagsList.setValue(value);
        }
    }

    function getValue() {
        return value;
    }

    function getElement() {
        return el;
    }

    function setValue(val) {
        set(val);
    }

    let _public = {
        getElement,
        getValue,
        setValue
    }

    andy.UI._instances.push(_public);
    return _public;
}