export function DuplicateProjectModal() {

    const events = new andy.EventManager();

    let duplicateForm, duplicateModal;
    let project = null;

    function constructor() {
        duplicateForm = new andy.UI.Form('duplicate_project', {
            name: { label: 'name', item: new andy.UI.Input({ placeholder: 'name', validators: andy.Validators.notEmpty }) },
            description: { label: 'description', item: new andy.UI.Input({ placeholder: 'description' }) }
        }, {
            save: new andy.UI.Button({ icon: 'mdi-content-save-outline', label: 'duplicate' }, duplicateHandler)
        });
        duplicateModal = new andy.UI.Modal(duplicateForm);
        duplicateModal.on('dismiss', cancelHandler);
    }

    function duplicateHandler() {
        if (duplicateForm.validate()) {
            events.dispatchEvent('duplicate', { ...project, ...duplicateForm.getValue() });
        }
    };

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function reset() {
        project = null;
        duplicateForm.reset();
    }

    function show(prj) {
        if (prj) {
            project = prj;
            duplicateForm.setValue(project);
        }     
        duplicateModal.show();
    }

    function hide() {
        reset();
        duplicateModal.hide();
    }

    constructor();

    return {
        show,
        hide,
        reset,
        on: events.addEventListener
    }
}