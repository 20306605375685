export function DevicesService({ socket }) {

    const events = new andy.EventManager();

    const dataset = new andy.Dataset('andy.devices');

    function constructor() {

    }

    function deregister(value) {
        socket.emit('devices', {
            action: 'deregister',
            value,
            token: andy.Auth.token
        });
    }

    constructor();

    return {
        dataset,
        deregister,
        on: events.addEventListener
    }
}
