module.exports = {
    "af": "AF.SVG",
    "sq": "AL.SVG",
    "am": "ET.SVG",
    "ar_DZ": "DZ.SVG",
    "ar_BH": "BH.SVG",
    "ar_EG": "EG.SVG",
    "ar_IQ": "IQ.SVG",
    "ar_JO": "JO.SVG",
    "ar_KW": "KW.SVG",
    "ar_LB": "LB.SVG",
    "ar_LY": "LY.SVG",
    "ar_MA": "MA.SVG",
    "ar_OM": "OM.SVG",
    "ar_QA": "QA.SVG",
    "ar_SA": "SA.SVG",
    "ar_SY": "SY.SVG",
    "ar_TN": "TN.SVG",
    "ar_AE": "AE.SVG",
    "ar_YE": "YE.SVG",
    "hy": "AM.SVG",
    "as": "IN.SVG",
    "az_AZ": "AZ.SVG",
    "eu": "EU.SVG",
    "be": "BY.SVG",
    "bn": "BD.SVG",
    "bs": "BA.SVG",
    "bg": "BG.SVG",
    "my": "MM.SVG",
    "ca": "CT.SVG",
    "zh_CN": "CN.SVG",
    "zh_HK": "HK.SVG",
    "zh_MO": "MO.SVG",
    "zh_SG": "SG.SVG",
    "zh_TW": "TW.SVG",
    "hr": "HR.SVG",
    "cs": "CZ.SVG",
    "da": "DK.SVG",
    "dv": "MV.SVG",
    "nl_BE": "BE.SVG",
    "nl_NL": "NL.SVG",
    "en_AU": "AU.SVG",
    "en_BZ": "BZ.SVG",
    "en_CA": "CA.SVG",
    "en_GB": "GB.SVG",
    "en_IN": "IN.SVG",
    "en_IE": "IE.SVG",
    "en_JM": "JM.SVG",
    "en_NZ": "NZ.SVG",
    "en_PH": "PH.SVG",
    "en_ZA": "ZA.SVG",
    "en_TT": "TT.SVG",
    "en_US": "US.SVG",
    "et": "EE.SVG",
    "fo": "FO.SVG",
    "fa": "IR.SVG",
    "fi": "FI.SVG",
    "fr_BE": "BE.SVG",
    "fr_CA": "CA.SVG",
    "fr_FR": "FR.SVG",
    "fr_LU": "LU.SVG",
    "fr_CH": "CH.SVG",
    "mk": "MK.SVG",
    "gd_IE": "IE.SVG",
    "gd": "SF.SVG",
    "de_AT": "AT.SVG",
    "de_DE": "DE.SVG",
    "de_LI": "LI.SVG",
    "de_LU": "LU.SVG",
    "de_CH": "CH.SVG",
    "el": "GR.SVG",
    "gn": "PY.SVG",
    "gu": "IN.SVG",
    "he": "IL.SVG",
    "hi": "IN.SVG",
    "hu": "HU.SVG",
    "is": "IS.SVG",
    "id": "ID.SVG",
    "it_IT": "IT.SVG",
    "it_CH": "CH.SVG",
    "ja": "JP.SVG",
    "kn": "IN.SVG",
    "ks": "IN.SVG",
    "kk": "KZ.SVG",
    "km": "KH.SVG",
    "ko": "KR.SVG",
    "lo": "LA.SVG",
    "lv": "LV.SVG",
    "lt": "LT.SVG",
    "ms_BN": "BN.SVG",
    "ms_MY": "MY.SVG",
    "ml": "ML.SVG",
    "mt": "MT.SVG",
    "mn": "MN.SVG",
    "ne": "NP.SVG",
    "no_NO": "NO.SVG",
    "or": "IN.SVG",
    "pl": "PL.SVG",
    "pt_BR": "BR.SVG",
    "pt_PT": "PT.SVG",
    "ro_MO": "MD.SVG",
    "ro": "RO.SVG",
    "ru": "RU.SVG",
    "ru_MO": "MD.SVG",
    "sa": "NP.SVG",
    "sr_SP": "SK.SVG",
    "tn": "BW.SVG",
    "sk": "SK.SVG",
    "sl": "SI.SVG",
    "so": "SO.SVG",
    "es_AR": "AR.SVG",
    "es_BO": "BO.SVG",
    "es_CL": "CL.SVG",
    "es_CO": "CO.SVG",
    "es_CR": "CR.SVG",
    "es_DO": "DO.SVG",
    "es_EC": "EC.SVG",
    "es_SV": "SV.SVG",
    "es_GT": "GT.SVG",
    "es_HN": "HN.SVG",
    "es_MX": "MX.SVG",
    "es_NI": "NI.SVG",
    "es_PA": "PA.SVG",
    "es_PY": "PY.SVG",
    "es_PE": "PE.SVG",
    "es_PR": "PR.SVG",
    "es_ES": "ES.SVG",
    "es_UY": "UY.SVG",
    "es_VE": "VE.SVG",
    "ep": "EP.SVG",
    "sw": "KE.SVG",
    "sv_FI": "FI.SVG",
    "sv_SE": "SE.SVG",
    "tg": "TJ.SVG",
    "te": "IN.SVG",
    "th": "TH.SVG",
    "tr": "TR.SVG",
    "tk": "TM.SVG",
    "uk": "UA.SVG",
    "ur": "PK.SVG",
    "uz_UZ": "UZ.SVG",
    "vi": "VN.SVG",
}