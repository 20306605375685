export function EventModal() {

  const events = new andy.EventManager();

  let eventForm, eventModal, sequenceSelect;
  let originalEvent = {}, sequences = {};


  function constructor() {
    const frequencyValues = [
      { val: "yearly", label: "yearly" },
      { val: "monthly", label: "monthly" },
      { val: "weekly", label: "weekly" },
      { val: "daily", label: "daily" },
      { val: "hourly", label: "hourly" },
      { val: "minutely", label: "minutely" }
    ];

    const frequencySelect = new andy.UI.Select(frequencyValues, (option, eOption) => { return eOption(option.val, option.label); })

    const weekdayValues = [
      { val: "mo", label: "monday" },
      { val: "tu", label: "tuesday" },
      { val: "we", label: "wednesday" },
      { val: "th", label: "thursday" },
      { val: "fr", label: "friday" },
      { val: "sa", label: "saturday" },
      { val: "su", label: "sunday" }
    ];

    const weekdayStartSelect = new andy.UI.Select(weekdayValues, (option, eOption) => { return eOption(option.val, option.label); })

    sequenceSelect = new andy.UI.Select(sequences, (seq, add) => {
      add(seq._id, seq.name, false);
    });

    const recurentSwitch = new andy.UI.Switch(false);

    recurentSwitch.on('change', () => {
      if (recurentSwitch.getValue()) {
        const v = eventForm.getValue();
        const duration = millisecondsToHMS(Math.abs(new Date(v.start) - new Date(v.end)));
        const until = new Date(); 
        until.setFullYear(until.getFullYear() + 1);
        eventForm.setValue({
          dtstart: v.start,
          until,
          duration
        });
        eventForm.applyFilter('recur');
      } else {
        eventForm.applyFilter('none');
      }
    })

    const weekDayCheckBox = new andy.UI.Group({
      mondayLabel: new andy.UI.Label('week_day_monday_short'),
      monday: new andy.UI.CheckBox(),
      tuesdayLabel: new andy.UI.Label('week_day_tuesday_short'),
      tuesday: new andy.UI.CheckBox(),
      wednesdayLabel: new andy.UI.Label('week_day_wednesday_short'),
      wednesday: new andy.UI.CheckBox(),
      thursdayLabel: new andy.UI.Label('week_day_thursday_short'),
      thursday: new andy.UI.CheckBox(),
      fridayLabel: new andy.UI.Label('week_day_friday_short'),
      friday: new andy.UI.CheckBox(),
      saturdayLabel: new andy.UI.Label('week_day_saturday_short'),
      saturday: new andy.UI.CheckBox(),
      sundayLabel: new andy.UI.Label('week_day_sunday_short'),
      sunday: new andy.UI.CheckBox()
    })

    const monthCheckBox = new andy.UI.Group({
      janLabel: new andy.UI.Label('january_short'),
      jan: new andy.UI.CheckBox(),
      febLabel: new andy.UI.Label('february_short'),
      feb: new andy.UI.CheckBox(),
      marLabel: new andy.UI.Label('march_short'),
      mar: new andy.UI.CheckBox(),
      aprLabel: new andy.UI.Label('april_short'),
      apr: new andy.UI.CheckBox(),
      mayLabel: new andy.UI.Label('may_short'),
      may: new andy.UI.CheckBox(),
      junLabel: new andy.UI.Label('june_short'),
      jun: new andy.UI.CheckBox(),
      julLabel: new andy.UI.Label('july_short'),
      jul: new andy.UI.CheckBox(),
      augLabel: new andy.UI.Label('august_short'),
      aug: new andy.UI.CheckBox(),
      sepLabel: new andy.UI.Label('september_short'),
      sep: new andy.UI.CheckBox(),
      octLabel: new andy.UI.Label('october_short'),
      oct: new andy.UI.CheckBox(),
      novLabel: new andy.UI.Label('november_short'),
      nov: new andy.UI.CheckBox(),
      decLabel: new andy.UI.Label('december_short'),
      dec: new andy.UI.CheckBox()
    })

    const durationGroup = new andy.UI.Group({
      h: new andy.UI.Input({ type: 'number', min: 0, max: 24 }),
      hLabel: new andy.UI.Label('hours'),
      m: new andy.UI.Input({ type: 'number', min: 0, max: 59 }),
      mLabel: new andy.UI.Label('minutes'),
      s: new andy.UI.Input({ type: 'number', min: 0, max: 59 }),
      sLabel: new andy.UI.Label('seconds')
    })

    eventForm = new andy.UI.Form('edit_schedule', {
      sequence: { label: 'sequence', item: sequenceSelect },
      allDay: { label: 'all_day', item: new andy.UI.Switch() },
      recurrent: { label: 'recurrent', item: recurentSwitch },
      start: { label: 'start_date', item: new andy.UI.DatePicker(null, { time_24hr: true, enableTime: true, dateFormat: "Y-m-d H:i" }), filter: 'none' },
      end: { label: 'end_date', item: new andy.UI.DatePicker(null, { time_24hr: true, enableTime: true, dateFormat: "Y-m-d H:i" }), filter: 'none' },
      dtstart: { label: 'start_date', item: new andy.UI.DatePicker(null, { time_24hr: true, enableTime: true, dateFormat: "Y-m-d H:i" }), filter: 'recur' },
      until: { label: 'until', item: new andy.UI.DatePicker(null, { time_24hr: true, enableTime: true, dateFormat: "Y-m-d H:i" }), filter: 'recur' },
      // startTime: { label: 'time_start', item: new andy.UI.DatePicker(null, { time_24hr: true, enableTime: true, noCalendar: true, dateFormat: "H:i" }), filter: 'recur' },
      duration: { label: 'duration', item: durationGroup, filter: 'recur' },
      frequency: { label: 'frequency', item: frequencySelect, filter: 'recur' },
      count: { label: 'count', item: new andy.UI.Input({ type: 'number' }), filter: 'recur' },
      interval: { label: 'interval', item: new andy.UI.Input({ type: 'number' }), filter: 'recur' },
      weekstart: { label: 'weekday_start', item: weekdayStartSelect, filter: 'recur' },
      weekdays: { label: 'week_days', item: weekDayCheckBox, filter: 'recur' },
      // months: { label: 'months', item: monthCheckBox, filter: 'recur' },
      // monthdays: { label: 'month_days', item: new andy.UI.Input(), filter: 'recur' },
      // startRecurrence: { label: 'from', item: new andy.UI.DatePicker(null, { dateFormat: "Y-m-d" }), filter: 'D' },
      // endRecurrence: { label: 'to', item: new andy.UI.DatePicker(null, { dateFormat: "Y-m-d" }), filter: 'D' },
    },
      {
        save: new andy.UI.Button({ icon: 'save', label: 'save' }, saveEventHandler),
        delete: new andy.UI.Button({ icon: 'delete', label: 'delete' }, deleteEventHandler)
      }
    );

    eventModal = new andy.UI.Modal(eventForm);
    eventModal.on('dismiss', cancelHandler);
  }

  function saveEventHandler() {
    if (eventForm.validate()) {
      events.dispatchEvent('save', { ...originalEvent, ...eventForm.getValue() });
    }
  };

  function cancelHandler() {
    events.dispatchEvent('cancel');
    reset();
  };

  function deleteEventHandler() {
    events.dispatchEvent('delete', { ...originalEvent, ...eventForm.getValue() });
  }

  function reset() {
    originalEvent = {};
    eventForm.reset();
  }

  function show(event) {
    if (event) {
      originalEvent = event;
      eventForm.setValue(event);
      if (event.recurrent) {
        eventForm.applyFilter('recur');
      } else {
        eventForm.applyFilter('none');
      }
    }
    eventModal.show();
  }

  function hide() {
    eventModal.hide();
    reset();
  }

  constructor();

  return {
    set sequences(v) {
      sequences = v;
      sequenceSelect.clear();
      sequenceSelect.addBulk(sequences);
    },
    show,
    hide,
    reset,
    // dismissable,
    on: events.addEventListener
  }

}