export function changeDeviceGroupModal() {

    const events = new andy.EventManager();

    let changeGroupForm;
    let changeGroupModal;
    let groupSelect = new andy.UI.Select(); 

    function constructor() {
        changeGroupForm = new andy.UI.Form('groups', {
            select: { label: 'select_a_group', item: groupSelect }
        }, {
            createNew: new andy.UI.Button({ label: 'create_group', icon: 'mdi-plus' }, () => events.dispatchEvent('create.group')),
            save: new andy.UI.Button({ label: 'save', icon: 'mdi-content-save-outline' }, saveHandler)
        });

        changeGroupModal = new andy.UI.Modal(changeGroupForm);
        changeGroupModal.on('dismiss', cancelHandler);
    }

    function saveHandler() {
        const values = createGroupForm.getValue().group.select;
        events.dispatchEvent('save', values);
    }

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };


    function show() {
        changeGroupModal.show();
    }

    function hide() {
        changeGroupModal.hide();
    }

    constructor();

    return {
        show,
        hide,
        on: events.addEventListener
    }
}

// () => {
//     currentlySelectedDevice.group = changeGroupForm.getValue().group.select;
//     devicesDataset.save(currentlySelectedDevice, el => {
//         selectGroups.setValue(el.group);
//     });
// }