

export function SequencesSidebar() {

    const events = new andy.EventManager();

    let list;

    function constructor() {
        list = new andy.UI.StudioList();

        const newSequenceButton = new andy.UI.StudioListItem({ label: 'new_sequence', icon: 'mdi-plus' });

        list.addFixed(newSequenceButton);

        newSequenceButton.on('click', () => events.dispatchEvent('sequence.new'));

    }

    function hideNewSequenceButton() {
        list.hideFixed();
    }

    function showNewSequenceButton() {
        list.showFixed();
    }

    function setSequences(sequences, calendar) {
        list.clear();
        Object.values(sequences).forEach(sequence => {
            const item = new andy.UI.StudioListItem({ subMenu: true, label: sequence.name, icon: 'mdi-format-list-bulleted' });
            // item.props.draggable = true; 
            item.on('click', () => {
                events.dispatchEvent('sequence.open', sequence);
            });
            item.on('duplicate', () => {
                events.dispatchEvent('sequence.duplicate', sequence);
            });
            item.on('delete', () => {
                events.dispatchEvent('sequence.remove', sequence);
            });
            list.add(sequence._id, item);

            calendar.makeDraggable(item.element, {
                eventData: {
                    title: sequence.name,
                    get id(){
                        return andy.uid.generate();
                    } ,
                    sequence,
                    backgroundColor: sequence.color
                }
            });
        });
    }

    constructor();

    return {
        get element() {
            return list.element;
        },
        setSequences,
        hideNewSequenceButton,
        showNewSequenceButton,
        on: events.addEventListener
    }

}