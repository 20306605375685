
export function EditUserModal() {

    const events = new andy.EventManager();

    let userCredentialsForm, userNotificationForm, userFormRoleSelect, userForm, userModal;
    let user = null;

    function constructor() {
        userCredentialsForm = new andy.UI.Form('user_credentials', {
            username: { label: 'username', item: new andy.UI.Input() },
            password: { label: 'password', item: new andy.UI.Input({ type: 'password' }) }
        });

        userNotificationForm = new andy.UI.Form('notification', {
            enable: { label: 'enable', item: new andy.UI.Switch() }
        });

        userFormRoleSelect = new andy.UI.Select(null, function (option, add) {
            add(option._id, option.name);
        });

        userForm = new andy.UI.Form('new_user', {
            name: { label: 'name', item: new andy.UI.Input() },
            surname: { label: 'surname', item: new andy.UI.Input() },
            language: { label: 'language', item: new andy.UI.Select(andy.Language.getLanguagesAvailable()) },
            contacts: { label: '', item: new andy.UI.Form('', { email: { label: 'email', item: new andy.UI.Input() } }) },
            role: { label: 'role', item: userFormRoleSelect },
            credentials: { label: '', item: userCredentialsForm },
            notification: { label: '', item: userNotificationForm },
        }, {
            save: new andy.UI.Button({ icon: 'save', label: 'save' }, saveHandler)
        });

        userModal = new andy.UI.Modal(userForm);
        userModal.on('dismiss', cancelHandler);
    }

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function saveHandler() {
        if(userForm.validate()){
            events.dispatchEvent('save', (user) ? {...user, ...userForm.getValue()} : userForm.getValue());
        } 
    };

    function reset(){
        user = null; 
        userForm.reset(); 
    }

    function show(u) {
        if(u){
            user = u; 
            userForm.setValue(u); 
        }else{
            reset(); 
        }
        userModal.show();
    }

    function hide() {
        userModal.hide();
    }

    constructor();

    return {
        get roleSelect(){
            return userFormRoleSelect;
        },
        show,
        hide,
        on: events.addEventListener
    }
}




// () => {
//     var values = userForm.getValue();
//     values.credentials = userCredentialsForm.getValue();
//     if (values.credentials.password.trim() === '') {
//         delete values.credentials.password;
//     }
//     if (typeof currentUser === 'object') {
//         values._id = currentUser._id;
//     }

//     usersDataset.save(values);
//     container.show('users');
// })