export function NotificationsController (socket) {

    var scope = this;


    var notificationsWrapper = new DIV('notifications-wrapper');
    document.body.appendChild(notificationsWrapper);


    this.newFixed = function (message, type) {
        let notification = new DIV('notification ' + type, message);
        notificationsWrapper.appendChild(notification);

        notification.remove = function () {
            setTimeout(() => {
                notification.remove();
            }, 300);
        }

        notification.show = function () {
            setTimeout(() => {
                notification.style.opacity = 1;
            }, 100);
        }

        notification.hide = function () {
            setTimeout(() => {
                notification.style.opacity = 0;
            }, 5000);
        }

        notification.update = function (text) {
            this.innerHTML = text;
        }
        return notification;
    }


    this.new = function (message, type, duration) {

        if (typeof duration == 'undefined') {
            duration = 5000;
        }

        var notification = new DIV('notification ' + type, message);

        notificationsWrapper.appendChild(notification);

        setTimeout(() => {
            notification.style.opacity = 1;
        }, 100);

        setTimeout(() => {
            notification.style.opacity = 0;
        }, duration);

        setTimeout(() => {
            notification.remove();
        }, duration + 300);
    }

    if (socket) {
        socket.on('notifications', (data) => {
            if (data.action == 'message') {
                scope.new(andy.Language.get(data.value.text), data.value.type);
            }
        });
    }
}