
export function DeviceRemoteActionsToolbar() {

    const events = new andy.EventManager();

    let dropdown, group, statusLabel, terminalButton;

    function constructor() {

        statusLabel = new andy.UI.Label();

        const moreDropdownItems = {
            resume: new andy.UI.Button({ label: 'resume', icon: 'mdi-play' }, () => events.dispatchEvent('resume.device')),
            pause: new andy.UI.Button({ label: 'pause', icon: 'mdi-pause' }, () => events.dispatchEvent('pause.device')),
            sync: new andy.UI.Button({ label: 'sync', icon: 'mdi-cloud-sync-outline' }, () => events.dispatchEvent('sync.device')),
            refresh: new andy.UI.Button({ label: 'refresh', icon: 'mdi-refresh' }, () => events.dispatchEvent('refresh.device')),
            reboot: new andy.UI.Button({ label: 'reboot', icon: 'mdi-restart' }, () => events.dispatchEvent('reboot.device')),
            poweroff: new andy.UI.Button({ label: 'power_off', icon: 'mdi-power' }, () => events.dispatchEvent('poweroff.device'))
         };

        dropdown = new andy.UI.DropDown({ icon: 'mdi-remote', label: 'remote_actions', items: moreDropdownItems});
        terminalButton = new andy.UI.Button({icon: 'mdi-console', label: 'terminal'}, ()=>events.dispatchEvent('terminal.open')); 
    
        group = new andy.UI.Group({
            statusLabel,
            dropdown, 
            terminalButton
        });

        disconnected(); 
      
    }

    constructor();

    function connected(){
        statusLabel.setValue('connected');
        dropdown.button.enable(); 
        terminalButton.enable(); 
    }

    function disconnected(){
        statusLabel.setValue('disconnected');
        dropdown.button.disable(); 
        terminalButton.disable(); 
    }

    function attemptToConnect(){
        statusLabel.setValue('attempt_to_connect');
        dropdown.button.disable(); 
        terminalButton.disable(); 
    }

    function failed(){
        statusLabel.setValue('connection_failed');
        dropdown.button.disable(); 
        terminalButton.disable(); 
    }

    return {
        get element() {
           return group.getElement();
        },
        connected, 
        disconnected, 
        attemptToConnect, 
        failed,
        on: events.addEventListener
    }
}