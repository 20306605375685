export function PagesSidebar() {

    const events = new andy.EventManager();

    let list;
    let currentSelected = null;

    function constructor() {
        list = new andy.UI.StudioList();

        const newPageButton = new andy.UI.StudioListItem({ label: 'new_page', icon: 'mdi-plus' });

        list.addFixed(newPageButton);

        newPageButton.on('click', () => events.dispatchEvent('new.page'));
    }

    function editorMode() {
        list.showFixed();
        Object.values(list.items).forEach(item => {
            item.props.selectable = true;
            item.props.clickable = true;
            item.props.draggable = false;
            item.update();
        })
        if (currentSelected) {
            currentSelected.select();
        }
    }

    function logicMode() {
        list.hideFixed();
        list.deselectAll();
        Object.values(list.items).forEach(item => {
            item.props.selectable = false;
            item.props.clickable = false;
            item.props.draggable = true;
            item.update();
        });
    }

    function setPages(pages) {
        list.clear();
        currentSelected = null;
        Object.values(pages).forEach((page, i) => {
            const item = new andy.UI.StudioListItem({ subMenu: true,  selectable: true, selected: (i == 0) ? true : false, label: page.name, icon: (page.masterPage) ? 'mdi-card-bulleted-outline' : 'mdi-card-outline' });
            item.on('click', (me) => {
                list.deselectAll(page._id);
                me.select();
                currentSelected = me;
                events.dispatchEvent('open.page', page);
            });

            item.on('dragstart', (e) => {
                events.dispatchEvent('drag.page', page);
            });

            item.on('duplicate', () => {
                events.dispatchEvent('duplicate.page', page);
            });
            item.on('delete', () => {
                events.dispatchEvent('remove.page', page);
            });

            list.add(page._id, item);
        });
    }

    constructor();

    return {
        get element() {
            return list.element;
        },
        setPages,
        editorMode,
        logicMode,
        on: events.addEventListener
    }

}