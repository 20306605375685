export function StudioList(p) {

    const items = {};
    let element, itemsWrapper, fixedWrapper;

    function constructor() {
        element = new DIV('andy-ui-studiolist');
        itemsWrapper = new DIV('studiolist-items-wrapper');
        fixedWrapper = new DIV('studiolist-fixed-wrapper');
        element.append(itemsWrapper, fixedWrapper);
    }

    function add(code, item) {
        if (!items[code]) {
            items[code] = item;
            itemsWrapper.append(item.element);
        }
        console.log(items);
    }

    function addFixed(item) {
        fixedWrapper.append(item.element);
    }

    function hideFixed() {
        fixedWrapper.classList.add('hide');
    }

    function showFixed() {
        fixedWrapper.classList.remove('hide');
    }

    function remove(code) {
        items[code].remove();
        items[code] = null;
        delete items[code];
    }

    function clear() {
        Object.keys(items).forEach(remove);
    }

    function deselectAll(except) {
        Object.keys(items).forEach(key => {
            if (key != except) items[key].deselect();
        });
    }

    constructor();

    return {
        get element() {
            return element;
        },
        get items() {
            return items;
        },
        add,
        addFixed,
        hideFixed,
        showFixed,
        deselectAll,
        remove,
        clear
    }

}