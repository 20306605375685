export function FileDetailsModal() {

    const events = new andy.EventManager();

    let detailsForm, detailsModal;
    let file = null;

    function constructor() {
        detailsForm = new andy.UI.Form('file_details', {
            name: { label: 'name', item: new andy.UI.Input({ validators: andy.Validators.notEmpty }) },
            id: {
                label: 'id',
                item: new andy.UI.Group({
                    'value': new andy.UI.Label('id'),
                    'copy': new andy.UI.Button({ icon: 'mdi-content-copy' }, () => events.dispatchEvent('file.copy.id', file))
                })
            },
            originalName: { label: 'original_name', item: new andy.UI.Label('') },
            filesize: { label: 'size', item: new andy.UI.Label('') },
            type: { label: 'type', item: new andy.UI.Label('') },
            insertDate: { label: 'uploaded', item: new andy.UI.Label('') },
            tags: { label: 'tags', item: new andy.UI.Tags('') }
        }, {
            save: new andy.UI.Button({ label: 'save', icon: 'mdi-save' }, saveHandler),
            cancel: new andy.UI.Button({ label: 'cancel', icon: 'mdi-cancel' }, cancelHandler)
        });

        detailsModal = new andy.UI.Modal(detailsForm);
        detailsModal.on('dismiss', cancelHandler);
    }

    function saveHandler() {
        if (detailsForm.validate()) {
            events.dispatchEvent('save', { file, values: detailsForm.getValue() });
        }
        file = null;
    }

    function cancelHandler() {
        file = null;
        events.dispatchEvent('cancel');
    };

    function show(f) {
        if (f) {
            file = f;
        }
        if (file) {
            detailsForm.setValue({...file, filesize: humanReadableFileSize(file.filesize), id: { value: f._id } });
        }
        detailsModal.show();
    }

    function hide() {
        detailsModal.hide();
    }

    constructor();

    return {
        show,
        hide,
        on: events.addEventListener
    }
}