import { StudioNodeEditor, Node } from './studio.logic.editor.nodes';

export function LogicView() {

    const events = new andy.EventManager();
    let editor;

    function constructor() {

        editor = new StudioNodeEditor();
        editor.on('drop', (e) => events.dispatchEvent('drop', e));

    }

    function createStartNode(isNew) {
        let n = new Node();

        n.setTitle('Start');
        n.setPosition(50, 50);
        n.addOutput('start', 'onStart', 'onStart', 'action', '');
        n._id = 'start';
        //n.removable(); // added now

        if (isNew) {
            // saveApplication();
        }

        editor.add(n);
    }

    function createEventNode(title, action) {
        let n = new Node();
        n.setTitle(title);
        n.setPosition(50, 200);
        n.addOutput( action ,action, action, 'action', '');
        n._id = action;
        editor.add(n);
    }

    function createNodeFromPage(layout, pos) {

        let n = new Node();
        n.setTitle(layout.name);
        n.removable();
        n._id = layout._id;
        n.layout = layout._id;
        if(pos){
            n.setPosition(pos.x, pos.y)
        }

        n.addInput(layout._id, layout._id, 'Show', 'show', '');

        for (let i = 0; i < layout.studio.blocks.length; i++) {
            let area = layout.studio.blocks[i];
            if (area.plugin.props) {
                if (area.plugin.data) {
                    let item = area.plugin.data;
                    if (item.nodeIO) {
                        if (item.nodeIO.inputs) {
                            let inputs = item.nodeIO.inputs;
                            for (let j = 0; j < inputs.length; j++) {
                                let input = inputs[j];
                                if(output._id){
                                    n.addInput(input._id  , input.code, area.name + '_' + input.code, input.type, input.accepts, '');
                                }else{
                                    n.addInput( item.nodeIO._id , item.nodeIO._id , area.name + '_' + input.code, input.type, input.accepts, '');
                                }
                                
                            }
                        }
                        if (item.nodeIO.outputs) {
                            let outputs = item.nodeIO.outputs;
                            for (let j = 0; j < outputs.length; j++) {
                                let output = outputs[j];
                                if(output._id){
                                    n.addOutput(output._id, output.code, area.name + '_' + output.code, output.type, output.accepts, '');
                                }else{
                                    n.addOutput(item.nodeIO._id, item.nodeIO._id, area.name + '_' + output.code, output.type, output.accepts, '');
                                }
                            }
                        }
                    }
                }
            }
        }

        editor.add(n);
    }

    function parseApplicationStructure({ structure, pages }) {

        if (structure.length < 1) {
            createNewApplication();
        }

        for (let i = 0; i < structure.length; i++) {

            console.log('--------------->', structure[i]);

            let node = structure[i];
            let n = new Node();

            if (node.layout) {
                if (pages[node.layout]) { // fix to show page name
                    let page = pages[node.layout];
                    n.setTitle(page.name);
                } else {
                    n.setTitle(node.title + ' (deleted)');
                }
            } else {
                n.setTitle(node.title);
            }

            if (node.removable) {
                n.removable();
            }
            n._id = (node._id) ? node._id : null;
            n.layout = (node.layout) ? node.layout : null;

            if (node.position) {
                if (node.position.left !== 0) {
                    n.setPosition(node.position.left, node.position.top); // Fix offset (needed left -300 aswell)
                } else {
                    n.setPosition(node.position.left, node.position.top); // Fix offset (needed left -300 aswell)
                }
            }


            for (let j = 0; j < node.inputs.length; j++) {
                let iP = node.inputs[j];
                n.addInput( iP._id || iP.code, iP.code, iP.label, iP.type, iP.accepts, iP.connectedTo);
            }

            for (let j = 0; j < node.outputs.length; j++) {
                let oP = node.outputs[j];
                n.addOutput(oP._id || oP.code, oP.code, oP.label, oP.type, oP.accepts, oP.connectedTo);
            }

            editor.add(n);
        }

        editor.completeConnections();
    }

    function clear() {
        editor.clearEditor();
    }

    function createNewApplication() {
        editor.clearEditor();
        createStartNode(true);
    }

    function fixRenderPaths() {
        editor.updatePaths();
    }

    constructor();


    return {
        clear,
        createEventNode,
        createNodeFromPage,
        createStartNode,
        parseApplicationStructure,
        fixRenderPaths,
        get structure() {
            console.log(editor.returnValue());
            return editor.returnValue();
        },
        get element() {
            return editor.getElement();
        },
        on: events.addEventListener
    }

}