module.exports = function () {
    
    const baseUrl = "http://api-dev.brandspot.media";
    
    function authenticate(data, callback) {
        request({
            url: `${baseUrl}/player/auth/validate`,
            method: 'POST',
            bearer: data.key,
            body: {},
            callback
        });
    }

    function request({ url, method, body, bearer = null, callback }) {
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        };

        if (bearer) {
            headers["Authorization"] = `Bearer ${bearer}`;
        }

        fetch(url,
            {
                headers,
                method,
                body: JSON.stringify(body)
            })
            .then(handleErrors)
            .then(res => res.json())
            .then(function(res) {
                callback({ err: null, res })
            })
            .catch(function (err) { 
                callback({ err });
            })
    }

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    return {
        authenticate,
        get apiUrl() {
            return baseUrl;
        }
    }
}