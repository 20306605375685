import { Terminal } from 'xterm';
// import { FitAddon } from 'xterm-addon-fit';


//TODO - Make a disable state when the connection goes off to prevent loss of input and to give better ux feedback

export function RemoteTerminalModal() {

    const events = new andy.EventManager();

    const terminal = new Terminal({
        cols: 80,
        rows: 30
    });
    // const fitAddon = new FitAddon();

    let firstOpen = true, isOpen = false;

    let terminalForm, terminalModal, terminalWrapper;

    function constructor() {

        // terminal.loadAddon(fitAddon);
        terminal.onData((data) => events.dispatchEvent('data', data));

        terminalWrapper = new DIV('terminal-wrapper');
        let terminalObj = {
            getElement: () => {
                return terminalWrapper;
            }
        }

        terminalForm = new andy.UI.Form('remote_terminal', {
            terminal: { item: terminalObj }
        });
        terminalModal = new andy.UI.Modal(terminalForm);
        terminalModal.on('dismiss', cancelHandler);
    }

    function cancelHandler() {
        events.dispatchEvent('cancel');
    }

    function show() {
        isOpen = true;
        terminalModal.show();

        if (firstOpen) {
            firstOpen = false;
            terminal.open(terminalWrapper);
            // fitAddon.fit();
        }

        events.dispatchEvent('open');
    }

    function write({ id, cmd }) {
        terminal.write(cmd);
    }

    function hide() {
        isOpen = false;
        terminalModal.hide();
    }

    function clear() {
        terminal.clear();
        terminal.reset();
    }

    function connected() {
    }

    function disconnected() {
    }

    constructor();

    return {
        show,
        hide,
        write,
        connected,
        disconnected,
        clear,
        on: events.addEventListener
    }
}