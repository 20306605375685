export function requestDeviceModal() {

    const events = new andy.EventManager();

    let getDeviceByCodeForm;
    let getDeviceByCodeModal;

    function constructor() {
        getDeviceByCodeForm = new andy.UI.Form('request_device', {
            code: { label: 'code', description: 'request_device_code_description', item: new andy.UI.Input({ maxLength: 5, customClass: 'require-code-input', validators: andy.Validators.notEmpty }) }
        }, {
            request: new andy.UI.Button(
                { icon: 'mdi-plus', label: 'request' }, requestHandler),
            cancel: new andy.UI.Button(
                { icon: 'mdi-cancel', label: 'cancel' }, cancelHandler)
        });

        getDeviceByCodeModal = new andy.UI.Modal(getDeviceByCodeForm);

        getDeviceByCodeModal.on('dismiss', cancelHandler);
    }

    function requestHandler() {
        if (getDeviceByCodeForm.validate()) {
            getDeviceByCodeModal.props.dismissable = false;
            const values = getDeviceByCodeForm.getValue();
            values.code = values.code.toLowerCase();
            events.dispatchEvent('request', values.code);
        }
    };

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function reset() {
        getDeviceByCodeForm.reset();
        getDeviceByCodeModal.props.dismissable = true;
    }

    function show() {
        reset();
        getDeviceByCodeModal.show();
    }

    function hide() {
        getDeviceByCodeModal.hide();
    }

    constructor();

    return {
        show,
        hide,
        reset,
        on: events.addEventListener
    }
}